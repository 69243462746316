export type BalancedBSTNode<T> = {
  value: T;
  left: BalancedBSTNode<T> | undefined;
  right: BalancedBSTNode<T> | undefined;
};

function _createBalancedBst<T>(
  /**
   * Can create the tree in O(n) time if the array is already sorted
   *
   * Perhaps a micro optimization since there won't be many my fitness limits
   * which is the primary use case for this, but I don't want to make the chart
   * any slower than it already is.
   */
  alreadySorted: T[],
  start: number,
  end: number
): BalancedBSTNode<T> | undefined {
  if (alreadySorted.length === 0) {
    return undefined;
  }

  if (start > end) {
    return undefined;
  }

  const mid = Math.floor((start + end) / 2);

  const node: BalancedBSTNode<T> = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    value: alreadySorted[mid]!,
    left: _createBalancedBst(alreadySorted, start, mid - 1),
    right: _createBalancedBst(alreadySorted, mid + 1, end),
  };

  return node;
}

export function createBalancedBst<T>(alreadySorted: T[]) {
  return _createBalancedBst(alreadySorted, 0, alreadySorted.length - 1);
}
