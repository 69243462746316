import { Tooltip } from "@mantine/core";
import * as React from "react";
import {
  Comments,
  useVariablesArrayQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { variableSchema } from "../../../lib/api-validators";
import {
  useNonSearchedVariables,
  useOperatingLimitsProbabilityOrExceedanceVariablesQueryOnDetailsPage,
} from "./list-of-charts/list-of-charts";
import { useGetUseOlDetailsStore } from "./use-ol-details-store";
import Button from "../../common/Button/Button";
import { cn } from "../../../lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandSeparator,
} from "../../ui/command";
import { Check, ChevronsUpDown, Search } from "lucide-react";
import { Button as OtherButton } from "../../ui/button";
import { VariableTypeString } from "../../../types/api/Variable";
import { useSelectedCommentIdParam } from "./use-selected-comment-id-param";
import { useSearchParams } from "react-router-dom";
import { DRAParamsMap } from "../../boundaries/hooks/useDRAParams2";
import { GroupOfVariables } from "../../variability-view/header/add-variables-button-with-dialog";
import { limitLevelSchema } from "../constants";
import { useGetUseViewModeStore } from "../../../shared-ui/time-series-2/grid-view-store";
import {
  LocalSidebarCollapseButton,
  LocalSidebarContainer,
} from "../../common/sidebar/local-sidebar";

export const useSearchedVariablesOnOperatingLimitsPages = () => {
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const useStore = useGetUseOlDetailsStore();
  const searchedVariableIds = useStore((s) => s.manuallySearchedVariableIds);

  return React.useMemo(() => {
    return searchedVariableIds.reduce((arr, id) => {
      const variable = variablesMapQuery.data?.[id];
      if (variable) {
        arr.push(variable);
      }
      return arr;
    }, [] as variableSchema[]);
  }, [variablesMapQuery.data, searchedVariableIds]);
};

export function OperatingLimitsDetailsSidebar() {
  const useViewModeStore = useGetUseViewModeStore();
  const setViewMode = useViewModeStore((s) => s.setViewMode);
  const [localSidebarOpen, setSidebarOpen] = React.useState(true);
  const toggleSidebar = () => {
    if (localSidebarOpen) {
      setViewMode("grid");
    }
    setSidebarOpen((prev) => !prev);
  };
  const otherVariablesMaybe = useNonSearchedVariables();
  const searchedVariables = useSearchedVariablesOnOperatingLimitsPages();

  const singleCommentQuery = Comments.single.useQuery(
    useSelectedCommentIdParam()
  );

  // @ts-ignore
  const forVariableId = singleCommentQuery.data?.context?.variableId;

  const sidebarContent = (
    <>
      <div className="flex flex-col p-2 gap-2">
        <span className="tracking-tight text-xslate-11 font-medium uppercase text-xs block">
          Variables (
          {searchedVariables.length + (otherVariablesMaybe ?? []).length})
        </span>
        <VariableSearchDropdown
          searchedVariables={searchedVariables}
          otherVariables={otherVariablesMaybe ?? []}
        />
      </div>
      {searchedVariables.map((v) => {
        return (
          <SidebarVariableCard
            key={v._id}
            variable={v}
            isSearched
            shouldRemoveSelectedCommentIdFromUrl={v._id === forVariableId}
          />
        );
      })}
      {otherVariablesMaybe?.map((v) => {
        return <SidebarVariableCard key={v._id} variable={v} />;
      })}
    </>
  );

  return (
    <>
      <LocalSidebarContainer open={localSidebarOpen}>
        {sidebarContent}
      </LocalSidebarContainer>
      <LocalSidebarCollapseButton
        onOpenToggle={toggleSidebar}
        open={localSidebarOpen}
      />
    </>
  );
}

function SidebarVariableCard({
  variable,
  isSearched,
  shouldRemoveSelectedCommentIdFromUrl,
}: {
  variable: variableSchema;
  isSearched?: boolean;
  shouldRemoveSelectedCommentIdFromUrl?: boolean;
}) {
  const useStore = useGetUseOlDetailsStore();
  const sp = useSearchParams();

  const query =
    useOperatingLimitsProbabilityOrExceedanceVariablesQueryOnDetailsPage();

  const limitsForThisVariable =
    query.data?.variableLimitMap[variable._id]?.limits;

  const uniqueLevels = new Set<limitLevelSchema>(
    limitsForThisVariable?.map((x) => x.level) ?? []
  );

  return (
    <div
      key={variable._id}
      className={cn(
        "border-b border-zinc-300 w-full px-4 py-1 min-h-[2.5rem] flex items-center justify-between text-[0.8rem]",
        isSearched &&
          "OperatingLimitsDetails__variableSelector__entry--searched"
      )}
    >
      <div>
        <div className="lowercase break-all">{variable.trimmedName || " "}</div>
        <div className="lowercase break-all">{variable.description || " "}</div>
      </div>

      {isSearched ? (
        <Tooltip label="Remove" withArrow position="left">
          <Button
            className="btn-ghost"
            icon="close"
            onClick={() => {
              useStore.getState().removeSearchVariableId(variable._id);
              shouldRemoveSelectedCommentIdFromUrl &&
                sp[1]((curr) => {
                  const copy = new URLSearchParams(curr);
                  copy.delete(DRAParamsMap.selectedCommentId);
                  return copy;
                });
            }}
          />
        </Tooltip>
      ) : (
        <div className="justify-self-end self-end inline-flex gap-1 [&>div]:h-2.5 [&>div]:w-2.5 [&>div]:rounded-full">
          {(uniqueLevels.has("high 5") || uniqueLevels.has("low 5")) && (
            <div className="bg-of5"></div>
          )}
          {(uniqueLevels.has("high 4") || uniqueLevels.has("low 4")) && (
            <div className="bg-of4"></div>
          )}
          {(uniqueLevels.has("high 3") || uniqueLevels.has("low 3")) && (
            <div className="bg-of3"></div>
          )}
          {(uniqueLevels.has("high 2") || uniqueLevels.has("low 2")) && (
            <div className="bg-of2"></div>
          )}
          {(uniqueLevels.has("high 1") || uniqueLevels.has("low 1")) && (
            <div className="bg-of1"></div>
          )}
        </div>
      )}
    </div>
  );
}

const placeholder = "Search variables...";

export function VariableSearchDropdown({
  otherVariables,
  searchedVariables,
}: {
  searchedVariables: variableSchema[];
  otherVariables: variableSchema[];
}) {
  const useStore = useGetUseOlDetailsStore();
  const [open, setOpen] = React.useState(false);
  const variablesQuery = useVariablesArrayQuery();
  const { data: allVariables } = variablesQuery;

  const closeSidebar = () => setOpen(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <OtherButton
          role="combobox"
          aria-expanded={open}
          className="justify-between"
        >
          {placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </OtherButton>
      </PopoverTrigger>
      <PopoverContent
        className="w-[400px] p-0 relative bottom-11"
        align="start"
        avoidCollisions={false}
      >
        <Command
          className="max-h-[35dvh] overflow-scroll"
          filter={(value, search) => {
            if (value.toLowerCase().includes(search.trim().toLowerCase()))
              return 1;
            return 0;
          }}
        >
          <CommandInput placeholder={placeholder} />
          <CommandEmpty>No variables found.</CommandEmpty>
          {searchedVariables.length > 0 && (
            <GroupOfVariables
              className="aria-selected:bg-xred-2"
              heading="Searched Variables"
              variables={searchedVariables}
              icon={Search}
              onSelect={(v) =>
                useStore.getState().removeSearchVariableId(v._id)
              }
            />
          )}
          <CommandSeparator />
          {otherVariables.length > 0 && (
            <GroupOfVariables
              icon={Check}
              heading="Variables with limits"
              variables={otherVariables}
            />
          )}
          <CommandSeparator />
          {allVariables && (
            <GroupOfVariables
              heading="Remaining Variables"
              variables={allVariables.filter((variable) => {
                const isTag = variable.type === VariableTypeString.Tag;
                const isAlreadyIncluded = searchedVariables
                  .concat(otherVariables)
                  .some((v) => v._id === variable._id);
                return isTag && !isAlreadyIncluded;
              })}
              onSelect={(v) => {
                useStore.getState().addSearchVariableId(v._id);
                closeSidebar();
              }}
            />
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
