import React, { useEffect, useRef, useState } from "react";
import useDocumentTitle from "../common/hooks/useDocumentTitle";
import {
  useSaveExpressionMutation,
  useVariablesArrayQuery,
} from "../../hooks/tanstack-query";
import ExpressionBuildingInput from "../../shared-ui/expression-building-input/ExpressionBuildingInput";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { parseExprRef } from "../../shared-ui/expression-building-input/utils";
import validateExpression from "../../shared-ui/expression-building-input/validate";
import { FUNCTIONS } from "../../shared-ui/expression-building-input/constants";
import {
  FaCalculator,
  FaPlus,
  FaSave,
  FaTag,
  FaTrash,
  FaUndo,
} from "react-icons/fa";
import { addToast } from "../toast/use-toast-store";
import {
  createJotaiStoreForChartSync,
  DRASecondaryVariableViewNeedsProvider,
} from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shared-ui/frontend/tooltip";
import { assertMinLen1 } from "../../shared-ui/lib/utils";
import { v4 } from "uuid";
import { TimeseriesChartTooltipStoreProvider } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../time-series/global-tooltip";
import { useCallback } from "use-memo-one";
import {
  Atoms,
  ChartVariant,
  initClampStoresForNewBatchVariable,
} from "../../shared-ui/time-series-2/svv-store/use-svv-store";
import { colorGen } from "../../shared-ui/lib/getColors";
import { createStore, Provider } from "jotai";
import { useTimezone } from "../../zustand/config/useConfigStore";
import { SavedExpression, SavedExpressionVariable } from "./types";
import { Modal } from "@mantine/core";

const alwaysReturnsFalse = () => false;

function InstantCalculatorInputsWithChart({
  savedExpression,
  resetSavedExpression,
}: {
  savedExpression?: SavedExpression;
  resetSavedExpression: () => void;
}) {
  useDocumentTitle("Instant Calculator");
  const saveExpression = useSaveExpressionMutation();
  const [saveExpressionModalOpen, setSaveExpressionModalOpen] = useState(false);
  const expressionNameRef = useRef<HTMLInputElement>(null);
  const allVariables = useVariablesArrayQuery();
  const [pageTitle, setPageTitle] = useState(
    savedExpression?.name || "Instant Calculator"
  );

  type WithAnIdBecauseThisWholeComponentIsHacky = SavedExpressionVariable & {
    presenceOfIdMeansItsReadyToGo: string | undefined;
    edit: string; // only used for expression editing, not variable type
  };

  const EMPTY = (): WithAnIdBecauseThisWholeComponentIsHacky => ({
    variable: "",
    type: "expression",
    presenceOfIdMeansItsReadyToGo: undefined,
    edit: "",
  });

  const [expressions, setExpressions] = useState<
    [
      WithAnIdBecauseThisWholeComponentIsHacky,
      ...WithAnIdBecauseThisWholeComponentIsHacky[],
    ]
  >(() => {
    return savedExpression
      ? assertMinLen1(
          savedExpression.variables.map(
            (x): WithAnIdBecauseThisWholeComponentIsHacky => ({
              ...x,
              presenceOfIdMeansItsReadyToGo: v4(),
              edit: x.variable,
            })
          )
        )
      : [EMPTY()];
  }); // what's actually rendered in the chart

  // only needed to make reset button clear input
  const firstRef = useRef<HTMLDivElement>(null);

  const [chartStore, setChartStore] =
    useState<ReturnType<typeof createStore>>();

  const zone = useTimezone();

  useEffect(() => {
    // these are the expressions that should be present in the chart
    const inputsReadyToReflectInTheChart = expressions.filter(
      (x) => !!x.presenceOfIdMeansItsReadyToGo
    );

    const noValidExpressions = inputsReadyToReflectInTheChart.length === 0;

    if (noValidExpressions) {
      if (chartStore) {
        setChartStore(undefined); // remove chart from view (the presense of a store for it means it should be displayed)
      }

      return; // do nothing
    }

    if (
      !chartStore
      // && areValidExpressions
    ) {
      /**
       * No chart is currently displayed. Create a new chart store
       * with these expressions.
       */

      setChartStore(
        createJotaiStoreForChartSync({
          zone,
          initialBatchVariables: assertMinLen1([
            ...inputsReadyToReflectInTheChart.map((x) => {
              if (!x.presenceOfIdMeansItsReadyToGo)
                throw new Error("impossible");
              switch (x.type) {
                case "expression":
                  return {
                    type: "expression" as const,
                    id: x.presenceOfIdMeansItsReadyToGo,
                    expression: x.variable,
                  };
                case "tag":
                  return {
                    type: "variable" as const,
                    bv: x.variable.padStart(48, "0"),
                  };
                default:
                  throw new Error("impossible");
              }
            }),
          ]),
          initialExpanded: false,
          decideAnomBooleanForNewlyAddedVariableTrendLines: alwaysReturnsFalse,
          variant: ChartVariant.InstantCalculator,
        })
      );

      return;
    }

    /**
     * A chart store does exist. Validate that expressions in the chart match
     * what is in the inputs
     */
    const currentTrendLines = chartStore.get(Atoms.selectedVariablesAtom); // get the currently displayed trendlines that are expressions (there may be variable trend lines too)

    const remainingInTheChart = currentTrendLines
      .map((x): (typeof currentTrendLines)[number] | undefined => {
        if (x.type === "variable") return x; // we are not concerned with these

        const expressionId = x.id;

        const found = inputsReadyToReflectInTheChart.find(
          (e) => e.presenceOfIdMeansItsReadyToGo === expressionId
        );

        if (!found) return undefined; // remove this expression from the chart because its input is no longer present

        const expressionsStillMatch = found.variable === x.expression;

        if (expressionsStillMatch) return x; // no need to update

        return {
          ...x,
          expression: found.variable,
        };
      })
      .filter((x) => x !== undefined);

    const needToAddToChart = inputsReadyToReflectInTheChart.filter(
      (e) =>
        e.type === "expression" &&
        !currentTrendLines.some(
          (x) =>
            x.type === "expression" && x.id === e.presenceOfIdMeansItsReadyToGo
        )
    );

    const colors = colorGen.getN(
      remainingInTheChart.map((x) => x.color),
      needToAddToChart.length
    );

    const newTrendLines = remainingInTheChart.concat(
      needToAddToChart.map((x, i) => {
        const color = colors[i];

        if (!color) throw new Error("Color not found");

        if (!x.presenceOfIdMeansItsReadyToGo) throw new Error("impossible");

        return {
          bold: false,
          type: "expression",
          id: x.presenceOfIdMeansItsReadyToGo,
          expression: x.variable,
          clampStores: initClampStoresForNewBatchVariable(
            x.presenceOfIdMeansItsReadyToGo
          ),
          color,
        };
      })
    );

    chartStore.set(Atoms.selectedVariablesAtom, assertMinLen1(newTrendLines));

    // get the current valid inputs
    const currentExpressionInputs = inputsReadyToReflectInTheChart.filter(
      (x) => x.type === "expression" && x.presenceOfIdMeansItsReadyToGo // only consider valid expressions, not "in edit expressions"
    );

    const clean = chartStore.sub(Atoms.onlyExpressionTrendLinesAtom, () => {
      const newTrendLines = chartStore.get(Atoms.onlyExpressionTrendLinesAtom);

      const deleteTheseInputs = currentExpressionInputs.filter((x) => {
        const stillExistsInChart = newTrendLines.some(
          (y) => y.id === x.presenceOfIdMeansItsReadyToGo
        );
        return !stillExistsInChart; // the chart interaction removed this card
      });

      if (deleteTheseInputs.length > 0) {
        setExpressions((curr) =>
          assertMinLen1(
            curr.filter((x) => {
              const removeIt = deleteTheseInputs.includes(x);
              return !removeIt;
            })
          )
        );
      }
    });
    return clean;
  }, [expressions, chartStore, zone, setExpressions]);

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between mx-3">
        <h3 className="text-lg">{pageTitle}</h3>
        <div className="flex gap-2">
          <Button
            variant="default"
            size="xs"
            onClick={() => {
              setExpressions((curr) => assertMinLen1(curr.concat(EMPTY())));
            }}
          >
            <FaPlus className="h-3 w-3" />
            <span className="ml-1">Add New Expression</span>
          </Button>
          <Button
            variant="default"
            size={"xs"}
            onClick={() => {
              const vars = chartStore?.get(Atoms.selectedVariablesAtom);
              if (vars) {
                setSaveExpressionModalOpen(true);
              } else {
                addToast({
                  title: "Please enter an expression and press calculate first",
                  variant: "danger",
                });
              }
            }}
          >
            <FaSave className="h-3 w-3 mr-2" /> Save
          </Button>
          <Modal
            size="lg"
            opened={saveExpressionModalOpen}
            onClose={() => setSaveExpressionModalOpen(false)}
            centered
            className="p-0"
            withCloseButton={false}
            closeOnClickOutside={true}
          >
            <h3 className="font-medium">Save Expression</h3>
            <ul className="ml-2 mb-3">
              {expressions.map((e, i) => (
                <li key={i} className="pl-3.5">
                  {e.variable}
                </li>
              ))}
              {(chartStore?.get(Atoms.selectedVariablesAtom) || [])
                .filter((x) => x.type === "variable")
                .map((v) => (
                  <li key={(v.type === "variable" && v.bv) || "impossible"}>
                    <FaTag className="inline-flex size-3 fill-xslate-11" />{" "}
                    {v.type === "variable" &&
                      allVariables.data?.find((x) => x._id === v.bv.slice(24))
                        ?.trimmedName}
                  </li>
                ))}
            </ul>
            <input
              type="text"
              className="input input-bordered rounded input-sm w-full h-auto"
              placeholder="Name"
              ref={expressionNameRef}
            />
            <div className="flex justify-between mt-2">
              <Button
                variant={"destructive"}
                size="sm"
                onClick={() => setSaveExpressionModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant={"default"}
                size={"sm"}
                onClick={() => {
                  saveExpression.mutateAsync({
                    name: expressionNameRef.current?.value || "Unnamed",
                    variables: (
                      chartStore?.get(Atoms.selectedVariablesAtom) || []
                    ).map((v) =>
                      v.type === "expression"
                        ? {
                            type: "expression",
                            variable: v.expression,
                          }
                        : {
                            type: "tag",
                            variable: v.bv.slice(24),
                          }
                    ),
                  });
                  setSaveExpressionModalOpen(false);
                }}
              >
                Save
              </Button>
            </div>
          </Modal>
          <Button
            variant="default"
            size={"xs"}
            onClick={() => {
              resetSavedExpression();
              setExpressions([EMPTY()]);
              if (firstRef.current) {
                firstRef.current.innerHTML = "";
              }
              setPageTitle("Instant Calculator");
            }}
          >
            <FaUndo className="h-3 w-3 mr-2" /> Reset
          </Button>
        </div>
      </div>
      {expressions
        .filter((x) => x.type === "expression")
        .map((expression, i) => {
          const handle = (expr: string) => {
            const invalid = expr.trim() === "";

            if (invalid) {
              const isFirst = i === 0;
              if (isFirst) return; // can't remove the first one. This is an invariant. It will mess things up if you allow it.
              setExpressions((curr) =>
                assertMinLen1(
                  curr.map((x) => {
                    if (x === expression) {
                      return {
                        ...x,
                        edit: expr,
                        presenceOfIdMeansItsReadyToGo: undefined,
                      };
                    }
                    return x;
                  })
                )
              );
              return;
            }

            // ready to insert to chart
            setExpressions((curr) =>
              assertMinLen1(
                curr.map((x) => {
                  if (x === expression) {
                    return {
                      ...x,
                      edit: expr,
                      variable: expr, // update the expression to the edited version
                      presenceOfIdMeansItsReadyToGo:
                        x.presenceOfIdMeansItsReadyToGo ?? v4(),
                    };
                  }
                  return x;
                })
              )
            );
          };

          return (
            <ExpressionBox
              clearable={i !== 0}
              hoistedRef={i === 0 ? firstRef : undefined}
              expression={expression.edit}
              setExpression={handle}
              onBlur={handle}
              key={`${expression}-${i}`}
              index={i}
              removeExpressionBox={() => {
                setExpressions((curr) =>
                  assertMinLen1(
                    curr.filter((x) => {
                      return x !== expression;
                    })
                  )
                );
              }}
            />
          );
        })}

      {chartStore ? (
        <div className="ml-3 pt-3">
          <TimeseriesChartTooltipStoreProvider>
            {() => (
              <>
                <GlobalTooltip />
                <Provider store={chartStore}>
                  <DRASecondaryVariableViewNeedsProvider
                    ableToGetTaller={false}
                    width={3000}
                    height={
                      (window.innerHeight * 3000 * 0.7) / window.innerWidth
                    }
                    lineWidthScale={0.4}
                    axesFontScale={0.9}
                    padding={{
                      left: 45,
                    }}
                    expandable={false}
                  />
                </Provider>
              </>
            )}
          </TimeseriesChartTooltipStoreProvider>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <h3 className="animate-in slide-in-from-top-5 text-xl tracking-tight text-xslate-11 fixed top-1/2 text-center">
            Type an expression and click &nbsp;
            <i className="fa fa-calculator" /> Calculate
            <div className="text-xs text-xslate-11 pl-3">
              Surround tag names with double quotes. Use single quotes for dates
              and string tag values.
            </div>
          </h3>
        </div>
      )}
    </div>
  );
}

function ExpressionBox({
  expression,
  index,
  setExpression,
  onBlur,
  removeExpressionBox,
  hoistedRef,
  clearable = true,
}: {
  expression: string;
  index: number;
  setExpression: (expr: string) => void;
  onBlur: (expr: string) => void;
  removeExpressionBox: () => void;
  hoistedRef?: React.RefObject<HTMLDivElement>;
  clearable?: boolean;
}) {
  const [isValidExpression, setIsValidExpression] = useState(true);
  const [validationError, setValidationError] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = hoistedRef || ref;

  // const savedExpressionsQuery = useSavedExpressionsQuery();
  // const savedExpressions = savedExpressionsQuery.data || [];
  // const savedExpressionStrings = savedExpressions.map((e) => e.expression);
  // const saveExpression = useSaveExpressionMutation();

  const allVariables = useVariablesArrayQuery();
  const options =
    allVariables.data
      ?.filter((v) => v.type === "Tag")
      .map((v) => ({
        label: v.trimmedName,
        value: v.name,
        description: v.description,
      })) || [];
  const symbols = allVariables.data?.map((o) => o.name) || [];

  return (
    <>
      <div className="flex p-3 pb-0 items-stretch">
        <ExpressionBuildingInput
          clearable={clearable}
          inputRef={inputRef}
          options={options}
          defaultValue={expression}
          className={cn(
            "bg-white border-r-0 rounded rounded-r-none z-10",
            !isValidExpression && "border-red-500 border-r"
          )}
          onBlur={(e) => {
            // a bit hacky, but delay onBlur to allow the click event to be processed first
            requestAnimationFrame(() => {
              if (e.currentTarget) {
                onBlur(parseExprRef(e.currentTarget.innerHTML));
              }
            });
          }}
          onInput={(e) => {
            const html = e.currentTarget.innerHTML;
            if (html === "") {
              setExpression("");
            }
          }}
        />
        <Button
          className={cn(
            "text-sm min-h-10 h-auto self-stretch",
            index > 0 ? "rounded-none" : "rounded-l-none"
          )}
          variant="default"
          onClick={() => {
            const expr = parseExprRef(inputRef.current?.innerHTML || "");
            try {
              if (validateExpression(expr, symbols, FUNCTIONS)) {
                setIsValidExpression(true);
                setExpression(expr);
              }
            } catch (e) {
              if (e instanceof Error) {
                setIsValidExpression(false);
                setTimeout(() => setIsValidExpression(true), 4000);
                setValidationError(e.message);
              }
            }
          }}
        >
          <FaCalculator className="h-4 w-4 mr-2" />
          Calculate
        </Button>
        {index > 0 && (
          <TooltipProvider delayDuration={50}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="default"
                  className="rounded-l-none text-sm min-h-10 h-auto self-stretch"
                  size="xs"
                  onClick={removeExpressionBox}
                >
                  <FaTrash className="h-3 w-3" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Remove expression</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      {!isValidExpression && (
        <div className="bg-red-500 text-white p-2 -mt-2 w-fit">
          {validationError}
        </div>
      )}
    </>
  );
}

export { InstantCalculatorInputsWithChart };
