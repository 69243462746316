import { PropsWithChildren, useState } from "react";
import Button from "../../common/Button/Button";
import UpdatedAt from "../../common/manager/UpdatedAt";
import moment from "moment";
import { Tooltip } from "@mantine/core";
import {
  useNotificationDeleteMutation,
  useNotificationToggleMutation,
  useNotificationQuery,
} from "../tanstack/useNotificationsQuery";
import Notification, {
  NotificationScheduleLabels,
  NotificationType,
  NotificationTypeLabels,
} from "../../../types/api/Notification";
import CustomNotificationEditForm from "./CustomNotificationEditForm";
import PatternNotificationEditForm, {
  PatternNotificationEditForm2,
} from "./PatternNotificationEditForm";
import { useGetUseDateSelectorsStore } from "../../charts/DTC/useDateSelectorStore";
import { cn } from "../../../lib/utils";
import { RuleFreqDisplay } from "./rule-and-freq-display";
import { PropsWithCn } from "../../types/component.types";
import { addToast } from "../../toast/use-toast-store";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { createStore, Provider, useAtom, useSetAtom } from "jotai";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { TimeseriesChart } from "../../../shared-ui/time-series-2/timeseries-chart";
import { useMemo } from "use-memo-one";
import {
  TrendChartQueryType,
  useTrendChartQuery,
} from "../../../hooks/tanstack-query";
import { chartFormat } from "../../../shared-ui/time-series-2/fetcher-utils";
import { z } from "zod";

function formatDate(date: moment.MomentInput) {
  return moment.utc(date).format("MMM D, YYYY hh:mm A");
}

export function NotificationCard({
  notificationId,
}: {
  notificationId: string;
}) {
  const { data: notif } = useNotificationQuery(notificationId);
  const { mutate, status } = useNotificationDeleteMutation(notificationId);
  const { mutate: toggle, status: toggleStatus } =
    useNotificationToggleMutation(notificationId);
  const [editing, setEditing] = useState(false);

  const processing = status === "loading" || toggleStatus === "loading";

  /* We check to see whether or not an existing edit is happening. If it is, you can't edit another card at the same time. */
  const useDateSelectorsStore = useGetUseDateSelectorsStore();
  const dateSelectors = useDateSelectorsStore();
  const patternEditing = dateSelectors.editing;

  if (!notif) {
    return (
      <div className="mt-6 border border-solid border-[#dbdebdb] bg-white rounded-lg">
        Loading...
      </div>
    );
  }

  if (editing) {
    switch (notif.type) {
      case NotificationType.CUSTOM:
        return (
          <CustomNotificationEditForm
            className="border-t border-x border-zinc-400 last:border-b first:rounded-t-md last:rounded-b-md"
            notificationId={notificationId}
            close={() => {
              setEditing(false);
            }}
          />
        );
      case NotificationType.PATTERN:
        if (!notif.variableId) {
          return (
            <div className="mt-6 border border-solid border-[#dbdebdb] bg-white rounded-lg p-5">
              Malformed Data
            </div>
          );
        }
        return (
          <div className="border-t border-x border-zinc-400 last:border-b first:rounded-t-md last:rounded-b-md">
            <PatternNotificationEditForm
              notification={notif}
              close={() => {
                setEditing(false);
              }}
            />
          </div>
        );
    }
  }

  const chartVariable =
    notif.variableId || notif.calculatedVariableList?.[0]?._id;
  return (
    <Container notification={notif}>
      <Header
        setEditing={setEditing}
        isEditingPattern={patternEditing}
        loading={processing}
        notification={notif}
        onDelete={() =>
          mutate(undefined, {
            onError: (error) => {
              addToast({
                variant: "danger",
                title: "Error deleting notification. " + error.at(0)?.msg,
              });
            },
          })
        }
        onToggle={() =>
          toggle(undefined, {
            onError: (error) => {
              addToast({
                variant: "danger",
                title: error.at(0)?.msg ?? "An unknown error has occurred",
              });
            },
          })
        }
      />
      <RuleDisplayOptionalMiniChart
        notification={notif}
        variableId={chartVariable}
      />
      <Footer notification={notif} />
    </Container>
  );
}

export function NotificationCard2({
  notificationId,
  onEditing,
}: {
  notificationId: string;
  onEditing: (editing: boolean) => void;
}) {
  const { data: notif } = useNotificationQuery(notificationId);
  const { mutate, status } = useNotificationDeleteMutation(notificationId);
  const { mutate: toggle, status: toggleStatus } =
    useNotificationToggleMutation(notificationId);
  const [editing, setEditing] = useState(false);

  const processing = status === "loading" || toggleStatus === "loading";

  const tz = useTimezone();

  /* We check to see whether or not an existing edit is happening. If it is, you can't edit another card at the same time. */
  const [brushState, setBrushState] = useAtom(Atoms.brushStoreAtom);
  const ensureDomain = useSetAtom(Atoms.ensureDomainAtom);
  const patternEditing = brushState && brushState.for === "pattern-notif-edit";

  if (!notif) {
    return (
      <div className="mt-6 border border-solid border-[#dbdebdb] bg-white rounded-lg">
        Loading...
      </div>
    );
  }

  if (editing) {
    switch (notif.type) {
      case NotificationType.CUSTOM:
        return (
          <CustomNotificationEditForm
            className="border-t border-x border-zinc-400 last:border-b first:rounded-t-md last:rounded-b-md"
            notificationId={notificationId}
            close={() => {
              setEditing(false);
            }}
          />
        );
      case NotificationType.PATTERN:
        if (!notif.variableId) {
          return (
            <div className="mt-6 border border-solid border-[#dbdbdb] bg-white rounded-lg p-5">
              Malformed Data
            </div>
          );
        }
        return (
          <div className="border-t border-x border-zinc-400 last:border-b first:rounded-t-md last:rounded-b-md">
            <PatternNotificationEditForm2
              notification={notif}
              close={() => {
                setEditing(false);
                setBrushState(undefined);
              }}
            />
          </div>
        );
    }
  }

  const chartVariable =
    notif.variableId || notif.calculatedVariableList?.[0]?._id;
  return (
    <Container notification={notif}>
      <Header
        setEditing={(x) => {
          onEditing(x);
          setEditing(x);
          if (!x) return;

          if (!notif.parameters) {
            return setBrushState({
              mode: "pattern-notif-edit",
              range: undefined,
            });
          }

          const { end, start } = notif.parameters;
          const s = DateTime.fromISO(start, { zone: "utc" })
            .setZone(tz, { keepLocalTime: true })
            .toMillis();
          const e = DateTime.fromISO(end, { zone: "utc" })
            .setZone(tz, { keepLocalTime: true })
            .toMillis();

          const editRange = [s, e] as [number, number];

          setBrushState({
            mode: "pattern-notif-edit",
            range: editRange,
          });
          ensureDomain(editRange);
        }}
        isEditingPattern={!!patternEditing}
        loading={processing}
        notification={notif}
        onDelete={() =>
          mutate(undefined, {
            onError: (error) => {
              addToast({
                variant: "danger",
                title: "Error deleting notification. " + error.at(0)?.msg,
              });
            },
          })
        }
        onToggle={() =>
          toggle(undefined, {
            onError: (error) => {
              addToast({
                variant: "danger",
                title: error.at(0)?.msg ?? "An unknown error has occurred",
              });
            },
          })
        }
      />
      <RuleDisplayOptionalMiniChart
        notification={notif}
        variableId={chartVariable}
      />
      <Footer notification={notif} />
    </Container>
  );
}
export function Container({
  notification,
  children,
  className,
}: PropsWithCn<
  PropsWithChildren<{
    notification: Notification;
  }>
>) {
  return (
    <li
      className={cn(
        "flex flex-col gap-3 border-t border-x border-zinc-400 last:border-b first:rounded-t-md last:rounded-b-md group transition-colors",
        notification.enabled ? "hover:bg-slate-100 bg-white" : "bg-bggrey",
        className
      )}
    >
      {children}
    </li>
  );
}

export function Footer({ notification }: { notification: Notification }) {
  return (
    <div className="ml-2 mt-1 flex sm:items-center justify-between">
      <UpdatedAt timestamp={notification.updatedAt || notification.createdAt} />
      <div className="mr-2 italic text-[0.8rem] text-zinc-400 my-1 flex justify-end">
        {notification.lastSend
          ? `Last Sent: ${moment(notification.lastSend).format("LLL")}`
          : "Never Sent"}
      </div>
    </div>
  );
}

export function Header({
  loading,
  notification,
  isEditingPattern,
  onDelete,
  onToggle,
  setEditing,
}: {
  loading: boolean;
  notification: Notification;
  isEditingPattern: boolean;
  onDelete: () => void;
  onToggle: () => void;
  setEditing: (editing: boolean) => void;
}) {
  const data = notification;
  return (
    <div className="flex sm:items-center justify-between px-3 pt-2 rounded-t-xl">
      <span
        className={cn(
          "rounded-full tracking-tight px-3 text-[0.9rem] border",
          notification.enabled
            ? `NotificationsManager__badge--${notification.type}`
            : "bg-zinc-50"
        )}
      >
        {NotificationTypeLabels[notification.type]}
      </span>

      {/* Controls */}
      <div className="mt-1 sm:mt-0 flex flex-col sm:flex-row">
        {(data.type === NotificationType.CUSTOM ||
          data.type === NotificationType.PATTERN) && (
          <Tooltip label="Edit" withArrow>
            <Button
              className="btn-ghost"
              disabled={
                loading ||
                (data.type === NotificationType.PATTERN && isEditingPattern)
              }
              icon="pencil"
              onClick={() => {
                setEditing(true);
              }}
            />
          </Tooltip>
        )}

        <Tooltip label="Delete" withArrow>
          <Button
            className="btn-ghost hover:text-xred-9"
            disabled={loading}
            loading={loading}
            icon="trash-o"
            onClick={() => {
              window.confirm("Do you want to delete this notification?") &&
                onDelete();
            }}
          />
        </Tooltip>

        <Tooltip label="Toggle Notification as Active or Disactive" withArrow>
          <input
            type="checkbox"
            className="toggle toggle-sm mt-[0.3em] checked:border-primary checked:bg-primary"
            checked={data.enabled}
            disabled={loading}
            onChange={onToggle}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export function RuleDisplayOptionalMiniChart({
  notification,
  variableId,
}: {
  notification: Notification;
  variableId: string | undefined;
}) {
  const ruleAndFreq = (
    <RuleFreqDisplay
      rule={notification.description}
      frequency={NotificationScheduleLabels[notification.schedule.type]}
    />
  );

  const showMiniChart = notification.type === NotificationType.PATTERN;

  const zone = useTimezone();

  if (!showMiniChart) {
    return ruleAndFreq;
  }

  return (
    <div className="flex items-center">
      <div className="w-[20em] pointer-events-none ml-6 mb-3 border border-zinc-200 bg-zinc-50 rounded-md group-hover:bg-white pt-1">
        {/* <DynamicTrendChart
          strokeWidth={9}
          hideHeader
          variableId={variableId}
          startDate={notification.parameters?.start}
          endDate={notification.parameters?.end}
          showExpandedDA={false}
          showFooter={false}
          showVariableInfo={false}
          showWatchlistToggle={false}
          showXAxis={false}
          showYAxis={false}
          showComments={false}
          showDefaultAnomalyColoration={false}
        /> */}
        <LightweightChart
          variableId={z.string().parse(variableId)}
          start={DateTime.fromISO(
            z.string().parse(notification.parameters?.start),
            {
              zone: "utc",
            }
          )
            .setZone(zone, { keepLocalTime: true })
            .toMillis()}
          end={DateTime.fromISO(
            z.string().parse(notification.parameters?.end),
            {
              zone: "utc",
            }
          )
            .setZone(zone, { keepLocalTime: true })
            .toMillis()}
        />
        <div className="italic text-[0.65em] mx-8 mt-1">
          {notification.parameters?.start && notification.parameters?.end
            ? `${formatDate(notification.parameters?.start)} — ${formatDate(
                notification.parameters?.end
              )}`
            : ""}
        </div>
      </div>
      {ruleAndFreq}
    </div>
  );
}

function LightweightChart({
  end,
  start,
  variableId,
}: {
  variableId: string;
  start: number;
  end: number;
}) {
  const chartStore = useMemo(() => {
    const jot = createStore();

    jot.set(Atoms.setInitialStateAtom, {
      decideAnomBooleanForNewlyAddedVariableTrendLines: () => false,
      expanded: false,
      batchVariablesOrExpressions: [
        {
          type: "variable",
          bv: variableId.padStart(48, "0"),
        },
      ],
    });
    jot.set(Atoms.setDomainAtom, [start, end]);
    jot.set(Atoms.modeTransparencyDefaultValueAtom, true);

    return jot;
  }, [end, start, variableId]);

  const timeseriesQuery = useTrendChartQuery({
    variables: [variableId],
    start,
    end,
    idk: { type: TrendChartQueryType.Regular },
  });

  const timeseriesDataAsChartFormat = useMemo(
    () =>
      (timeseriesQuery.data &&
        chartFormat(timeseriesQuery.data, "0".repeat(24))) ??
      [],
    [timeseriesQuery.data]
  );

  return (
    <Provider store={chartStore}>
      <TimeseriesChart
        myFitnessLimitsMapSorted={undefined}
        limitsMap={undefined}
        inViewOverride
        noDaBars
        stillUseColorForAnomalyColorationOff
        theme="light"
        timezone={useTimezone()}
        height={333}
        width={1000}
        stages={undefined}
        app="DRA"
        data={timeseriesDataAsChartFormat}
        padding={{
          right: 30,
          bottom: 20,
        }}
      />
    </Provider>
  );
}
