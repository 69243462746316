import React, { useState } from "react";
import MainLayout from "../layouts/MainLayout";
import useDocumentTitle from "../common/hooks/useDocumentTitle";
import {
  useDeleteSavedExpressionMutation,
  useSavedExpressionsQuery,
  useSaveExpressionMutation,
  useVariablesArrayQuery,
} from "../../hooks/tanstack-query";
import { cn, iife } from "../../lib/utils";
import { MultiSelect } from "@mantine/core";
import { addToast } from "../toast/use-toast-store";
import { SavedExpression } from "./types";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { UseMutationResult } from "@tanstack/react-query";
import { InstantCalculatorInputsWithChart } from "./inputs-and-chart";
import { TimeseriesChartTooltipStoreProvider } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../time-series/global-tooltip";
import { Button } from "../../shared-ui/frontend/button";
import { FaEllipsisV, FaTag } from "react-icons/fa";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "../../shared-ui/frontend/dropdown-menu";
import { Pencil, Trash2, X } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shared-ui/frontend/tooltip";
import { ScrollArea } from "../../shared-ui/frontend/scroll-area";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../shared-ui/frontend/generic-delete-confirm";
import {
  FullscreenDrawer,
  FullscreenProvider,
} from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { FullscreenChartContainer } from "../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import { Provider } from "jotai";
import { DRASecondaryVariableViewNeedsProvider } from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import { FULLSCREEN_PROPS } from "../pb/profile-book";
// import { MultiSelect } from "../../shared-ui/frontend/multi-select";

function InstantCalculatorPage() {
  useDocumentTitle("Instant Calculator");
  const [drawerOpen, setDrawerOpen] = useState(true);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const [savedExpId, setSavedExpId] = useState<string>();

  const savedExpressionsQuery = useSavedExpressionsQuery();
  const savedExpressions = savedExpressionsQuery.data || [];

  const deleteSavedExpression = useDeleteSavedExpressionMutation();

  return (
    <FullscreenProvider>
      <TimeseriesChartTooltipStoreProvider>
        {() => (
          <MainLayout showDateNav={true}>
            <GlobalTooltip />
            <div className="flex flex-row">
              <div
                className={cn(
                  "bottom-0 left-0 top-12 z-10 bg-white",
                  drawerOpen ? "expand-sidebar" : "collapse-sidebar"
                )}
              >
                {drawerOpen ? (
                  <button
                    onClick={toggleDrawer}
                    className="left-[100%] bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4 border-xindigo-7 border hover:border-xindigo-8 h-28 w-4 rounded-r-lg fixed cursor-pointer top-1/2 -translate-y-1/2 flex items-center transition-all hover:scale-110"
                  >
                    <IoChevronBackOutline className="size-4" />
                  </button>
                ) : (
                  <button
                    onClick={toggleDrawer}
                    className="right-[-25rem] bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4 border-xindigo-7 border hover:border-xindigo-8 h-28 w-4 rounded-r-lg fixed cursor-pointer top-1/2 -translate-y-1/2 flex items-center transition-all hover:scale-110"
                  >
                    <IoChevronForwardOutline className="size-4" />
                  </button>
                )}
                <ScrollArea className="h-[calc(100dvh-88px)] w-[350px]">
                  <h3 className="text-base text-center bg-bggrey shadow font-medium text-xslate-11">
                    Saved Expressions
                  </h3>

                  <div className="p-2">
                    {savedExpressions.length > 0 ? (
                      savedExpressions
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt).getTime() -
                            new Date(a.updatedAt).getTime()
                        )
                        .map((expr) => (
                          <SavedExpressionItem
                            key={expr._id}
                            expr={expr}
                            onClick={() => {
                              setSavedExpId(expr._id);
                            }}
                            deleteSavedExpression={deleteSavedExpression}
                            setSavedExpId={setSavedExpId}
                          />
                        ))
                    ) : (
                      <p className="text-xs text-xslate-11">
                        You have not saved any expressions yet.
                      </p>
                    )}
                  </div>
                </ScrollArea>
              </div>

              {iife(() => {
                if (!savedExpId)
                  return (
                    <InstantCalculatorInputsWithChart
                      key={"probable-dont-even-need-this"}
                      resetSavedExpression={() => setSavedExpId(undefined)}
                    />
                  );

                const savedExp = savedExpressions.find(
                  (expr) => expr._id === savedExpId
                );

                if (!savedExp) return;

                return (
                  <InstantCalculatorInputsWithChart
                    key={
                      savedExpId +
                      savedExp.variables.map((v) => v.variable).join("")
                    }
                    savedExpression={savedExp}
                    resetSavedExpression={() => setSavedExpId(undefined)}
                  />
                );
              })}
            </div>
            <FullscreenChart />
          </MainLayout>
        )}
      </TimeseriesChartTooltipStoreProvider>
    </FullscreenProvider>
  );
}

function FullscreenChart() {
  return (
    <FullscreenDrawer>
      {(store) => (
        <FullscreenChartContainer>
          <Provider store={store}>
            <DRASecondaryVariableViewNeedsProvider
              ableToGetTaller={false}
              {...FULLSCREEN_PROPS()}
            />
          </Provider>
        </FullscreenChartContainer>
      )}
    </FullscreenDrawer>
  );
}

function SavedExpressionItem({
  expr,
  onClick,
  deleteSavedExpression,
  setSavedExpId,
}: {
  expr: SavedExpression;
  deleteSavedExpression: UseMutationResult<void, unknown, string, unknown>;
  onClick: () => void;
  setSavedExpId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(expr.name);
  const [expressions, setExpressions] = useState<string[]>(
    expr.variables.filter((v) => v.type === "expression").map((v) => v.variable)
  );
  const [tags, setTags] = useState<string[]>(
    expr.variables.filter((v) => v.type === "tag").map((v) => v.variable)
  );
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const saveExpression = useSaveExpressionMutation();
  const allVariables = useVariablesArrayQuery()?.data;
  const active = false;
  const disabled = false;
  const lastElement = false;

  return (
    <>
      <GenericDeleteTriggerWithConfirmationPopup
        title="Are you sure you want to delete this expression?"
        open={deleteConfirmationOpen}
        onOpenChange={(open) => {
          !open && setDeleteConfirmationOpen(open);
        }}
        confirmDisabled={deleteSavedExpression.isLoading}
        onConfirm={() => {
          deleteSavedExpression.mutate(expr._id);
          setSavedExpId(undefined);
        }}
      />

      <div
        onClick={onClick}
        className={
          isEditing
            ? "flex justify-between items-center p-2 border-b border-t border-solid border-bordgrey"
            : cn(
                "rounded-md p-2 pl-3 flex items-center gap-3 group transition-all duration-100 text-[13px]",
                {
                  "bg-indigo-800 text-white": active,
                  "hover:bg-zinc-100 hover:cursor-pointer hover:text-indigo-800 hover:pl-5 active:scale-95":
                    !active && !disabled,
                  "mb-5": lastElement,
                  "mb-[2px]": !lastElement,
                  "cursor-not-allowed": disabled,
                }
              )
        }
      >
        {isEditing ? (
          <form
            className="flex flex-col w-full"
            onClick={(e) => e.stopPropagation()}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const id = expr._id;
              saveExpression.mutate({
                _id: id,
                name: name,
                variables: [
                  ...expressions.map((value) => ({
                    type: "expression" as const,
                    variable: value,
                  })),
                  ...tags.map((tag) => ({
                    type: "tag" as const,
                    variable: tag,
                  })),
                ],
              });
              addToast({
                title: "Expression saved",
                variant: "success",
              });
              setIsEditing(false);
            }}
          >
            <label htmlFor={`${expr._id}-name`} className="text-sm font-medium">
              Name:
            </label>
            <input
              type="text"
              value={name}
              id={`${expr._id}-name`}
              onChange={(e) => setName(e.target.value)}
              className="input input-bordered rounded input-sm w-full h-auto mb-2"
            />
            <label className="text-sm font-medium">Expressions:</label>
            {expressions.map((expression, i) => (
              <div
                key={expression}
                className={cn(i > 0 && "mt-1", "flex flex-row")}
              >
                <textarea
                  className="input input-bordered rounded input-sm w-full h-auto"
                  defaultValue={expression}
                  onBlur={(e) => {
                    const newExpressions = expressions.slice();
                    newExpressions[i] = e.currentTarget.value;
                    setExpressions(newExpressions);
                  }}
                  autoComplete="off"
                  rows={Math.ceil(expression.length / 40)}
                />
                {expressions.length > 1 && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                        variant="outline"
                        size={"sm"}
                        className="rounded-l-none border-l-0"
                        onClick={() => {
                          setExpressions(
                            expressions.filter((_, idx) => idx !== i)
                          );
                        }}
                      >
                        <X className="size-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Remove expression</p>
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
            ))}
            {tags.length > 0 && allVariables && (
              <>
                <label className="text-sm font-medium mt-2">Tags:</label>
                <MultiSelect
                  data={allVariables.map((x) => ({
                    value: x._id,
                    label: x.name,
                  }))}
                  value={tags}
                  onChange={(values) => setTags(values)}
                  maxDropdownHeight={370}
                  dropdownPosition="flip"
                  clearSearchOnChange={false}
                  classNames={{
                    input: "rounded-md",
                    value: "bg-zinc-300",
                    dropdown: "border-2 border-bordgrey2",
                  }}
                  clearable={false}
                  creatable={false}
                  searchable={true}
                />
              </>
            )}
            <div className="flex flex-row-reverse mt-2">
              <Button type="submit" variant="default" size="xs">
                Save
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(false);
                }}
                className="mr-2"
                variant={"destructive"}
                size="xs"
              >
                Cancel
              </Button>
            </div>
          </form>
        ) : (
          <div className="flex grow justify-between items-center">
            <div>
              <span className="text-base font-medium">{expr.name}</span>
              <ul>
                {expr.variables.map((variable) => (
                  <li
                    key={variable.variable}
                    className="text-[13px] leading-[18px] pl-4 mb-0.5"
                  >
                    {variable.type === "tag" ? (
                      <>
                        <FaTag className="inline-flex mx-1 size-2.5 fill-xslate-11" />
                        {allVariables?.find((v) => v._id === variable.variable)
                          ?.name || "Deleted Tag"}
                      </>
                    ) : (
                      <div
                        className="inline font-mono rounded p-0.5"
                        style={{
                          color: "#0071bc",
                          backgroundColor: "#e6f0f3",
                        }}
                      >
                        {variable.variable}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <EllipsisDropdown
              setDeleteConfirmationOpen={setDeleteConfirmationOpen}
              triggerEdit={() => setIsEditing(true)}
            />
          </div>
        )}
      </div>
    </>
  );
}

function EllipsisDropdown(props: {
  setDeleteConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  triggerEdit: () => void;
}) {
  const { setDeleteConfirmationOpen, triggerEdit } = props;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size={"icon-sm"} className="shrink-0">
          <FaEllipsisV className="size-3.5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-32">
        <DropdownMenuGroup>
          <Button
            onClick={(e) => {
              e.stopPropagation(); // the parent of this also has its onClick, which we don't want to invoke
              triggerEdit();
            }}
            variant="ghost"
            className="w-full py-0 px-2 justify-start rounded-sm"
          >
            <Pencil className="mr-2 h-4 w-4" />
            <span>Edit</span>
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setDeleteConfirmationOpen(true);
            }}
            variant="ghost"
            className="w-full py-0 px-2 justify-start rounded-sm"
          >
            <Trash2 className="mr-2 h-4 w-4" />
            <span>Delete</span>
          </Button>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default InstantCalculatorPage;
