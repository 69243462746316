import React, { useEffect, useRef, useState } from "react";
import * as R from "remeda";
import draw from "./FaultTreeChartRenderer";
import * as d3 from "d3";
import {
  getAcknowledgements,
  getFaultTreeChart,
} from "../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../zustand/useBaseUrl";
import { YYYY_MM_DD } from "../../lib/validators";
import { faultTreeChartSchema } from "../../lib/api-schema/ft/fault-tree-chart";
import { faultTreeNodeSchema } from "../../lib/api-schema/ft/fault-tree-node";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";
import { cn, PropsWithCn } from "../frontend/cn";

const maxNodesAtLevel = (root: faultTreeChartSchema) => {
  const nodes = d3.hierarchy(root);
  let max = (nodes.children || []).length;
  for (let i = 0; i <= nodes.height; i++) {
    const nodesAtLevel = nodes.descendants().filter((n) => n.depth === i);
    max = Math.max(max, nodesAtLevel.length);
  }
  return max;
};

const getOptionsFromStructure = (
  structure: faultTreeChartSchema,
  inputHeight: number
) => {
  const maxLevel = maxNodesAtLevel(structure); // estimate spacing of stuff based on widest tree level
  return {
    dx: document.fullscreenElement
      ? Math.max(4, inputHeight / maxLevel / 2)
      : Math.max(inputHeight / maxLevel / 2, 15),
    r: document.fullscreenElement
      ? Math.max(2, 7 - Math.floor(maxLevel / 10))
      : Math.max(4.5, 7 - Math.floor(maxLevel / 10)),
    strokeWidth: document.fullscreenElement
      ? Math.max(1, 2.5 - Math.floor(maxLevel / 20))
      : Math.max(1, 2.5 - Math.floor(maxLevel / 20)),
    fontSize: document.fullscreenElement
      ? Math.max(3, 15 - Math.floor(maxLevel / 10))
      : Math.max(9, 15 - Math.floor(maxLevel / 10)),
    haloWidth: document.fullscreenElement
      ? Math.max(3, 15 - Math.floor(maxLevel / 10)) / 4
      : Math.max(3, 15 - Math.floor(maxLevel / 10)) / 4,
  };
};

const filterTree = (
  node: faultTreeChartSchema,
  isRoot = true
): faultTreeChartSchema | null => {
  // Create a shallow copy of the node
  const copiedNode: faultTreeChartSchema = { ...node };

  // If the node has children, process them
  if (copiedNode.children && copiedNode.children.length > 0) {
    // Recursively filter and create copies of children
    copiedNode.children = copiedNode.children
      .map((child) => filterTree(child, false))
      .filter((child) => child !== null) as faultTreeChartSchema[];

    // If the node has any active children, keep it
    if (copiedNode.children.length > 0) {
      return copiedNode;
    }
  }

  // Keep the node if it is active, otherwise filter out
  return copiedNode.status > 0 || isRoot ? copiedNode : null;
};

export function FaultTreeChart2(
  props: {
    style?: React.CSSProperties;
    height?: number;
    width?: number;
    selectedDate: YYYY_MM_DD;
    treeId: string;
    zoomEnabled?: boolean;
    highlightNode?: string;
    filterInactiveNodes?: boolean;
    includeAck?: boolean;
    handleTreeNodeChange: (nodeId: string) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    savedZoomState: any;
  } & PropsWithCn
) {
  const ref = useRef<SVGSVGElement>(null);

  const ftChartQuery = useFaultTreeChartQuery(props.treeId, props.selectedDate);
  const ftChartQueryData = ftChartQuery.data;

  useEffect(() => {
    if (!ftChartQueryData) return;

    const structure =
      props.filterInactiveNodes === true
        ? filterTree(ftChartQueryData.treeStructure)
        : ftChartQueryData.treeStructure;

    if (!structure) return;

    const currentWidth = props.width ?? window.innerWidth;
    const currentHeight = props.height ?? window.innerHeight;

    const drawWithDims = (w: number, h: number) => {
      const options = getOptionsFromStructure(structure, h);

      draw(
        structure,
        props.includeAck === false
          ? {}
          : ftChartQueryData.treeAcknowledgementsMap,
        {
          ...options,
          label: (d: faultTreeNodeSchema) => d.name,
          padding: 1,
          height: h,
          width: w,
          zoomEnabled: !!props.zoomEnabled,
          highlightNode: props.highlightNode,
        },
        ref,
        props.handleTreeNodeChange,
        props.savedZoomState
      );
    };

    drawWithDims(currentWidth, currentHeight); // draw it now

    if (props.height === undefined || props.width === undefined) {
      // one of the two need to be auto adjust upon resize
      const onResize = () => {
        drawWithDims(
          props.width ?? window.innerWidth,
          props.height ?? window.innerHeight
        );
      };

      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }
  }, [
    props.filterInactiveNodes,
    ftChartQueryData,
    props.highlightNode,
    props.height,
    props.width,
    props.zoomEnabled,
    props.handleTreeNodeChange,
    props.savedZoomState,
  ]);

  return (
    <svg
      className={cn("h-auto w-full", props.className)}
      style={props.style}
      ref={ref}
    ></svg>
  );
}

function useGetFaultTreeChartQueryKey() {
  const baseUrl = useBaseUrlExperimental();

  return (treeId: string, selectedDate: YYYY_MM_DD, key?: string | undefined) =>
    ["fault-tree", baseUrl, treeId, selectedDate, key] as const;
}

export function useRefetchFaultTreeChartQuery() {
  const getKey = useGetFaultTreeChartQueryKey();
  const cli = useQueryClient();

  return (...args: Parameters<typeof getKey>) => {
    cli.invalidateQueries(getKey(...args));
  };
}

function useFaultTreeChartQuery(
  treeId: string,
  selectedDate: YYYY_MM_DD,
  key?: string
) {
  const baseUrl = useBaseUrlExperimental();

  const getKey = useGetFaultTreeChartQueryKey();

  return useQuery({
    queryKey: getKey(treeId, selectedDate, key),
    queryFn: async () => {
      const data = await getFaultTreeChart(baseUrl, treeId, selectedDate);

      const acks = await getAcknowledgements(baseUrl, {
        varIds: d3
          .hierarchy(data)
          .descendants()
          .map((n) => n.data._id), // for each tree node, get the acks
        start: selectedDate,
        end: selectedDate,
      });

      /**
       * A map of node id to the latest positive ack for that node
       * If it doesn't exist, they key isn't in the map
       */
      const nodeToPostiveAck = R.pipe(
        acks,
        R.groupBy((a) => a.variable),
        R.forEachObj((grouping) => {
          grouping.sort(
            (a, b) =>
              new Date(b.created_at).valueOf() -
              new Date(a.created_at).valueOf()
          );
        }),
        R.mapValues((grouping) => grouping[0]), // get the latest one
        R.omitBy((acksList) => acksList.unacknowledgment) // we only want to keep positive acks not unacks
      );

      return {
        treeStructure: data,
        treeAcknowledgementsMap: nodeToPostiveAck,
      };
    },
    cacheTime: minutesToMilliseconds(2),
  });
}
