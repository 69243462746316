import { AnomalyLevelEnum, AnomalyValidator } from "../lib/anomaly-levels";
import { type MinLen1 } from "../lib/types";
import * as R from "remeda";
import { Changepoints, Timeseries, TimeseriesForBv } from "./types";
import { iife, minLen1 } from "../lib/utils";
import { type Equal } from "type-testing";

export type DRATimeseries = {
  value: number;
  variable: string;
  // stage: string;
  timestamp: number;
  da: number | null;
  mode: string | null;
  // batchVariable: string;
}[];

export function chartFormat(data: DRATimeseries, batch: string) {
  const ignoreSpecialAnomalyValue = (
    d: number | null
  ): AnomalyLevelEnum | null => {
    if (d === null) return d;

    const parsed = AnomalyValidator.safeParse(d);

    if (parsed.success) return parsed.data;
    return null;
  };

  type Segment = {
    pts: MinLen1<{
      v: number;
      t: number;
    }>;
    d: number | null;
    r: [number, number];
  };

  const partitionByAnomaly = (
    s: [
      {
        value: number;
        variable: string;
        timestamp: number;
        da: number | null;
        mode: string | null;
      },
      ...{
        value: number;
        variable: string;
        timestamp: number;
        da: number | null;
        mode: string | null;
      }[],
    ]
  ): MinLen1<Segment> => {
    const partitionedByAnomaly: Array<Segment> = [];

    const leftoverPoints = s;

    while (leftoverPoints.length > 0) {
      const firstPoint = leftoverPoints[0];
      if (!firstPoint) throw new Error("impossible");

      const anom = ignoreSpecialAnomalyValue(firstPoint.da);

      const idx = leftoverPoints.findIndex(
        (p) => ignoreSpecialAnomalyValue(p.da) !== anom
      );

      if (idx === -1) {
        // all the same

        partitionedByAnomaly.push({
          d: ignoreSpecialAnomalyValue(leftoverPoints[0].da),
          pts: iife(() => {
            const out = leftoverPoints.map((x): Segment["pts"][number] => {
              return {
                t: x.timestamp,
                v: x.value,
              };
            });
            if (!minLen1(out)) throw new Error("impossible");

            return out;
          }),
          r: [
            R.minBy(leftoverPoints, (x) => x.value)!.value,
            R.maxBy(leftoverPoints, (x) => x.value)!.value,
          ],
        });

        break;
      }

      const firstPart = leftoverPoints.splice(0, idx);
      if (!minLen1(firstPart)) throw new Error("impossible");

      const firstPointOfNextSegment = leftoverPoints[0]; // 0 because we spliced it

      firstPart.push({
        /**
         * Each point in partition is from the same
         * batchvariable and stage, but they have different
         * DAs. We want to show them as "connected" in the
         * chart, so we add a point to the end of each
         * partition to artificially connect them.
         */
        ...firstPointOfNextSegment,
        da: anom,
        timestamp: firstPointOfNextSegment.timestamp - 1,
        // minus 1 millisecond to connect them
      });

      partitionedByAnomaly.push({
        d: ignoreSpecialAnomalyValue(firstPart[0].da),
        pts: iife(() => {
          const out = firstPart.map((x): Segment["pts"][number] => {
            return {
              t: x.timestamp,
              v: x.value,
            };
          });

          if (!minLen1(out)) throw new Error("impossible");
          return out;
        }),
        r: [
          R.minBy(firstPart, (x) => x.value)!.value,
          R.maxBy(firstPart, (x) => x.value)!.value,
        ],
      });
    }

    if (!minLen1(partitionedByAnomaly)) throw new Error("expected >=1");

    return partitionedByAnomaly;
  };

  const m = R.groupBy(data, (d) => d.variable);

  // we need them sorted
  for (const arr of Object.values(m)) {
    arr.sort((a, b) => a.timestamp - b.timestamp);
  }

  const out: TimeseriesForBv[] = [];

  for (const variableId in m) {
    const sortedPoints = m[variableId]!;

    // domain and range for all the data of this variable id
    const d: [number, number] = [Infinity, -Infinity];
    const r: [number, number] = [Infinity, -Infinity];
    for (const x of sortedPoints) {
      if (x.timestamp < d[0]) d[0] = x.timestamp;
      if (x.timestamp > d[1]) d[1] = x.timestamp;
      if (x.value < r[0]) r[0] = x.value;
      if (x.value > r[1]) r[1] = x.value;
    }

    let left = 0;

    const partitionedByMode: Array<typeof sortedPoints> = [];

    while (left < sortedPoints.length) {
      const currStage = sortedPoints[left]!.mode;

      let right = left + 1;

      while (
        right < sortedPoints.length &&
        sortedPoints[right]!.mode === currStage
      ) {
        right++;
      }

      const stagePoints = sortedPoints.slice(left, right);

      if (!minLen1(stagePoints)) throw new Error("impossible");
      partitionedByMode.push(stagePoints);

      left = right;
    }

    // go in between where modes change and connect them
    // by adding another point to make it look connected
    for (let i = 0; i < partitionedByMode.length; i++) {
      const thisModePoints = partitionedByMode[i]!;
      const nextModePoints = partitionedByMode[i + 1];

      if (!nextModePoints) continue; // out of bounds

      const lastPoint =
        thisModePoints[thisModePoints.length - 1] ?? thisModePoints[0];

      const firstPointOfNextStage = nextModePoints[0];

      thisModePoints.push({
        ...lastPoint, // inherit other values

        /**
         * Artificial connection at the point when stages change so
         * it won't appear piece-wise.
         */
        timestamp: firstPointOfNextStage.timestamp - 1,
        value: firstPointOfNextStage.value,
      });
    }

    const stages = partitionedByMode.map(
      (x): TimeseriesForBv["stages"][number] => {
        return {
          _id: x[0].mode ?? "Remainder",
          d: [x[0].timestamp, x[x.length - 1]!.timestamp],
          r: [
            R.minBy(x, (x) => x.value)!.value,
            R.maxBy(x, (x) => x.value)!.value,
          ],
          ptsPartitioned: partitionByAnomaly(x),
        };
      }
    );

    if (!minLen1(stages)) throw new Error("impossibel");

    const out_: TimeseriesForBv = {
      type: "variable",
      bv: `${batch}${variableId}`,
      stages,
      d,
      r,
    };

    out.push(out_);
  }

  return out;
}

type Almost = Omit<
  DRATimeseries[number],
  keyof Pick<DRATimeseries[number], "value">
> & { value: number | null };

const _mustCompile: Equal<
  Omit<DRATimeseries[number], keyof Pick<DRATimeseries[number], "value">>,
  Omit<Almost, keyof Pick<Almost, "value">>
> = true;

export function mergeChangepoints(
  _data: { [variableId: string]: Timeseries[] },
  _changepoints: { [variableId: string]: Changepoints[] }
): DRATimeseries {
  const variablesIds = Object.keys(_data);

  const out: Almost[] = [];

  /**
   * Merge timeseries data with changepoints
   */
  for (const vid of variablesIds) {
    const tsPointsForVid = _data[vid];
    const changepoints = _changepoints[vid];

    if (!tsPointsForVid || !changepoints) {
      throw new Error("Missing data or changepoints");
    }

    // keep tracking of the left changepoint
    // increment it when we reach the right changepoint
    let leftChangepointIdx = 0;

    /**
     * Using a while loop because I don't want to i++
     * on every iteration,
     */
    let i = 0;
    while (i < tsPointsForVid.length) {
      const lCp = changepoints[leftChangepointIdx];
      const rCp = changepoints[leftChangepointIdx + 1];

      if (!lCp) throw new Error("no left changepoint");

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const dataPoint = tsPointsForVid[i]!;
      const pointTime = dataPoint.timestamp;

      if (rCp) {
        // if the right changepoint exists, then the left isn't the last one
        const pointIsBeforeRightCp = pointTime < rCp.timestamp;

        if (pointIsBeforeRightCp) {
          // this point still has the same DA as previous
          out.push({
            da: lCp.da,
            mode: lCp.mode,
            timestamp: dataPoint.timestamp,
            value: dataPoint.value,
            variable: vid,
          });
          i++;
        } else {
          // we've reached or passed the right changepoint

          // check if we need to interpolate
          const shouldInterpolate = pointTime > rCp.timestamp;

          if (shouldInterpolate) {
            const prevPoint = tsPointsForVid[i - 1];
            if (prevPoint) {
              // todo is this first point necessary?
              // it seems to work without this first push
              out.push({
                da: lCp.da,
                mode: lCp.mode,
                timestamp: rCp.timestamp,
                value: rCp.value ?? null,
                variable: vid,
              });
              out.push({
                da: rCp.da,
                mode: rCp.mode,
                timestamp: rCp.timestamp,
                value: rCp.value ?? null,
                variable: vid,
              });
            }
          }

          // make the right changepoint the new left one
          leftChangepointIdx++;
          // dont handle the point, we'll handle it in the next iteration
        }
      } else {
        // no more changepoints to the right, we're on the last one
        // simply push all the points
        out.push({
          da: lCp.da,
          mode: lCp.mode,
          timestamp: dataPoint.timestamp,
          value: dataPoint.value,
          variable: vid,
        });
        i++;
      }
    }
  }

  /**
   * There can now be NaNs in the data, we need to remove them.
   *
   * This isn't the best way to do it but whatever because
   * I don't want to mess up the merging code above which is
   * already very brittle.
   */

  for (let i = 0; i < out.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pt = out[i]!;

    if (pt.value !== null) continue;

    let backPt, forwardPt;

    for (let back = i - 1; back >= 0; back--) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const pt = out[back]!;
      if (pt.value !== null) {
        backPt = pt;
        break;
      }
    }

    for (let forward = i + 1; forward < out.length; forward++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const pt = out[forward]!;

      if (pt.value !== null) {
        forwardPt = pt;
        break;
      }
    }

    if (backPt) {
      if (forwardPt) {
        // best case scenario, we can interpolate
        const slope =
          (forwardPt.value! - backPt.value!) /
          (forwardPt.timestamp - backPt.timestamp);

        const interpolatedValue =
          forwardPt.value! - slope * (forwardPt.timestamp - pt.timestamp);

        pt.value = interpolatedValue;
      } else {
        // just take the value of back
        pt.value = backPt.value!;
      }
    } else {
      if (forwardPt) {
        // just take the value of forward
        pt.value = forwardPt.value!;
      } else {
        // we're screwed because there's no value to take, we just have to remove it
        // later on do a filter for .value which are still null
      }
    }
  }

  const out2 = out.filter(
    (x) => x.value !== null
  ) satisfies Almost[] as unknown as DRATimeseries;

  return out2;
}
