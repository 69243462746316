import { useParams } from "react-router";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import MainLayout from "../../layouts/MainLayout";
import useBaseUrl from "../../../zustand/useBaseUrl";
import { FaultTreeChart2 } from "../../../shared-ui/d3ft/FaultTreeChart2";
import { useDateState } from "../../../zustand/useDateState";
import { useEffect, useRef, useState } from "react";
import { Input } from "../../../shared-ui/frontend/input";
import {
  useAllFaultTreeNodesQuery,
  useFaultTreeNodeDeleteMutation,
  useFaultTreeNodeCreateMutation,
  useFaultTreeNodesQuery,
  useFaultTreeQuery,
  useFaultTreeNodeMutation,
  useFaultTreeMutation,
} from "../../../hooks/tanstack-query";
import MainLoader from "../../common/MainLoader";
import { Textarea } from "../../../shared-ui/frontend/text-area";
import { InstantCalculator } from "../../instant-calculator/InstantCalculator";
import ExpressionBuildingInput from "../../../shared-ui/expression-building-input/ExpressionBuildingInput";
import { cn, iife } from "../../../lib/utils";
import { Button } from "../../ui/button";
import OldButton from "../../common/Button/Button";
import { parseExprRef } from "../../../shared-ui/expression-building-input/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shared-ui/frontend/select";
import { faultTreeNodeSchema } from "../../../lib/api-schema/ft/fault-tree-node";
import { Link } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { Modal } from "@mantine/core";
import { FaPencil } from "react-icons/fa6";
import { FileUploader } from "./file-uploader";
import { FaultTreeNodeFiles } from "./use-ft-node-files-query";
import { FilesPills } from "./files-pills";
import { POST_FILE } from "../../../frameworks/fetcher/fetcher-experimental";
import { FaUndo } from "react-icons/fa";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../../shared-ui/frontend/generic-delete-confirm";
import { addToast } from "../../toast/use-toast-store";

export function FaultTreeEditor() {
  useDocumentTitle("FT > DRA");
  const match = useParams();
  const treeId = match.treeId;
  const ftQuery = useFaultTreeQuery(treeId || "");
  const ft = ftQuery.data;
  const nodesQuery = useFaultTreeNodesQuery(treeId || "");
  const [selectedNode, setSelectedNode] = useState<
    faultTreeNodeSchema | undefined
  >(undefined);
  const nodes = nodesQuery.data || [];

  // expression building input setup
  const nodeRef = useRef<HTMLDivElement>(null);
  const tagRef = useRef<HTMLDivElement>(null);
  // keep local copy of expressions for displaying as full expression
  const [nodeExpression, setNodeExpression] = useState(
    selectedNode?.nodeExpression || ""
  );
  const [tagExpression, setTagExpression] = useState(
    selectedNode?.tagExpression || ""
  );
  const [connector, setConnector] = useState(
    selectedNode?.expressionConnector || "&"
  );
  const allNodesQuery = useAllFaultTreeNodesQuery();
  const allNodes = allNodesQuery.data || [];
  const options =
    allNodes.map((v) => ({
      label: v.name,
      value: v.name,
      description: undefined,
    })) || [];

  const createNodeMut = useFaultTreeNodeCreateMutation();
  const deleteNodeMut = useFaultTreeNodeDeleteMutation();
  const editNodeMut = useFaultTreeNodeMutation();
  const baseUrl = useBaseUrl();
  const selectedDateEnd = useDateState().axisRangeTo.dateString;
  const savedZoomState = useRef<unknown>();
  const hasEditAccess = useHasEditPermission();
  const [showPublishingModal, setShowPublishingModal] = useState(false);
  const editMut = useFaultTreeMutation();
  const togglePublished = () => {
    if (!ft) return;
    editMut.mutate({
      ...ft,
      published: !ft.published,
    });
  };
  const [editingName, setEditingName] = useState(false);
  const [creatingChild, setCreatingChild] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const refetchFilesQuery = FaultTreeNodeFiles.useInvalidateQueryForUnit();
  const uploadFiles = () => {
    if (files.length > 0 && selectedNode) {
      const formData = new FormData();
      for (const f of files) {
        formData.append("file", f);
      }
      POST_FILE(
        `${baseUrl}/fault-tree-nodes/${selectedNode._id}/files`,
        formData
      ).then(() => {
        refetchFilesQuery();
        setFiles([]);
      });
    }
  };
  const [reset, setReset] = useState(0);
  useEffect(() => {
    savedZoomState.current = null;
  }, [reset]);

  // select root node if no node is selected
  useEffect(() => {
    if (nodesQuery.data && !selectedNode) {
      setSelectedNode(nodesQuery.data[0]);
    }
  }, [nodesQuery.data, selectedNode]);
  // any time the selected node changes the node/tag expressions should update
  useEffect(() => {
    setNodeExpression(selectedNode?.nodeExpression || "");
    setTagExpression(selectedNode?.tagExpression || "");
    setConnector(selectedNode?.expressionConnector || "&");
    setCreatingChild(false);
  }, [selectedNode]);

  if (!treeId) {
    // they accessed the page without the tree Id, redirect to all trees page
    window.location.href = `${baseUrl}/ft`;
    return null;
  }
  if (!ft) {
    return (
      <MainLayout showDateNav={false}>
        <MainLoader />
      </MainLayout>
    );
  }

  const publishButton = (
    <OldButton
      size="xs"
      // variant={ft.published ? "default" : "neutral"}
      className={cn(
        "mr-2",
        ft.published && "btn-success",
        !hasEditAccess && "pointer-events-none"
      )}
      // disabled={!hasEditAccess}
      onClick={() =>
        hasEditAccess &&
        (ft.published ? togglePublished() : setShowPublishingModal(true))
      }
    >
      {ft.published ? "Published" : "Unpublished"}
    </OldButton>
  );

  return (
    <MainLayout showDateNav={false}>
      <Modal
        classNames={{ modal: "rounded-xl" }}
        size="35%"
        opened={showPublishingModal}
        centered={true}
        onClose={() => {
          setShowPublishingModal(false);
        }}
        title={`Are you sure you want to publish ${ft?.name}? Processing will take some time.`}
      >
        <form
          className="flex"
          onSubmit={(e) => {
            e.preventDefault();
            setShowPublishingModal(false);
            togglePublished();
          }}
        >
          <Button type="submit" className="ml-auto">
            Publish
          </Button>
        </form>
      </Modal>
      <div className="flex flex-row place-items-center justify-between">
        <Link to={`${baseUrl}/control/ft`}>
          <Button variant={"ghost"} className="text-md">
            <ChevronLeft />
            All Fault Trees
          </Button>
        </Link>
        {editingName ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const form = e.currentTarget;
              const formData = new FormData(form);
              const name = formData.get("name") as string;
              editMut.mutate({
                ...ft,
                name,
              });
              setEditingName(false);
            }}
            className="mt-1 flex flex-row"
          >
            <Input
              defaultValue={ft.name}
              name="name"
              autoFocus
              className="min-w-80 rounded-r-none border-r-0"
            />
            <Button
              type="button"
              onClick={() => setEditingName(false)}
              className="rounded-none"
              variant={"destructive"}
            >
              Cancel
            </Button>
            <Button type="submit" className="rounded-l-none border-l-0">
              Save
            </Button>
          </form>
        ) : (
          <div className="flex flex-row place-items-center">
            <Button
              variant={"ghost"}
              onClick={() => setEditingName(true)}
              disabled={!hasEditAccess}
            >
              <FaPencil />
            </Button>
            <h1 className="text-xl">{ft.name}</h1>
          </div>
        )}
        {hasEditAccess ? (
          <Tooltip>
            <TooltipTrigger asChild>{publishButton}</TooltipTrigger>
            <TooltipContent>
              Click to {ft.published ? "un" : ""}publish
            </TooltipContent>
          </Tooltip>
        ) : (
          publishButton
        )}
      </div>
      <div className="MIN_H_SCREEN2 flex flex-row">
        <div className="relative m-2 w-1/2 overflow-hidden rounded border border-xslate-7 bg-white p-2">
          <FaultTreeChart2
            key={`${selectedNode?._id}${selectedNode?.expression}${reset}`}
            treeId={treeId}
            selectedDate={selectedDateEnd}
            zoomEnabled={true}
            highlightNode={selectedNode?._id}
            savedZoomState={savedZoomState}
            handleTreeNodeChange={(id) => {
              setSelectedNode(nodes.find((n) => n._id === id));
            }}
            includeAck={false}
            height={window.innerHeight - 100}
            width={window.innerWidth / 2}
          />
          <Button
            variant={"ghost"}
            onClick={() => setReset((r) => r + 1)}
            className="absolute bottom-0 right-0"
          >
            <FaUndo />
          </Button>
        </div>
        <div className="m-2 w-1/2 rounded border border-xslate-7 bg-white px-3 py-2">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const nodeId = selectedNode?._id;
              if (!nodeId) return;
              uploadFiles();
              const form = e.currentTarget;
              const formData = new FormData(form);
              const node = {
                name: formData.get("name") as string,
                tagExpression: parseExprRef(tagRef.current?.innerHTML || ""),
                nodeExpression: parseExprRef(nodeRef.current?.innerHTML || ""),
                recommendation: formData.get("rec") as string,
                expressionConnector: connector,
              };
              editNodeMut.mutate({ nodeId, node });
            }}
            className="flex flex-col gap-2"
            // key is needed because only defaultValue can be set on expression building input (to support placeholder I think?)
            key={`${selectedNode?._id}${selectedNode?.expression}${reset}`}
          >
            <div>
              <label htmlFor="name">Node Name</label>
              <Input
                defaultValue={selectedNode?.name}
                id="name"
                name="name"
                disabled={ft.published || !hasEditAccess}
              />
            </div>
            <div>
              <span>Node Expression</span>
              <div className={cn("rounded bg-xslate-2 p-4 shadow")}>
                <label htmlFor="tag_refs">Tag References</label>
                {!ft.published ? (
                  <InstantCalculator
                    booleanExpression={false}
                    defaultValue={selectedNode?.tagExpression}
                    defaultInputRef={tagRef}
                    onInput={(e) => setTagExpression(e)}
                    includeSlowFunctions={true}
                  />
                ) : (
                  <div className="mb-1 min-h-10 cursor-not-allowed rounded-md border border-xslate-7 p-2 font-mono">
                    {selectedNode?.tagExpression}
                  </div>
                )}
                <label htmlFor="node_refs">Node References</label>
                {!ft.published ? (
                  <ExpressionBuildingInput
                    inputRef={nodeRef}
                    options={options}
                    defaultValue={selectedNode?.nodeExpression}
                    // defaultValue={nodeExpression}
                    className={cn("rounded-lg border-xslate-7")}
                    onInput={(e) =>
                      setNodeExpression(parseExprRef(e.currentTarget.innerHTML))
                    }
                    noFunctionsOrOperators={true}
                  />
                ) : (
                  <div className="min-h-10 cursor-not-allowed rounded-md border border-xslate-7 p-2 font-mono">
                    {selectedNode?.nodeExpression}
                  </div>
                )}
                <div className="mt-2">
                  Full Expression (Node References
                  <Select
                    value={connector}
                    onValueChange={setConnector}
                    name="connector"
                    disabled={ft.published || !hasEditAccess}
                  >
                    <SelectTrigger className="m-1 inline-flex h-6 w-20">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={"&"}>AND</SelectItem>
                        <SelectItem value={"|"}>OR</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  Tag References)
                </div>
                <div className="min-h-10 rounded-md border border-xslate-7 p-2 font-mono">
                  {iife(() => {
                    const needsConnector =
                      nodeExpression.length > 0 && tagExpression.length > 0;
                    return needsConnector
                      ? `(${nodeExpression}) ${connector} (${tagExpression})`
                      : nodeExpression + tagExpression;
                  })}
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="rec">Recommendation</label>
              <Textarea
                id="rec"
                name="rec"
                defaultValue={selectedNode?.recommendation}
                disabled={ft.published || !hasEditAccess}
              />
            </div>
            {selectedNode && (
              <div>
                <FilesPillsSection
                  nodeId={selectedNode._id}
                  isPublished={ft.published}
                />
                <FileUploader
                  disabled={ft.published || !hasEditAccess}
                  nodeId={selectedNode._id}
                  files={files}
                  setFiles={setFiles}
                />
              </div>
            )}
            <div className="flex flex-row justify-between">
              {ft.published || !hasEditAccess ? (
                <Button
                  variant={"destructive"}
                  type="button"
                  size={"sm"}
                  disabled={true}
                >
                  Delete Node
                </Button>
              ) : (
                <GenericDeleteTriggerWithConfirmationPopup
                  title={`Are you sure you want to delete ${selectedNode?.name}?`}
                  onConfirm={() => {
                    const id = selectedNode?._id;
                    const parent = nodes.find(
                      (n) => n._id === selectedNode?.parentId
                    );
                    if (!parent) {
                      addToast({
                        title: "Cannot delete top node",
                        variant: "danger",
                      });
                      return;
                    }
                    if (id)
                      deleteNodeMut.mutateAsync(id).then(() => {
                        setSelectedNode(parent);
                      });
                  }}
                  confirmDisabled={false}
                >
                  <Button variant={"destructive"} type="button" size={"sm"}>
                    Delete Node
                  </Button>
                </GenericDeleteTriggerWithConfirmationPopup>
              )}
              {creatingChild ? (
                <div className="flex flex-row">
                  <Input
                    name="child-name"
                    id="child-name"
                    placeholder="Type Child Node Name"
                    autoFocus
                    className="h-9 w-80 rounded-r-none border-r-0"
                  />
                  <Button
                    variant={"destructive"}
                    className="rounded-none border-r-0"
                    size="sm"
                    type="button"
                    onClick={() => setCreatingChild(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"default"}
                    className="rounded-l-none"
                    size={"sm"}
                    type="button"
                    disabled={ft.published || !hasEditAccess}
                    onClick={() => {
                      setCreatingChild(false);
                      const id = selectedNode?._id;
                      const name = (
                        document.getElementById(
                          "child-name"
                        ) as HTMLInputElement
                      ).value;
                      if (id)
                        createNodeMut
                          .mutateAsync({
                            faultTreeId: ft._id,
                            parentId: id,
                            name: name,
                            tagExpression: "",
                            nodeExpression: "",
                            recommendation: "",
                            expressionConnector: "&",
                          })
                          .then((newNode) => {
                            setSelectedNode(newNode as faultTreeNodeSchema);
                          });
                    }}
                  >
                    Create
                  </Button>
                </div>
              ) : (
                <Button
                  variant={"outline"}
                  size={"sm"}
                  disabled={ft.published || !hasEditAccess}
                  onClick={() => setCreatingChild(true)}
                >
                  Add Child Node
                </Button>
              )}
              <Button
                variant={"default"}
                size={"sm"}
                type="submit"
                disabled={ft.published || !hasEditAccess}
              >
                Save Node
              </Button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
}

function FilesPillsSection({
  nodeId,
  isPublished,
}: {
  nodeId: string;
  isPublished: boolean;
}) {
  const filesQuery = FaultTreeNodeFiles.useQuery(nodeId);
  const canDelete = useHasEditPermission();

  if (filesQuery.isError) throw filesQuery.error;

  const files = filesQuery.data;

  if (!files || files.length === 0) return null;

  return (
    <>
      <label>Files</label>
      <FilesPills
        allowDelete={!isPublished && canDelete}
        files={files}
        className="mb-4 rounded-lg border border-zinc-300 p-3"
      />
    </>
  );
}
