import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { createStore } from "zustand";

function createModeDrawerStore() {
  return createStore<{
    modeId: string | undefined;
    setModeId: (modeId: string | undefined) => void;
  }>((set) => ({
    modeId: undefined,
    setModeId: (modeId) => set({ modeId }),
  }));
}

type ModeDrawerStore = ReturnType<typeof createModeDrawerStore>;

const ModeDrawerStoreContext = createContext<ModeDrawerStore | undefined>(
  undefined
);

function ModeDrawerStoreProvider({ children }: PropsWithChildren) {
  const [store] = useState(createModeDrawerStore);
  return (
    <ModeDrawerStoreContext.Provider value={store}>
      {children}
    </ModeDrawerStoreContext.Provider>
  );
}

function useModeDrawerStoreNotRequired() {
  return useContext(ModeDrawerStoreContext);
}

function useModeDrawerStore() {
  const store = useContext(ModeDrawerStoreContext);

  if (!store) {
    throw new Error("useModeStore must be used within a ModeStoreProvider");
  }

  return store;
}

export {
  ModeDrawerStoreProvider,
  useModeDrawerStore,
  useModeDrawerStoreNotRequired,
};
