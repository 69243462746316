import { FaCut, FaEye, FaEyeSlash, FaPlus, FaUndo } from "react-icons/fa";
import { Button } from "../../frontend/button";
import {
  type PropsWithCn,
  type PropsWithCnAndChildren,
  cn,
} from "../../frontend/cn";
import {
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "../../frontend/hover-card";
import { useEffect, useState, type ComponentProps } from "react";
import { Atoms, isVariableTrendLine } from "../svv-store/use-svv-store";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../frontend/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../frontend/tooltip";
import {
  createUseClampStore,
  useGetUseMinMaxClampsStores,
  UseMinMaxClampsStoresContext,
} from "../clamps/clamp-popover-store";
import { PRECISION } from "../clamps/utils";
import { Label } from "../../frontend/label";
import { ClampInputToggle } from "../clamps/clamp-input-toggle";
import { YAxisMode } from "../../time-series/types";
import { atom, useAtomValue, useSetAtom, useStore } from "jotai";

const BUTTON_SIZE: ComponentProps<typeof Button>["size"] = "icon-md";

function Container({ children, className }: PropsWithCnAndChildren) {
  return (
    <div
      className={cn(
        // @container allows us to dynamically hide some of its children
        // based on this div's size. See https://github.com/tailwindlabs/tailwindcss-container-queries
        "hidden items-center justify-around gap-3 p-1 pt-2.5 @xl:flex",
        className
      )}
    >
      {children}
    </div>
  );
}

function EyeButton({ className }: PropsWithCn) {
  const single = useAtomValue(Atoms.singleVariableViewAtom);
  const primary = useAtomValue(Atoms.primaryBatchVariableAtom);

  const setAllAnomalyColoration = useSetAtom(Atoms.setAllAnomalyColorationAtom);

  if (!single) return undefined;

  if (!isVariableTrendLine(primary)) return undefined;

  const isAnomalyColoration = primary.anom;

  const ColorationIcon = isAnomalyColoration ? FaEye : FaEyeSlash;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant={"ghost"}
          size={BUTTON_SIZE}
          className={className}
          onClick={(e) => {
            e.stopPropagation();
            setAllAnomalyColoration(!isAnomalyColoration);
          }}
        >
          <ColorationIcon className="size-3" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {isAnomalyColoration ? "Hide" : "Show"} anomalies
      </TooltipContent>
    </Tooltip>
  );
}

function VariableSelectOverlayButton() {
  const open = useSetAtom(Atoms.variableSelectDrawerOpenAtom);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant={"outline"}
          size={BUTTON_SIZE}
          onClick={() => open(true)}
          className="size-7"
        >
          <FaPlus className="size-3" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Overlay Tags</TooltipContent>
    </Tooltip>
  );
}

function StoreInspect({ className }: PropsWithCn) {
  const selectedVariables = useAtomValue(Atoms.selectedVariablesAtom);
  const zoom = useAtomValue(Atoms.zoomAtomAtom);

  const xScale = useAtomValue(Atoms.getXScaleAtom);

  if (process.env.NODE_ENV !== "development") return null;

  return (
    <HoverCardRoot>
      <HoverCardTrigger asChild>
        <Button
          className={cn("self-center", className)}
          size={"xxs"}
          variant={"outline"}
        >
          Inspect State
        </Button>
      </HoverCardTrigger>
      <HoverCardContent className="h-[50dvh] w-auto overflow-scroll">
        <pre className="text-xs">
          {JSON.stringify(
            {
              selectedVariables:
                selectedVariables.map((x) => {
                  return {
                    ...x,
                    clampStores: x.clampStores?.map((x) => typeof x),
                  };
                }) ?? null,
              zoom,
              xScale: !!xScale,
            },
            null,
            2
          )}
        </pre>
      </HoverCardContent>
    </HoverCardRoot>
  );
}

function ResetButton({
  domain,
}: {
  /**
   * What should the domain be reset to when the
   * reset button is clicked?
   */
  domain: [number, number];
}) {
  const reset = useSetAtom(Atoms.resetAtom);
  const setDomain = useSetAtom(Atoms.setDomainAtom);
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant={"ghost"}
          size={BUTTON_SIZE}
          onClick={() => {
            reset();
            setDomain(domain);
          }}
        >
          <FaUndo className="size-3" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Reset</TooltipContent>
    </Tooltip>
  );
}

const globalRangeGlobalMinAtom = atom((get) => {
  return get(Atoms.globalRangeAtom)?.min;
});

const globalRangeGlobalMaxAtom = atom((get) => {
  return get(Atoms.globalRangeAtom)?.max;
});

function _GlobalAbsoluteClampsPopoverInternal(
  popoverContentProps: Pick<
    ComponentProps<typeof PopoverContent>,
    "alignOffset" | "align" | "side" | "sideOffset"
  >
) {
  const stores = useGetUseMinMaxClampsStores();
  if (!stores) throw new Error("Expected");

  const [minStore, maxStore] = stores;
  const minIsAuto = minStore((s) => s.auto);
  const maxIsAuto = maxStore((s) => s.auto);
  const minValue = minStore((s) => s.inputValue);
  const maxValue = maxStore((s) => s.inputValue);

  const minAutoValue =
    useAtomValue(globalRangeGlobalMinAtom)?.toFixed(PRECISION) ?? "";
  const maxAutoValue =
    useAtomValue(globalRangeGlobalMaxAtom)?.toFixed(PRECISION) ?? "";

  const jot = useStore();

  const setYClampMin = useSetAtom(Atoms.setYClampMinAtom);
  const setYClampMax = useSetAtom(Atoms.setYClampMaxAtom);

  useEffect(
    function handleDataSync() {
      if (minIsAuto)
        return jot.sub(globalRangeGlobalMinAtom, () => {
          const stringDisplayed =
            jot.get(globalRangeGlobalMinAtom)?.toFixed(PRECISION) ?? "";
          const { inputValue: value, setInputValue } = minStore.getState();
          if (value !== stringDisplayed) {
            setInputValue(stringDisplayed);
          }
        });

      return minStore.subscribe(
        (s) => s.inputValue,
        (inputValue) => {
          if (!inputValue.trim()) {
            setYClampMin();
            return;
          }

          const num = parseFloat(inputValue.trim());

          if (isNaN(num)) throw new Error("should not be possible??");

          setYClampMin(undefined, num);
        }
      );
    },
    [minStore, minIsAuto, jot, setYClampMin]
  );

  useEffect(
    function handleDataSync() {
      if (maxIsAuto)
        return jot.sub(globalRangeGlobalMaxAtom, () => {
          const stringDisplayed =
            jot.get(globalRangeGlobalMaxAtom)?.toFixed(PRECISION) ?? "";
          const { inputValue: value, setInputValue } = maxStore.getState();
          if (value !== stringDisplayed) {
            setInputValue(stringDisplayed);
          }
        });

      return maxStore.subscribe(
        (s) => s.inputValue,
        (inputValue) => {
          if (!inputValue.trim()) {
            setYClampMax();
            return;
          }

          const num = parseFloat(inputValue.trim());

          if (isNaN(num)) throw new Error("should not be possible??");

          setYClampMax(undefined, num);
        }
      );
    },
    [maxStore, maxIsAuto, jot, setYClampMax]
  );

  const minIsError = minAutoValue === "";
  const maxIsError = maxAutoValue === "";
  const someError = minIsError || maxIsError;

  return (
    <Tooltip>
      <TooltipContent>Y-axis clamps</TooltipContent>
      <Popover>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              variant={someError ? "destructive" : "ghost"}
              size={BUTTON_SIZE}
              onClick={(e) => e.stopPropagation()}
            >
              <FaCut className="size-3" />
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>
        <PopoverContent
          {...popoverContentProps}
          className="w-auto bg-white p-3 dark:bg-xslate-1"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex gap-7">
            <div className="inline-flex flex-col justify-around">
              <Label htmlFor="height">Y-MAX</Label>
              <Label htmlFor="height">Y-MIN</Label>
            </div>
            <div className="inline-flex flex-col gap-2">
              <div className="inline-flex gap-2.5">
                <ClampInputToggle
                  value={maxIsAuto ? maxAutoValue : maxValue}
                  setValue={maxStore.getState().setInputValue}
                  isAuto={maxIsAuto}
                  setIsAuto={(newAutoValue) => {
                    const { setAuto, setInputValue } = maxStore.getState();
                    setAuto(newAutoValue.auto);

                    if (newAutoValue.auto) {
                      setYClampMax();
                    } else {
                      if (maxIsAuto) {
                        // switch from auto to manual
                        if (newAutoValue.startingValue !== undefined) {
                          setYClampMax(
                            undefined,
                            newAutoValue.startingValue.parsed
                          );
                          setInputValue(newAutoValue.startingValue.asInputted);
                        } else {
                          const v = jot.get(globalRangeGlobalMaxAtom);
                          v !== undefined && setYClampMax(undefined, v);
                          setInputValue(v?.toFixed(PRECISION) ?? "");
                        }
                      }
                    }
                  }}
                  isError={maxAutoValue === ""}
                />
              </div>
              <div className="inline-flex gap-2.5">
                <ClampInputToggle
                  value={minIsAuto ? minAutoValue : minValue}
                  setValue={minStore.getState().setInputValue}
                  isAuto={minIsAuto}
                  setIsAuto={(newAutoValue) => {
                    const { setAuto, setInputValue } = minStore.getState();
                    setAuto(newAutoValue.auto);

                    if (newAutoValue.auto) {
                      setYClampMin();
                    } else {
                      if (minIsAuto) {
                        // switch from auto to manual
                        if (newAutoValue.startingValue !== undefined) {
                          setYClampMin(
                            undefined,
                            newAutoValue.startingValue.parsed
                          );
                          setInputValue(newAutoValue.startingValue.asInputted);
                        } else {
                          const v = jot.get(globalRangeGlobalMinAtom);
                          v !== undefined && setYClampMin(undefined, v);
                          setInputValue(v?.toFixed(PRECISION) ?? "");
                        }
                      }
                    }
                  }}
                  isError={minAutoValue === ""}
                />
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </Tooltip>
  );
}

const _GlobalAbsoluteClampsPopover: typeof _GlobalAbsoluteClampsPopoverInternal =
  (props) => {
    const jot = useStore();

    const [s] = useState(() => {
      const min = jot.get(globalRangeGlobalMinAtom);
      const max = jot.get(globalRangeGlobalMaxAtom);
      return [
        createUseClampStore({
          auto: true,
          inputValue: min?.toFixed(PRECISION) ?? "",
        }),
        createUseClampStore({
          auto: true,
          inputValue: max?.toFixed(PRECISION) ?? "",
        }),
      ] as const;
    });

    return (
      <UseMinMaxClampsStoresContext.Provider value={s}>
        <_GlobalAbsoluteClampsPopoverInternal {...props} />
      </UseMinMaxClampsStoresContext.Provider>
    );
  };

const shouldShowGlobalClampsAtom = atom((get) => {
  const single = get(Atoms.singleVariableViewAtom);
  const absolute = get(Atoms.yAxisModeAtom) === YAxisMode.Absolute;
  return single || absolute;
});

function GlobalClampsButton() {
  const shouldShowGlobalClamps = useAtomValue(shouldShowGlobalClampsAtom);
  if (!shouldShowGlobalClamps) return;

  return <_GlobalAbsoluteClampsPopover />;
}

export {
  Container,
  VariableSelectOverlayButton,
  StoreInspect,
  ResetButton,
  GlobalClampsButton,
  EyeButton,
  BUTTON_SIZE,
};
