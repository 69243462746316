import { useLocation, Navigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { routerPaths } from "../../constants/routerPaths";

const LOGIN_PATH = "/" + routerPaths.login; // must match the path in src/App.tsx
const REDIRECT_SEARCH_PARAM_KEY = "redirect";
const RedirectURLValidator = z.string().startsWith("/");

function NavigateToLogin() {
  const { pathname, search } = useLocation();

  /**
   * The below Navigate component will have no effect if SAML is enabel
   * because this useEffect will take them to the IDP url, if it exists.
   */

  function getRedirectUrl(): string {
    const rawRedirectUrl = `${pathname}${search}`.trim();
    const isTrivial = rawRedirectUrl === "/" || rawRedirectUrl === "";

    if (isTrivial) {
      return LOGIN_PATH;
    }

    const encoded = encodeURIComponent(rawRedirectUrl);

    return `${LOGIN_PATH}?${REDIRECT_SEARCH_PARAM_KEY}=${encoded}`;
  }

  const u = getRedirectUrl();

  return <Navigate to={u} replace />;
}

const useRedirectSearchParam = (): string | undefined => {
  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get(REDIRECT_SEARCH_PARAM_KEY);

  if (!redirectUrl) {
    return undefined;
  }

  const parsed = RedirectURLValidator.safeParse(redirectUrl);

  if (parsed.success) {
    return decodeURIComponent(parsed.data);
  }
  return undefined;
};

export { useRedirectSearchParam };
export default NavigateToLogin;
