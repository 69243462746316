import {
  type ComponentProps,
  memo,
  type PropsWithChildren,
  useState,
} from "react";
import { useTzAwareDateStateDomain } from "./chart-range/use-tz-aware-date-state-domain";
import {
  TrendChartQueryType,
  useAriaQuery,
  useTrendChartQuery,
} from "../../../hooks/tanstack-query";
import { createStore, Provider, useAtomValue } from "jotai";
import {
  Atoms,
  ChartVariant,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  GenericSecondaryVariableViewNeedsProvider,
  type AvailableProps,
  useDebouncedEffectiveRangeForTimeseriesQuery,
} from "./dra-and-aria-secondary-variable.view";
import { cn } from "../../../lib/utils";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";
import { useMemo } from "use-memo-one";
import { HeaderGutsARIA } from "./header";
import * as HeaderPrimitive from "../../../shared-ui/time-series-2/secondary-variable-view/header";
import { minutesToMilliseconds } from "date-fns";
import { TimeseriesForBv } from "../../../shared-ui/time-series-2/types";
import { chartFormat } from "../../../shared-ui/time-series-2/fetcher-utils";

/**
 * Use this if you want to provide your own store provider. See
 * the component below for an example of how to provide your
 * own store
 *
 * If you don't want to provide your own store provider, use the
 * component below.
 */
function AriaSecondaryVariableViewNeedsProvider({
  className,
  onClose,
  expandable,
  ...props
}: ComponentProps<typeof ARIAChartHeader> &
  Omit<
    AvailableProps,
    keyof Pick<
      AvailableProps,
      | "data"
      | "noDaBars"
      | "anomalyRectangles"
      | "anomalyPatchesThicknessScale"
      | "hackForDemo"
      | "hideCommentPills"
      | "header"
      | "myFitnessLimitsMapSorted"
    >
  >) {
  /**
   * ARIA variables will have bvar of format "clusterId" + "variable"
   * DRA variables will have bvar of format "0".repeat(24) + "variable"
   */
  const allBvs = useAtomValue(Atoms.allBvsAtom).sort();
  const draBvs = allBvs.filter((v) => v.slice(0, 24) === "0".repeat(24));
  const ariaBvs = allBvs.filter((v) => v.slice(0, 24) !== "0".repeat(24));
  const first = ariaBvs[0];
  const cluster = first?.slice(0, 24);

  /**
   * Get ARIA data
   */
  const variables = useMemo(() => {
    return [...new Set(ariaBvs.map((v) => v.slice(24)))];
  }, [ariaBvs]);
  const [startRequest, endRequest] =
    useDebouncedEffectiveRangeForTimeseriesQuery();
  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);
  const ariaQuery = useAriaQuery(
    variables,
    cluster || "",
    new Date(startRequest).toISOString(),
    new Date(endRequest).toISOString(),
    canRequestData && variables.length > 0 && cluster !== undefined
  );

  /**
   * Get DRA data
   */
  const [start, end] = useAtomValue(Atoms.getDomainAtom);
  const isZoomed = start !== startRequest || end !== endRequest;
  const draQuery = useTrendChartQuery(
    {
      variables: draBvs.map((v) => v.slice(24)),
      start: startRequest,
      end: endRequest,
      idk: isZoomed
        ? {
            type: TrendChartQueryType.Zoomed,
            originalEnd: end,
            originalStart: start,
          }
        : { type: TrendChartQueryType.Regular },
    },
    {
      enabled: canRequestData && draBvs.length > 0,
      staleTime: minutesToMilliseconds(3),
    }
  );
  const timeseriesDataAsChartFormat = useMemo(() => {
    const zeroed =
      draQuery.data?.map((d) => ({
        ...d,
        da: null,
      })) ?? [];
    const ts: TimeseriesForBv[] = chartFormat(zeroed, "0".repeat(24));

    return ts;
  }, [draQuery.data]);

  return (
    <GenericSecondaryVariableViewNeedsProvider
      myFitnessLimitsMapSorted={undefined} // not used in ARIA
      header={<ARIAChartHeader expandable={expandable} onClose={onClose} />}
      hackForDemo // remove later
      noDaBars
      data={[...(ariaQuery.data || []), ...timeseriesDataAsChartFormat]}
      anomalyRectangles
      hideCommentPills // disabled on ARIA for now
      className={cn("border-0", className)}
      // anomalyPatchesThicknessScale={2}
      {...props}
    />
  );
}

const ARIAChartHeader = memo<ComponentProps<typeof HeaderGutsARIA>>(
  function ARIAChartHeader(props) {
    return (
      <HeaderPrimitive.Container className="gap-0 @lg:gap-2">
        <HeaderGutsARIA {...props} />
      </HeaderPrimitive.Container>
    );
  }
);

function AriaSecondaryVariableViewProvider({
  initialVariables,
  clusterId,
  initialExpanded,
  children,
}: {
  initialExpanded: boolean;
  clusterId: string;
  initialVariables: [string, ...string[]];
} & PropsWithChildren) {
  const [start, end] = useTzAwareDateStateDomain(); // a derivate of datestate, will change when datestate does

  const [s] = useState(() => {
    const s = createStore();
    s.set(Atoms.setInitialStateAtom, {
      decideAnomBooleanForNewlyAddedVariableTrendLines: undefined,
      batchVariablesOrExpressions: assertMinLen1(
        initialVariables.map((v) => ({
          type: "variable" as const,
          bv: `${clusterId}${v}`,
        }))
      ),
      expanded: initialExpanded,
      variant: ChartVariant.Aria,
    });
    s.set(Atoms.setDomainAtom, [start, end]);

    /**
     * The default behavior is to show the sidebar open depending on the #
     * of batch variables provided above, and is baked into the store logic.
     *
     * This is a special case in ARIA: show the sidebar closed by default,
     * regardless of the # of batch variables provided.
     */
    s.set(s.get(Atoms.cardsSidebarOpenAtomAtom), false);

    return s;
  });

  return <Provider store={s}>{children}</Provider>;
}

export {
  AriaSecondaryVariableViewNeedsProvider,
  AriaSecondaryVariableViewProvider,
};
