import React, { useState, useEffect, useRef, ComponentProps } from "react";
import "./ExpressionReference.css";
import {
  type Option,
  replaceHtmlSpecialChars,
  getRangeHtml,
  findQuoteIndex,
  moveCaretPosition,
  getCaretPosition,
  getContentAroundCaret,
  findFunctionName,
  exprRef,
  getLastWordMatchingRegex,
  decodeHtml,
  replaceOptions,
} from "./utils";
import { cn } from "../frontend/cn";
import { FUNCTIONS, OPERATORS, SLOW_FUNCTIONS } from "./constants";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../frontend/tooltip";
import { Button } from "../frontend/button";
import { X } from "lucide-react";
import { INPUT_LOOKALIKE_CLASSNAMES } from "../frontend/input";
import { ScrollArea } from "../frontend/scroll-area";

/**
 * This component aims to make the expression building easier by
 * detecting when a user has typed a " (quotation), and subsequently opens a
 * menu to allow them to select/autofill from a set of options
 */
type Props = {
  inputRef: React.RefObject<HTMLDivElement>;
  options: Option[];
  defaultValue?: string;
  className?: string;
  placeholder?: string;
} & ComponentProps<"div">;
export default function ExpressionBuildingInput({
  inputRef,
  options,
  defaultValue,
  className,
  placeholder,
  clearable = true,
  includeSlowFunctions = false,
  noFunctionsOrOperators = false,
  ...rest
}: Props & {
  clearable?: boolean;
  includeSlowFunctions?: boolean;
  noFunctionsOrOperators?: boolean;
}) {
  /**
   * hooks
   */
  const [showSelectionMenu, setShow] = useState(false);
  const [query, setQuery] = useState("");

  const [fnQuery, setFnQuery] = useState("");
  const [fnHelp, setFnHelp] = useState("");
  const [caretPosition, setCaretPosition] = useState({ x: 0, y: 0 });
  const [highlightedExpression, setHighlightedExpression] = useState(0);
  const [editingExprRef, setEditingExprRef] = useState<HTMLElement | null>(
    null
  );
  const menuRef = useRef<HTMLDivElement>(null);

  /**
   * data
   */
  const filteredOptions =
    query !== ""
      ? options.filter((t) =>
          `${t.label} ${t.description || ""}`
            .trim()
            .toLowerCase()
            .includes(query.trim().toLowerCase())
        )
      : options;

  const filteredOperators = noFunctionsOrOperators
    ? []
    : Object.keys(OPERATORS).filter((op) =>
        `${op} ${OPERATORS[op as keyof typeof OPERATORS]}`
          .toLowerCase()
          .includes(fnQuery.toLowerCase())
      );
  const allFunctions = noFunctionsOrOperators
    ? {}
    : includeSlowFunctions
      ? { ...FUNCTIONS, ...SLOW_FUNCTIONS }
      : FUNCTIONS;
  const filteredFunctions =
    fnQuery !== ""
      ? [
          ...Object.keys(allFunctions)
            .filter((fn) =>
              `${fn} ${allFunctions[fn as keyof typeof allFunctions]?.description}`
                .toLowerCase()
                .includes(fnQuery.toLowerCase())
            )
            .sort((a, b) => a.localeCompare(b)),
          ...filteredOperators,
        ]
      : [];

  /**
   * callbacks
   */
  function handleCaretPosition() {
    if (inputRef.current!.innerHTML.length === 0) {
      // reset but include all functions
      setFnQuery(" ");
      setFnHelp("");
      setQuery("");
      return;
    }
    // get caret position to place tooltip
    setCaretPosition(getCaretPosition(inputRef.current!));
    // get contents before and after caret
    const { before, after } = getContentAroundCaret(inputRef.current!);
    // to determine if a tag is being typed
    setQuery(getLastWordMatchingRegex(before, /"[^"]*$/g).replaceAll('"', ""));
    // and if a function is fully typed out, show a tooltip with the function signature, example
    const selectedFn = findFunctionName(before, after);
    const info = allFunctions[selectedFn as keyof typeof allFunctions];
    if (selectedFn && info) {
      setFnHelp(`${info.signature || selectedFn}\nExample: ${info.example}`);
      setFnQuery("");
    } else if (query.length === 0) {
      setFnHelp("");
      // if a function is being typed, show a menu
      const fnOrTag = getLastWordMatchingRegex(before, /[a-zA-Z_]+$/g);
      if (!before.endsWith(`"${fnOrTag}`)) {
        setFnQuery(fnOrTag);
      }
    }
  }

  function handleKeyPress(evt: React.KeyboardEvent<HTMLDivElement>) {
    if (!evt || evt.key === "Shift") return;
    const isQuote = evt.key === '"';
    const isBackSpace = evt.key === "Backspace";
    const isDown = evt.key === "ArrowDown";
    const isUp = evt.key === "ArrowUp";
    const isEnter = evt.key === "Enter";
    const isEscape = evt.key === "Escape";

    if (isEscape) {
      setShow(false);
      return;
    }
    if (showSelectionMenu) {
      if (evt.key === "ArrowLeft" || evt.key === "ArrowRight") {
        evt.preventDefault();
      } else if (isEnter || isQuote) {
        // prevent a newline/quote from being added
        evt.preventDefault();
        const selectedOption = filteredOptions[highlightedExpression];
        if (selectedOption) {
          insert(selectedOption);
        }
      } else if (isBackSpace) {
        if (query === "") {
          setShow(false);
        }
      } else if (isDown) {
        evt.preventDefault();
        setHighlightedExpression(
          (highlightedExpression + 1) % filteredOptions.length
        );
      } else if (isUp) {
        evt.preventDefault();
        setHighlightedExpression(
          (highlightedExpression + filteredOptions.length - 1) %
            filteredOptions.length
        );
      }
      return;
    }
    if (isQuote) {
      setShow(true);
      setFnQuery("");
      return;
    }
    if (isBackSpace) {
      setShow(false);
      return;
    }
    const showFnMenu = fnQuery.length > 0;
    if (showFnMenu) {
      if (isEnter) {
        // prevent a newline from being added
        evt.preventDefault();
        setFnQuery("");
        const fn = filteredFunctions[highlightedExpression];
        if (fn) {
          Object.keys(OPERATORS).includes(fn)
            ? insertOperator(fn)
            : insertFn(fn);
        }
      } else if (isDown) {
        evt.preventDefault();
        const filteredFunctionsLength = filteredFunctions.length;
        setHighlightedExpression(
          (highlightedExpression + 1) % filteredFunctionsLength
        );
      } else if (isUp) {
        evt.preventDefault();
        const filteredFunctionsLength = filteredFunctions.length;
        setHighlightedExpression(
          (highlightedExpression + filteredFunctionsLength - 1) %
            filteredFunctionsLength
        );
      }
    }
  }

  function insert(option: Option) {
    const input = inputRef.current;
    if (!input) return;
    const cursorPosition = findQuoteIndex(input.innerHTML); // it's guaranteed there's only one quotation mark
    if (cursorPosition === -1) {
      // if there isn't it means the menu came up by clicking exprRef
      if (editingExprRef) {
        const tempContainer = document.createElement("div");
        tempContainer.innerHTML = exprRef(option);
        const newElement = tempContainer.firstChild as HTMLElement;
        editingExprRef.replaceWith(newElement);
        tempContainer.remove();
        setEditingExprRef(null);
        setShow(false);
        setQuery("");
      }
      return;
    }
    const newHtml1 = `${input.innerHTML.slice(0, cursorPosition)}${exprRef(
      option
    )}&nbsp;`;
    const newHtml2 = input.innerHTML.slice(
      cursorPosition + query.length + 1 // +1 to remove the quotation mark
    );
    input.innerHTML = newHtml1 + newHtml2;
    setShow(false);
    // set the cursor position to the end of the inserted text
    const newPosition = replaceHtmlSpecialChars(decodeHtml(newHtml1)).length;
    moveCaretPosition(input, newPosition);
    // trigger onInput manually so listeners get updated text
    const event = new Event("input", { bubbles: true, cancelable: true });
    inputRef.current.dispatchEvent(event);
  }

  function insertFn(fn: string) {
    const input = inputRef.current;
    if (!input) return;
    const cursorPosition = Math.max(0, input.innerHTML.lastIndexOf(fnQuery));
    const newHtml1 = `${input.innerHTML.slice(0, cursorPosition)}${fn}(`;
    const newHtml2 = `)${input.innerHTML.slice(
      cursorPosition + fnQuery.length
    )}`;
    input.innerHTML = newHtml1 + newHtml2;
    setFnQuery("");
    // set the cursor position in between the parentheses
    const newPosition = replaceHtmlSpecialChars(newHtml1).length;
    moveCaretPosition(input, newPosition);
    handleCaretPosition(); // make function hint show up immediately
    // trigger onInput manually so listeners get updated text
    const event = new Event("input", { bubbles: true, cancelable: true });
    inputRef.current.dispatchEvent(event);
  }

  function insertOperator(op: string) {
    const input = inputRef.current;
    if (!input) return;
    const cursorPosition = Math.max(0, input.innerHTML.lastIndexOf(fnQuery));
    const newHtml1 = `${input.innerHTML.slice(0, cursorPosition)}${op}`;
    const newHtml2 = `${input.innerHTML.slice(
      cursorPosition + fnQuery.length
    )}`;
    input.innerHTML = newHtml1 + newHtml2;
    setFnQuery("");
    const newPosition = replaceHtmlSpecialChars(newHtml1).length;
    moveCaretPosition(input, newPosition);
    // trigger onInput manually so listeners get updated text
    const event = new Event("input", { bubbles: true, cancelable: true });
    inputRef.current.dispatchEvent(event);
  }

  /**
   * effects
   */
  // handle translating defaultValue to HTML markup
  useEffect(() => {
    if (
      inputRef.current &&
      inputRef.current.innerHTML.length === 0 &&
      defaultValue !== undefined
    ) {
      inputRef.current.innerHTML = replaceOptions(defaultValue, options);
    }
  }, [defaultValue, JSON.stringify(options)]);
  // handle scrolling the menu to the highlighted item
  useEffect(() => {
    const menuContainer = menuRef.current?.children[1] as HTMLElement;
    if (!menuContainer) return;
    const highlightedItem = menuContainer.children[
      highlightedExpression
    ] as HTMLElement;
    if (!highlightedItem) return;

    const menuTop = menuContainer.scrollTop;
    const menuBottom = menuTop + menuContainer.offsetHeight;

    const itemTop = highlightedItem.offsetTop;
    const itemBottom = itemTop + highlightedItem.offsetHeight;

    const searchInputHeight = 48;
    if (itemTop < menuTop + searchInputHeight) {
      menuContainer.scrollTop = itemTop - searchInputHeight;
    } else if (itemBottom > menuBottom) {
      menuContainer.scrollTop = itemBottom - menuContainer.offsetHeight;
    }
  }, [highlightedExpression]);
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // we want the menu to disappear after the input loses focus
      // but if you click on the menu, don't want it to disappear (so can't simply use onBlur on the input)
      if (
        inputRef.current &&
        !inputRef.current.contains(target) &&
        menuRef.current &&
        !menuRef.current.contains(target)
      ) {
        setFnQuery("");
        setQuery("");
        setShow(false);
        setEditingExprRef((prev) => {
          if (prev) prev.classList.remove("selected");
          return null;
        });
      }
      // if you clicked on exprRef, bring up menu again
      else if (
        target.className.includes("exprRef") &&
        // and the clicked exprRef is inside the input
        inputRef.current?.contains(target)
      ) {
        setQuery(" "); // show all options
        setShow(true);
        setEditingExprRef((prev) => {
          if (prev) prev.classList.remove("selected");
          return event.target as HTMLElement;
        });
        target.classList.add("selected");
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [inputRef]);

  if (options.length === 0) return <p>Loading tags...</p>;

  const showFnMenu = fnQuery.length > 0 && filteredFunctions.length > 0; // simply based off whether there's a query/results
  const showTagMenu = showSelectionMenu && filteredOptions.length > 0; // because a quote is not part of the query, showSelectionMenu is used

  return (
    <div className="relative w-full">
      {fnHelp.length > 0 && (
        <div
          className="fixed h-12 w-fit rounded bg-green-600 p-1 text-white"
          style={{
            zIndex: 190,
            left: caretPosition.x - 15,
            top: caretPosition.y - 75, // subtract height of the div (plus some padding)
          }}
        >
          {fnHelp.split("\n").map((line, i) => (
            <div key={i} className={i === 1 ? "text-xs" : ""}>
              {line}
            </div>
          ))}
        </div>
      )}

      <div className="flex items-center justify-center">
        {/* this is the actual expression building input element */}
        {/* the innerHTML is parsed and becomes the expression string so don't modify markup */}
        <div
          className={cn(
            INPUT_LOOKALIKE_CLASSNAMES,
            /**
             * Without display block, I was unable
             * to remove a tag by hitting the
             * backspace. Probs important to point
             * out.
             *
             */
            "peer/expr z-50 block h-fit min-h-10",
            className
          )}
          ref={inputRef}
          contentEditable={true}
          spellCheck={false}
          // if a key is held down onKeyDown will fire repeatedly
          onKeyDown={handleKeyPress}
          // onKeyUp fired when key is released, so reflects the position after the input has been processed and added to the field
          onKeyUp={handleCaretPosition}
          onClick={handleCaretPosition}
          onFocus={() => {
            if (inputRef.current!.innerHTML === "") {
              // by default we want to show the menu (a space will include all functions)
              setFnQuery(" ");
            }
          }}
          onPaste={(evt) => {
            evt.preventDefault();
            const text = evt.clipboardData.getData("text/plain");
            // make what's in quotes an exprRef
            const regex = /"([^"]*)"/g;
            const replaceIds = text.replace(regex, (_, p1) => {
              const found = options.find((opt) => opt.label === p1);
              return found ? exprRef(found) : ""; // or should we return p1?
            });
            const { before: beforeString, after: _ } = getContentAroundCaret(
              inputRef.current!,
              false
            );
            const { before, after } = getContentAroundCaret(
              inputRef.current!,
              true
            );
            inputRef.current!.innerHTML = `${before}${replaceIds}${after}`;
            const newTextLength = decodeHtml(replaceIds)
              // as it would be displayed as text
              .replace(/<span[^>]*>(.*?)<\/span>/g, "$1").length;
            moveCaretPosition(
              inputRef.current!,
              beforeString.length + newTextLength
            );
            handleCaretPosition();
            // trigger onInput manually so listeners get updated text
            const event = new Event("input", {
              bubbles: true,
              cancelable: true,
            });
            inputRef.current!.dispatchEvent(event);
          }}
          onCopy={(evt) => {
            evt.preventDefault();
            if (editingExprRef) {
              editingExprRef.classList.remove("selected");
              setEditingExprRef(null);
              setShow(false);
            }
            const selection = window.getSelection();
            if (!selection) return;
            const range = selection.getRangeAt(0);
            const html = getRangeHtml(range);
            const regex =
              /<span class="exprRef" contenteditable="false" data-value="(.+?)">(.+?)<\/span>/g;
            const replaceIds = replaceHtmlSpecialChars(
              html.replace(regex, (_, p1, p2) => `"${p2}"`)
            );
            evt.clipboardData.setData("text/plain", replaceIds);
          }}
          {...rest}
          onBlur={(e) => {
            setFnHelp("");
            if (rest.onBlur) rest.onBlur(e);
          }}
        />
        <div
          className="peer-focus-within:expr:hidden pointer-events-none absolute left-3 text-sm text-xslate-11 peer-focus/expr:hidden peer-[&:not(:empty)]/expr:hidden"
          style={defaultValue ? { display: "none" } : {}}
        >
          {placeholder || "Type an expression with Tag IDs in double quotes"}
        </div>

        {clearable && (
          <TooltipProvider delayDuration={50}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={"ghost"}
                  size={"icon"}
                  onClick={() => {
                    setShow(false);
                    setFnHelp("");
                    setQuery("");
                    setFnQuery("");
                    inputRef.current!.innerHTML = "";
                    const event = new Event("input", {
                      bubbles: true,
                      cancelable: true,
                    });
                    inputRef.current!.dispatchEvent(event);
                  }}
                  className="absolute right-1 top-1/2 -translate-y-1/2"
                  type="button"
                >
                  <X className="h-3 w-3" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Clear</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div ref={menuRef} className="absolute w-full" style={{ zIndex: 190 }}>
        {(showTagMenu || showFnMenu) && (
          <ScrollArea className="h-[26rem] translate-y-2 rounded-md border border-xslate-7 bg-white empty:hidden dark:bg-xslate-1">
            <ul className={cn("w-full list-none p-1.5")}>
              {showTagMenu
                ? filteredOptions.map((t, i) => (
                    <ListElement
                      containerRef={menuRef}
                      highlighted={highlightedExpression === i}
                      key={t.value}
                      onClick={() => insert(t)}
                      onMouseEnter={() => setHighlightedExpression(i)}
                    >
                      {/* leading should have same value as the <li> height directly above to be able to vertically center the text  */}
                      <span className="break-all text-sm font-medium leading-none">
                        {t.label} | {t.description}
                      </span>
                    </ListElement>
                  ))
                : showFnMenu
                  ? filteredFunctions.map((fn, i) => (
                      <ListElement
                        containerRef={menuRef}
                        highlighted={highlightedExpression === i}
                        key={fn}
                        onClick={() =>
                          Object.keys(OPERATORS).includes(fn)
                            ? insertOperator(fn)
                            : insertFn(fn)
                        }
                        onMouseEnter={() => setHighlightedExpression(i)}
                      >
                        {/* leading should have same value as the <li> height directly above to be able to vertically center the text  */}
                        <span className="text-sm font-medium leading-none">
                          {fn}
                          {fn in OPERATORS ? (
                            <span className="ml-2 text-xs font-normal">
                              {OPERATORS[fn as keyof typeof OPERATORS]}
                            </span>
                          ) : (
                            <span className="ml-2 text-xs font-normal text-xslate-11">
                              {allFunctions[fn as keyof typeof allFunctions]
                                ?.description || ""}
                            </span>
                          )}
                        </span>
                      </ListElement>
                    ))
                  : null}
            </ul>
          </ScrollArea>
        )}
      </div>
    </div>
  );
}

function ListElement({
  className,
  highlighted,
  containerRef,
  ...rest
}: ComponentProps<"li"> & {
  highlighted: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const ref = useRef<HTMLLIElement>(null);

  // useEffect(() => {
  //   if (!highlighted) return;

  //   const container = containerRef.current?.getBoundingClientRect();
  //   const me = ref.current?.getBoundingClientRect();

  //   if (!container || !me) return;

  //   if (me.top < container.top || me.bottom > container.bottom) {
  //     // ref.current?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [highlighted, ref, containerRef]);

  return (
    <li
      ref={ref}
      data-highlighted={highlighted.toString()}
      className={cn(
        "min-h-8 mb-1 flex cursor-pointer items-center rounded-sm px-2 py-1 transition-all data-[highlighted=true]:bg-xindigo-3 data-[highlighted=true]:pl-4 data-[highlighted=true]:text-xindigo-11",
        className
      )}
      {...rest}
    />
  );
}
