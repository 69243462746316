import { useStore } from "zustand";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../../../shared-ui/frontend/drawer";
import { useVariablesMappedByIdQuery } from "../../../hooks/tanstack-query";
import { AxisRangeButtonGroupAndLabelToLeft } from "../../dateSelector/axis-range-button-group";
import { Button } from "../../../shared-ui/frontend/button";
import { X } from "lucide-react";
import {
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
  DRASecondaryVariableViewProvider,
} from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useToggleFullscreen } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import {
  useShutdownDrawerStore,
  useShutdownDrawerStoreNotRequired,
} from "./use-shutdown-drawer-store";

function Internal() {
  const store = useShutdownDrawerStore();
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const { shutdownId, setShutdownId } = useStore(store);

  const toggleFullscreen = useToggleFullscreen();

  const chartDims = chartDimensionsConfig({ isGridView: false });

  return (
    <Drawer
      dismissible={false}
      direction="right"
      open={!!shutdownId}
      onOpenChange={(open) => {
        if (!open) {
          setShutdownId(undefined);
        }
      }}
    >
      <DrawerContent className="w-[90dvw] h-[100dvh] right-0 left-auto overflow-scroll rounded-none p-0 bg-zinc-100 !select-text">
        <div className="overflow-auto p-6">
          <div className="flex items-center justify-between mt-1 flex-nowrap">
            <div className="flex flex-nowrap items-center">
              <span className="text-2xl font-semibold tracking-tight whitespace-nowrap">
                {shutdownId &&
                  variablesMapQuery.data &&
                  variablesMapQuery.data[shutdownId]?.name}
              </span>
            </div>
            <div className="min-w-max">
              <AxisRangeButtonGroupAndLabelToLeft />
              <DrawerClose asChild>
                <Button size="icon" variant={"ghost"}>
                  <X className="size-4" />
                </Button>
              </DrawerClose>
            </div>
          </div>

          <div className={"grid grid-cols-1 gap-4 py-4"}>
            <DRASecondaryVariableViewProvider
              initialBatchVariables={[
                {
                  type: "variable",
                  bv: "0".repeat(24) + shutdownId,
                },
              ]}
              initialExpanded={true}
            >
              <DRASecondaryVariableViewNeedsProvider
                noPortalMultiSelects
                onLineClick={
                  toggleFullscreen &&
                  (() =>
                    ({ jotaiStore: s }) => {
                      toggleFullscreen(s);
                    })
                }
                ableToGetTaller
                {...chartDims}
                padding={{
                  left: 45,
                }}
                expandable
                onlyMinMaxYAxes={false}
              />
            </DRASecondaryVariableViewProvider>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function ShutdownDrawer() {
  const s = useShutdownDrawerStoreNotRequired();

  if (!s) return null;

  return <Internal />;
}

export { ShutdownDrawer };
