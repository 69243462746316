import { create } from "zustand";
import * as React from "react";
import { useStore } from "jotai";
import { TrendLineVariant } from "../draw/draw";

type HoverData = TrendLineVariant & {
  percentageTop: number;
  percentageLeft: number;
  v: number;
  t: number;
  stageId: string;
  container: Element; // the div containing the canvas and svg
  shouldDisplayTagId: boolean;
  slopingTrendId: string | undefined;

  crossHair:
    | {
        v: number;
        percentageTop: number;
        percentageLeft: number;
      }
    | undefined;
};
type ClickedLineData = {
  bvOrId: string;
  jotaiStore: ReturnType<typeof useStore>;
  clientX: number;
  clientY: number;
};

type TimeseriesChartsTooltipStore = {
  hoverData: HoverData | undefined;
  setTooltipData: (data: HoverData | undefined) => void;
  removeHoverData: () => void;

  clickedLine: undefined | ClickedLineData;
  setClickedLineData: (data: ClickedLineData) => void;
  removeClickedLineData: () => void;
};

function createUseTimeseriesChartsTooltipStore() {
  return create<TimeseriesChartsTooltipStore>((set) => {
    return {
      hoverData: undefined,
      clickedLine: undefined,
      setClickedLineData: (clickedLine) => set({ clickedLine }),
      setTooltipData: (data) => set({ hoverData: data }),
      removeClickedLineData: () => set({ clickedLine: undefined }),
      removeHoverData: () => set({ hoverData: undefined }),
    };
  });
}

type UseTimeseriesChartsTooltipStore = ReturnType<
  typeof createUseTimeseriesChartsTooltipStore
>;

const TimeseriesChartsTooltipStoreContext = React.createContext<
  UseTimeseriesChartsTooltipStore | undefined
>(undefined);

function TimeseriesChartTooltipStoreProvider(props: {
  children: (useStore: UseTimeseriesChartsTooltipStore) => React.ReactNode;
}) {
  const [useStore] = React.useState(() =>
    createUseTimeseriesChartsTooltipStore()
  );
  return (
    <TimeseriesChartsTooltipStoreContext.Provider value={useStore}>
      {props.children(useStore)}
    </TimeseriesChartsTooltipStoreContext.Provider>
  );
}

function useGetUseTimeseriesChartsTooltipStore() {
  const store = React.useContext(TimeseriesChartsTooltipStoreContext);
  return store;
}

function useShowClickedLine(): (clickedLineData: ClickedLineData) => void {
  const store = useGetUseTimeseriesChartsTooltipStore();

  if (!store) return () => {};

  return (clickedLineData) => {
    store.getState().setClickedLineData(clickedLineData);
  };
}

export {
  useGetUseTimeseriesChartsTooltipStore,
  TimeseriesChartTooltipStoreProvider,
  useShowClickedLine,
  type ClickedLineData,
  type HoverData,
};
