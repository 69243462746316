import React from "react";
import _ from "lodash";
import {
  FaLayerGroup,
  FaFlag,
  FaAdjust,
  FaCheck,
  FaFile,
  FaChartBar,
  FaFileContract,
  FaUsers,
  FaClock,
  FaAddressCard,
  FaBell,
} from "react-icons/fa";
import { ImStatsDots } from "react-icons/im";
import { PiWarningCircleBold } from "react-icons/pi";
import { GrTree, GrCluster } from "react-icons/gr";
import useCurrentUnitObject from "../common/hooks/useCurrentUnitObject";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { BsBarChartSteps } from "react-icons/bs";

/**
 * TODO: change name to useAllowedSettingsLinks
 */
function useAllowedSettingsLinks() {
  const user = useAuthStore((s) => s.user);
  const currentUnit = useCurrentUnitObject();

  if (!currentUnit) {
    return [];
  }

  const addons = {
    specialtyReports:
      !!currentUnit.addons.specialtyReports || user.role === "Root",
    operatingLimits: !!currentUnit.addons.operatingLimits,
    faultTrees: !!currentUnit.addons.faultTrees,
    realtime: !!currentUnit.addons.realTime,
    slopingTrends: !!currentUnit.addons.slopingTrends,
    aria: !!currentUnit.addons.aria,
  };

  return _.filter(
    [
      { name: "General" },
      { name: "Tags", url: `/tag`, icon: ImStatsDots },
      { name: "Groups + Sections", url: `/group`, icon: FaLayerGroup },
      { name: "Shutdown Rules", url: `/sdr`, icon: FaFlag },
      { name: "Operating Modes", url: `/om`, icon: FaAdjust },
      { name: "Custom Clusters", url: `/cluster`, icon: GrCluster },
      { name: "Acknowledgment Summary", url: `/ack`, icon: FaCheck },
      { name: "Reports Library", url: `/rl`, icon: FaFile },
      {
        name: "Teams",
        url: `/teams`,
        icon: FaUsers,
      },

      {
        name: "Modules",
        disabled: !addons.faultTrees && !addons.operatingLimits, // if the 2 below are disabled, omit this label too
      },
      {
        name: "Fault Trees",
        url: `/ft`,
        disabled: !addons.faultTrees,
        icon: GrTree,
      },
      {
        name: "Operating Fitness",
        url: `/ofm`,
        disabled: !addons.operatingLimits,
        icon: FaChartBar,
      },
      {
        name: "My Fitness",
        url: `/my-limits`,
        icon: BsBarChartSteps,
        disabled: !addons.realtime,
      },
      {
        name: "ARIA + Dynamic Clusters",
        url: `/aria`,
        icon: GrCluster,
        disabled: !addons.aria,
      },
      // {
      //   name: "Change Group Display Order",
      //   url: `${v1Base}/control#change-group-display-order`,
      //   disabled: user.role !== "Root",
      // },

      {
        name: "Admin",
        disabled: user.role === "User",
      },
      {
        name: "Specialty Reports",
        url: `/sr`,
        disabled: !addons.specialtyReports || user.role === "User",
        icon: FaFileContract,
      },
      {
        name: "Users",
        url: `/users`,
        disabled: user.role === "User",
        icon: FaAddressCard,
      },
      {
        name: "Bad Tags",
        url: `/bt`,
        disabled: user.role === "User",
        icon: PiWarningCircleBold,
      },
      {
        name: "Root",
        disabled: user.role !== "Root",
      },
      {
        name: "Back-Processing",
        url: `/bp`,
        disabled: user.role !== "Root",
        icon: FaClock,
      },
      // {
      //   name: "Sensor Flatline",
      //   url: `${v1Base}/control#sensor-vars`,
      //   disabled: user.role !== "Root",
      //   icon: "chart-line",
      // },
    ],
    (link) => !link.disabled
  );
}

export default useAllowedSettingsLinks;
