import {
  createContext,
  type PropsWithChildren,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import { type PropsWithCnAndChildren, cn } from "../../frontend/cn";
import { useShouldRenderOrReact } from "../timeseries-chart";
import { Loader2 } from "lucide-react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Atoms } from "../svv-store/use-svv-store";

const RESIZE_DEBOUNCE_MS = 250;

function InViewDetectContainer({
  children,
  className,
  loading,
}: PropsWithCnAndChildren<{
  loading?: boolean;
}>) {
  const ref = useRef<HTMLDivElement>(null);
  const shouldRender2 = useShouldRenderOrReact();
  const [inView, setInView] = useAtom(Atoms.chartInViewportAtom);

  const shouldRender = shouldRender2 && inView;

  const setContainerWidth = useSetAtom(Atoms.containerDimensionsAtom);

  useLayoutEffect(() => {
    if (!shouldRender) return;

    const e = ref.current;
    if (!e) return;

    /**
     * Immediately set the value in case resize
     * observer doesn't fire immediately
     */
    const box = e.getBoundingClientRect();
    setContainerWidth({
      h: Math.round(box.height),
      w: Math.round(box.width),
    });

    let timer = undefined as ReturnType<typeof setTimeout> | undefined;

    const r = new ResizeObserver((entries) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        const first = entries[0];

        if (!first) throw new Error("no entries");

        const x = first.contentBoxSize[0];

        if (!x) throw new Error("no contentBoxSize");
        const width = x.inlineSize;
        const height = x.blockSize;

        setContainerWidth({ w: Math.round(width), h: Math.round(height) });
      }, RESIZE_DEBOUNCE_MS);
    });

    r.observe(e);

    return () => {
      r.disconnect();
      clearTimeout(timer);
    };
  }, [ref, shouldRender, setContainerWidth]);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const { top, bottom } = ref.current.getBoundingClientRect();

    const inView = bottom >= 0 && top <= window.innerHeight;
    setInView(inView);

    const ob = new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (!first) return;

        setInView(first.isIntersecting);
      },
      {
        threshold: 0.001,
      }
    );

    ob.observe(ref.current);

    return () => {
      ob.disconnect();
    };
  });

  return (
    <div ref={ref} className={cn("relative flex", className)} data-vaul-no-drag>
      {loading && (
        <div className="absolute inset-0 z-[19] rounded-lg hover:cursor-wait">
          <div className="grid h-full place-content-center">
            <Loader2 className="size-10 animate-spin-slow" />
          </div>
        </div>
      )}
      {children}
    </div>
  );
}

function ChartAreaContainer({ children }: PropsWithChildren) {
  const ref = useRef<HTMLDivElement>(null);
  const shouldRender2 = useShouldRenderOrReact();

  const inView = useAtomValue(Atoms.chartInViewportAtom);

  const shouldRender = shouldRender2 && inView;

  const setDimensions = useSetAtom(Atoms.chartAreaDimensionsAtom);

  useLayoutEffect(() => {
    if (!shouldRender) return;

    const e = ref.current;
    if (!e) return;

    /**
     * Immediately set the height
     */
    setDimensions({
      h: Math.round(e.clientHeight),
      w: Math.round(e.clientWidth),
    });

    let timer = undefined as ReturnType<typeof setTimeout> | undefined;

    const r = new ResizeObserver((entries) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        const first = entries[0];

        if (!first) throw new Error("no entries");

        const x = first.contentBoxSize[0];

        if (!x) throw new Error("no contentBoxSize");

        setDimensions({
          h: Math.round(x.blockSize),
          w: Math.round(x.inlineSize),
        });
      }, RESIZE_DEBOUNCE_MS);
    });

    r.observe(e);

    return () => {
      r.disconnect();
      clearTimeout(timer);
    };
  }, [shouldRender, setDimensions]);

  return (
    <div className={"inline-flex grow flex-col self-start"} ref={ref}>
      {children}
    </div>
  );
}

const IsRenderedAsFullscreenContext = createContext<boolean>(false);

function useIsRenderedAsFullscreen() {
  return useContext(IsRenderedAsFullscreenContext);
}

function FullscreenChartContainer({ children }: PropsWithChildren) {
  return (
    <IsRenderedAsFullscreenContext.Provider value={true}>
      {children}
    </IsRenderedAsFullscreenContext.Provider>
  );
}

export {
  InViewDetectContainer,
  ChartAreaContainer,
  FullscreenChartContainer,
  useIsRenderedAsFullscreen,
};
