import React, { useState } from "react";
import Form from "../../common/Form";
import InputOld from "../../common/InputOld";
import First from "../../common/First";
import Fragment from "../../common/Fragment";
import MainLoader from "../../common/MainLoader";
import Error from "../../common/PageError";
import formatErrors from "../../../lib/formatErrors";
import { TimePicker } from "../../../shared-ui/frontend/calendar2/time-picker2";
import _ from "lodash";
import * as moment from "moment";
import api from "../../../lib/api";
import flags from "../../../lib/flags";
import PillRow from "../../common/manager/PillRow";
import UpdatedAt from "../../common/manager/UpdatedAt";
import "../../page-templates/manager/Manager.scss";
import "./SpecialtyReportsManager.scss";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import AddonRoute from "../../common/AddonRoute";
import UserPermissionRoute from "../../login/UserPermissionRoute";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { Badge } from "../../ui/badge";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { BadgesForSpecialtyReportCard } from "./sr-card-badges";

const OF_REPORT_LEVEL_OPTIONS = [
  { value: "All", label: "All Levels" },
  { value: "Level1", label: "Level 1" },
  { value: "Level2", label: "Level 2" },
  { value: "Level3", label: "Level 3" },
  { value: "Level4", label: "Level 4" },
  { value: "Level5", label: "Level 5" },
];

const ENTITY_STATES = flags(["editing", "saving", "save_completed"]);

/* Duration (in milliseconds) to display save success messages. */
const SUCCESS_DURATION = 6000;

/* Unique identifier for new entities. */
const NEW_ID = "__newreport";

const DEFAULT_SHIFT_TIME = "06:00:00";
const DEFAULT_DELIVERY_TIME = "06:00:00";

const PAGE_NAME = "Specialty Reports";

class SpecialtyReportManager extends React.Component {
  constructor() {
    super();

    /* Used to make sure that IDs are unique per component.
     * This is a safety feature -- we should never have the same component
     * used in multiple places*/
    this.prefix = _.uniqueId("SpecialtyReportManager");

    this.state = {
      error:
        null /* Page level error (if something has gone so wrong that the full page can't load) */,
      reports:
        null /* All entity data is stored here in a simple key-value object literal. */,
      timeouts:
        {} /* Used for purely visual purposes (save confirmation, see method binding below) */,

      users: null,
      teams: null,
      groups: null,
      summaryType: null,
      faultTrees: null,
    };

    /* Server method event binding.
     *
     * This code makes sure that:
     * - if anything goes wrong during a request (serverside or clientside), an error gets displayed.
     * - entities go into a loading state during requests.
     * - entities come out of a loading state after requests.
     * - entity save-successful messages time out (based on SUCCESS_DURATION above).
     *
     * You may want to leave some request methods out of this if they have
     * particularly complicated logic. For example, adding the GET request
     * doesn't make any sense, since we don't want this behavior on page load.
     * But in general, most post-load actions can be added here without any
     * problems.
     */
    [
      "deleteReport",
      "patchReport",
      "createReport",
      "resendReport",
      "regenerateReport",

      /* add additional methods here */
    ].forEach((method) => {
      const original = this[method];

      this[method] = async function (id, payload) {
        /* Handle methods like `createReport` where data is passed
         * in instead of ID. Arguably, we shouldn't have this kind of magic
         * behavior, but that can be someone else's debate for now. */
        id = typeof id === "string" ? id : NEW_ID;

        this.setEntityState(id, (report) => {
          return {
            state: flags.set(report.state, ENTITY_STATES.saving, true),
            errors: [],
          };
        });

        let result = null;

        try {
          result = await original.apply(this, arguments);
          addSuccessToast("Success");

          id = result
            ? result._id
            : id; /* ID replacement when saving new report. */

          await this.setAsyncState((state) => {
            let report = state.reports[id];
            if (!report) {
              return {};
            } /* handle deletion */

            report.state = flags.set(
              report.state,
              ENTITY_STATES.save_completed,
              true
            );

            /* Avoid old timeouts clearing more recent success messages. */
            if (state.timeouts[id]) {
              clearTimeout(state.timeouts[id]);
            }

            state.timeouts[id] = setTimeout(() => {
              this.setEntityState(id, (report) => {
                return {
                  state: flags.set(
                    report.state,
                    ENTITY_STATES.save_completed,
                    false
                  ),
                };
              });
            }, SUCCESS_DURATION);

            return {
              timeouts: state.timeouts,
              reports: state.reports,
            };
          });
        } catch (err) {
          addUnknownErrorToast(err);

          /*@TODO: theoretically, re-editing should remove errors immediately (?) */

          this.setEntityState(id, () => ({ errors: formatErrors(err) }));

          await this.setEntityState(id, (report) => {
            return {
              state: flags.set(report.state, ENTITY_STATES.saving, false),
            };
          });

          throw err;
        }

        await this.setEntityState(id, (report) => {
          return {
            state: flags.set(report.state, ENTITY_STATES.saving, false),
          };
        });

        return result;
      };
    });
  }

  setAsyncState(method) {
    if (!_.isFunction(method)) {
      throw new Error("`setAsyncState` requires a function");
    }

    return new Promise((resolve) =>
      this.setState.call(this, method, () => resolve())
    );
  }

  setEntityState(id, method, updateReport = false) {
    if (!_.isFunction(method)) {
      throw new Error("`setEntityState` requires a function");
    }

    return this.setAsyncState((state) => {
      let report = state.reports[id];
      if (!report) {
        return {};
      }

      let edits = method(report);
      _.each(edits, (value, key) => {
        /* TODO: handle array transformations here? */
        report[key] = value;
      });

      if (this.isFaultTreeReport(report) && updateReport) {
        updateReport(id, "ft_summary", report.data.ft_summary);
        updateReport(id, "any_node_active", report.data.any_node_active);
        updateReport(id, "send_empty", report.data.send_empty);
      }

      return { reports: state.reports };
    });
  }

  componentDidMount() {
    Promise.all([
      this.getGroupsAndUsersAndTeamsAndFaultTree(),
      this.getReports(),
    ])
      .then(([groupsAndUsersAndFaultTrees, reports]) => {
        this.setAsyncState((state) => {
          return {
            groups: groupsAndUsersAndFaultTrees.groups,
            users: groupsAndUsersAndFaultTrees.users,
            teams: groupsAndUsersAndFaultTrees.teams,
            faultTrees: groupsAndUsersAndFaultTrees.faultTrees,
            reports,
          };
        });
      })
      .catch((err) => {
        console.log(err);

        this.setAsyncState((state) => ({ error: "Error loading page." }));
      });
  }

  async getGroupsAndUsersAndTeamsAndFaultTree() {
    const groups = await api.getGroups();
    const users = await api.getUsers();
    const teams = await api.getTeams();
    const faultTrees = await api.getFaultTrees();

    return {
      groups: _.reduce(
        groups,
        (result, group) => {
          result[group._id] = group;
          return result;
        },
        {}
      ),

      users: _.reduce(
        users,
        (result, user) => {
          result[user._id] = user;
          return result;
        },
        {}
      ),

      teams: _.reduce(
        teams,
        (result, team) => {
          result[team._id] = team;
          return result;
        },
        {}
      ),

      faultTrees: _.reduce(
        faultTrees.filter((faultTree) => faultTree.published),
        (result, faultTree) => {
          result[faultTree._id] = faultTree;
          return result;
        },
        {}
      ),
    };
  }

  async getReports() {
    const reports = await api.getSpecialtyReports();

    return _.reduce(
      reports,
      (result, report) => {
        result[report._id] = {
          state: 0,
          errors: [],
          edits: {},
          data: report,
        };

        return result;
      },
      {}
    );
  }

  async deleteReport(id) {
    await api.deleteSpecialtyReport(id);

    return this.setAsyncState((state) => {
      state.reports[id] = undefined;
      return { reports: state.reports };
    });
  }

  async patchReport(id, data) {
    if (data.application === "FT") {
      data["ft_summary"] =
        data["ft_summary"] === "Yes" || data["ft_summary"] === true
          ? true
          : false;
      data["any_node_active"] =
        data["any_node_active"] === "Yes" || data["any_node_active"] === true
          ? true
          : false;
      data["send_empty"] =
        data["send_empty"] === "Yes" || data["send_empty"] === true
          ? true
          : false;

      data.faultTrees =
        data.faultTrees === undefined || data.faultTrees.includes("All")
          ? []
          : data.faultTrees;
    } else {
      data.faultTrees = [];
    }
    var report = await api.patchSpecialtyReport(id, data);

    await this.setAsyncState((state) => {
      state.reports[id].data =
        report ||
        Object.assign(state.reports[id].data, state.reports[id].edits);

      state.reports[id].edits = {};

      return { reports: state.reports };
    });

    return report;
  }

  async createReport(data, isFT) {
    if (isFT) {
      data["ft_summary"] =
        data["ft_summary"] === "No" || data["ft_summary"] === true
          ? false
          : true;
      data["any_node_active"] =
        data["any_node_active"] === "No" || data["any_node_active"] === false
          ? false
          : true;
      data["send_empty"] =
        data["send_empty"] === "No" ||
        data["send_empty"] === false ||
        data["send_empty"] === undefined
          ? false
          : true;

      data.faultTrees =
        data.faultTrees === undefined || data.faultTrees.includes("All")
          ? []
          : data.faultTrees;
    } else {
      data.faultTrees = [];
    }

    var report = await api.postSpecialtyReport(data);

    await this.setAsyncState((state) => {
      state.reports[report._id] = {
        state: 0,
        errors: [],
        edits: {},
        data: report,
      };

      state.reports[NEW_ID] = null;
      return { reports: state.reports };
    });

    return report;
  }

  async resendReport(id, users) {
    await api.resendSpecialtyReport(id, users);
  }

  async regenerateReport(id) {
    await api.regenerateSpecialtyReport(id);
  }

  applicationTypeToDisplay(type) {
    switch (type) {
      case "DRA":
        return "DRA Report";
      case "FT":
        return "Fault Tree Report";
      case "ST":
        return "Sloping Trend Report";
      case "OF":
        return "Operating Fitness Report";
      case "VARIABILITY":
        return "Variability Report";
      case "ARIA":
        return "ARIA Report";
      default:
        return null;
    }
  }

  deliveryDaysToDisplay(deliveryDays) {
    switch (deliveryDays) {
      case "All":
        return "Every Day";
      case "MondayFriday":
        return "Monday - Friday";
      case "SaturdaySunday":
        return "Saturday - Sunday";
      case "SundayThursday":
        return "Sunday - Thursday";
      case "FridaySaturday":
        return "Friday - Saturday";
    }
  }

  reportTypeUsesTrendLength(report) {
    return report.data.application === "ST";
  }

  reportTypeUsesMoreOptions(report) {
    return (
      report.data.application === "DRA" ||
      report.data.application === "OF" ||
      report.data.application === "FT" ||
      report.data.application === "ARIA"
    );
  }

  reportTypeUsesDRIValues(report) {
    return report.data.application === "DRA";
  }

  reportTypeUsesAnomalies(report) {
    return report.data.application === "DRA";
  }

  isDRAReport(report) {
    return report.data.application === "DRA";
  }

  isOFReport(report) {
    return report.data.application === "OF";
  }

  isVariabilityReport(report) {
    return report.data.application === "VARIABILITY";
  }

  isFaultTreeReport(report) {
    return report.data.application === "FT";
  }

  isAriaReport(report) {
    return report.data.application === "ARIA";
  }

  getOrderList(report) {
    switch (report.data.application) {
      case "DRA":
        return [
          { value: "Group", label: "Groups (Default)" },
          { value: "Anomaly", label: "Anomalies" },
        ];
      case "ST":
        return [
          { value: "Trend", label: "Trends (Default)" },
          { value: "Group", label: "Groups" },
        ];
      case "OF":
        return [
          { value: "Level", label: "Levels (Default)" },
          { value: "Group", label: "Groups" },
        ];
      case "VARIABILITY":
        return [
          { value: "Variability", label: "Change In Variability (Default)" },
          { value: "Group", label: "Groups" },
        ];
    }
  }

  getRangeList(report) {
    switch (report.data.application) {
      case "DRA":
      case "OF":
      case "FT":
      case "ARIA":
        return [
          {
            value: "SevenDays",
            label: "7 Days",
            isDisabled:
              report.extendedData.formattedFrequency === "Monthly" ||
              report.extendedData.formattedFrequency === "BiWeekly",
          },

          {
            value: "ThirtyDays",
            label: "30 Days",
            isDisabled: report.extendedData.formattedFrequency === "Monthly",
          },
          {
            value: "Monthly",
            label: "Monthly",
            isDisabled: report.extendedData.formattedFrequency !== "Monthly",
          },
          { value: "SixtyDays", label: "60 Days" },
          { value: "OneTwentyDays", label: "120 Days" },
          { value: "OneEightyDays", label: "180 Days" },
        ];
      case "ST":
        return [
          { value: "OneEightyDays", label: "180 Days" },
          { value: "Yearly", label: "1 Year" },
        ];
      case "VARIABILITY":
        return [
          { value: "EightWeeks", label: "8 Weeks" },
          { value: "EightDays", label: "8 Days" },
          { value: "EightMonths", label: "8 Months" },
        ];
      default:
        return [];
    }
  }

  getFrequencyList(report) {
    switch (report.data.summaryType) {
      case "Daily":
        return [
          { value: "Daily", label: "Every Morning" },
          { value: "Weekday", label: "Monday-Friday Morning" },
          { value: "Weekend", label: "Saturday-Sunday Morning" },
        ];
      case "Weekly":
        return [
          { value: "Monday", label: "Every Monday Morning" },
          { value: "Sunday", label: "Every Sunday Morning" },
        ];
      case "BiWeekday":
        return [
          { value: "BiWeekday", label: "Every other Monday Morning" },
          { value: "BiWeekend", label: "Every other Sunday Morning" },
        ];
      case "Monthly":
        return [{ value: "Monthly", label: "Every 1st Day of Month Morning" }];
      case "Shift":
        return [{ value: "Shift", label: "Fixed Time" }];
      default:
        return [
          { value: "Error", label: "You must select a summary type first" },
        ];
    }
  }

  unitHasRealtime = () => {
    return this.props.currentUnit.addons.realTime;
  };

  getReportTypes = () => {
    const DEFAULT_APPLICATIONS = [
      { value: "DRA", label: "DRA Report", order: 0 },
      { value: "ST", label: "Sloping Trend Report", order: 1 },
      { value: "FT", label: "Fault Tree Report", order: 2 },
      // { value: "ARIA", label: "ARIA Report", order: 3 },
      // {value: 'VARIABILITY', label: 'Variability Report', order: 3},
    ];
    const types = DEFAULT_APPLICATIONS;

    const currentUnit = this.props.currentUnit;
    if (currentUnit.addons.operatingLimits)
      types.push({ value: "OF", label: "Operating Fitness Report", order: 2 });

    return types.sort((a, b) => a.order - b.order);
  };

  getSummaryTypes(report) {
    const DEFAULT_SUMMARY_TYPES = [
      { value: "Daily", label: "Daily Summary Report" },
      { value: "Weekly", label: "Weekly Summary Report" },
      { value: "BiWeekday", label: "Biweekly Summary Report" },
      { value: "Monthly", label: "Monthly Summary Report" },
    ];

    const types = DEFAULT_SUMMARY_TYPES;
    const currentUnit = this.props.currentUnit;
    const isOperatingFitnessApplication = report.data.application === "OF";
    if (currentUnit.addons.realTime && !isOperatingFitnessApplication)
      types.push({ value: "Shift", label: "Shift Summary Report" });

    return types;
  }

  render() {
    const that = this;

    /* In the case we legitimately have zero reports,
     * `that.state.reports` will still be a (truthy) blank array */
    const loaded = !!that.state.reports;
    const error = !!that.state.error;

    /* Frequency values are (on multiple occasions) divided into "bins" for logical operations */
    function frequencyBin(frequency) {
      switch (frequency) {
        case "Daily":
        case "Weekday":
        case "Weekend":
          return "Daily";

        case "Monday":
        case "Sunday":
          return "Weekly";

        case "Monthly":
          return "Monthly";

        case "BiWeekend":
        case "BiWeekday":
          return "BiWeekly";

        case "Shift":
          return "Shift";

        default:
          return frequency;
      }
    }

    const reports = _.reduce(
      that.state.reports,
      (result, report, id) => {
        if (!report) {
          return result;
        } /* Skip deleted entities */

        const displayReport = {
          editable: true,
          _id: id,
          state: report.state,
          errors: report.errors,
          data: _.extend({}, report.data, report.edits),
        };

        /* Add any other display properties or transforms you need to do. */
        displayReport.extendedData = {
          updated_at: moment(displayReport.data.updated_at)
            .local()
            .format("LLL"),

          formattedUserNumberString: (function (data) {
            if (!(data.teams || []).length) {
              return `Subscribed Users: ${data.users.length}`;
            }

            if (!data.users.length) {
              return `Subscribed Teams: ${data.teams.length}`;
            }

            return `Subscribers: ${data.users.length} users, ${data.teams.length} teams`;
          })(displayReport.data),

          formattedDelivery: (function (data) {
            switch (data.frequency) {
              case "Daily":
                return "Every Morning";
              case "Weekday":
                return "Monday-Friday";
              case "Weekend":
                return "Saturday-Sunday";

              case "BiWeekday":
                return "Every Alternate Monday Morning";
              case "BiWeekend":
                return "Every Alternate Sunday Morning";

              case "Monday":
                return "Monday Morning";
              case "Sunday":
                return "Sunday Morning";

              case "Monthly":
                return "Every 1st of Month";

              case "Shift":
                const formattedCustomDeliveryTime = moment(
                  data.customDeliveryTime,
                  "hh:mm:ss"
                ).format("h:mma");
                const formattedCustomDeliveryDays = that.deliveryDaysToDisplay(
                  data.customDeliveryDays
                );
                return `${formattedCustomDeliveryDays}, ${formattedCustomDeliveryTime}`;

              default:
                return data.frequency;
            }
          })(displayReport.data),

          formattedRange: (function (range) {
            switch (range) {
              case "SevenDays":
                return "7D";
              case "ThirtyDays":
                return "30D";
              case "SixtyDays":
                return "60D";
              case "OneTwentyDays":
                return "120D";
              case "OneEightyDays":
                return "180D";
              case "Monthly":
                return "1 Month";
              case "Yearly":
                return "1 Year";
              case "EightWeeks":
                return "8 Weeks";
              case "EightDays":
                return "8 Days";
              case "EightMonths":
                return "8 Months";
              default:
                return range;
            }
          })(displayReport.data.range),

          formattedChangeInVariability: (function (changeInVariability) {
            switch (changeInVariability) {
              case "All":
                return "All";
              case "Major":
                return "Major";
              case "Moderate":
                return "Moderate";
              case "Minor":
                return "Minor";
              default:
                return changeInVariability;
            }
          })(displayReport.data.changeInVariability),

          formattedTrendLength: (function (trendLength) {
            switch (trendLength) {
              case "All":
                return "Long-term, Medium-term, Short-term";
              case "ShortTerm":
                return "Short-term";
              case "MediumTerm":
                return "Medium-term";
              case "LongTerm":
                return "Long-term";
              default:
                return trendLength;
            }
          })(displayReport.data.trendLength),

          formattedFrequency: frequencyBin(displayReport.data.frequency),

          formattedShiftTimes: (function (data) {
            if (data.summaryType !== "Shift") return "";
            const formattedShiftStartTime = moment(
              data.shiftStartTime,
              "hh:mm:ss"
            ).format("h:mma");
            const formattedShiftEndTime = moment(
              data.shiftEndTime,
              "hh:mm:ss"
            ).format("h:mma");
            return `, ${formattedShiftStartTime} - ${formattedShiftEndTime}`;
          })(displayReport.data),

          formattedWatchlist: (function (watchlist) {
            switch (watchlist) {
              case "All":
                return "All";
              case "Select":
                return "Select";
              default:
                return "None";
            }
          })(displayReport.data.watchlist),

          formattedDri: (function (dri) {
            switch (dri) {
              case "All":
                return "All";
              case "Select":
                return "Select";
              default:
                return "None";
            }
          })(displayReport.data.dri),

          formattedAnomalies: (function (anomalies) {
            switch (anomalies) {
              case "All":
                return "3° and 2°";
              case "Third":
                return "3°";
              case "Second":
                return "2°";
              default:
                return anomalies;
            }
          })(displayReport.data.anomalies),

          formattedOrder: (function (order) {
            switch (order) {
              case "Group":
                return "Groups";
              case "Anomaly":
                return "Anomalies";
              case "Trend":
                return "Trends";
              case "Level":
                return "Levels";
              case "Variability":
                return "Change In Variability";
              default:
                return order;
            }
          })(displayReport.data.order),

          formattedFreshAnomalies: (function (freshAnomalies) {
            switch (freshAnomalies) {
              case "All":
                return "All Anomalies";
              case "Fresh":
                return "Fresh Anomalies Only";
              case "Ordered":
                return "Fresh First, Followed by Other";
              default:
                return freshAnomalies;
            }
          })(displayReport.data.freshAnomalies),

          formattedGroups: (function (groups) {
            return _.reduce(
              groups,
              (arr, groupId) => {
                const group =
                  that.state.groups[groupId] || that.state.faultTrees[groupId];

                if (group) {
                  arr.push({ value: group._id, label: group.name });
                }
                return arr;
              },
              []
            );
          })(displayReport.data.groups),

          formattedUsers: (function (users) {
            return _.map(users, (user_id) => {
              const user = that.state.users[user_id];
              if (!user) {
                return {
                  value: user_id,
                  label: `<USER MISSING - PLEASE REMOVE>`,
                };
              }

              return { value: user._id, label: `${user.first} ${user.last}` };
            });
          })(displayReport.data.users),

          formattedTeams: (function (teams) {
            return _.map(teams, (team_id) => {
              const team = that.state.teams[team_id];
              if (!team) {
                return {
                  value: team_id,
                  label: `<TEAM MISSING - PLEASE REMOVE>`,
                };
              }

              return { value: team._id, label: `${team.name}` };
            });
          })(displayReport.data.teams),

          formattedFitnessLevels: (function (levels) {
            if (_.first(levels) === "All") return "All";
            const formattedLevels = _.map(levels, (level) => {
              switch (level) {
                case "Level1":
                  return "Level 1";
                case "Level2":
                  return "Level 2";
                case "Level3":
                  return "Level 3";
                case "Level4":
                  return "Level 4";
                case "Level5":
                  return "Level 5";
              }
            });
            return formattedLevels.join(", ");
          })(displayReport.data.fitnessLevels),

          formattedFitnessAnalysisType: (function (fitnessAnalysisType) {
            switch (fitnessAnalysisType) {
              case "Exceedances":
                return "Exceedances";
              case "PredProb75":
                return "Pred. Prob. > 75% (7d)";
              case "PredProb50":
                return "Pred. Prob. > 50% (7d)";
              case "PredProbWithIncrease":
                return "Significant Increase in Pred. Prob. (7d)";
              case "PredProb75NoExceedances":
                return "Pred. Prob. > 75% (7d), No Exceedances";
              case "PredProb50NoExceedances":
                return "Pred. Prob. > 50% (7d), No Exceedances ";
              case "PredProbWithIncreaseNoExceedance":
                return "Significant Increase in Pred. Prob. (7d), No Exceedances";
              default:
                return fitnessAnalysisType;
            }
          })(displayReport.data.fitnessAnalysisType),

          formattedFitnessSendWithNoVariables: (function (
            fitnessSendWithNoVariables
          ) {
            return fitnessSendWithNoVariables ? "Yes" : "No";
          })(displayReport.data.fitnessSendWithNoVariables),

          formattedFitnessOnlyShowSelectedLevels: (function (
            fitnessOnlyShowSelectedLevels
          ) {
            return fitnessOnlyShowSelectedLevels
              ? "Select (Shown in Table)"
              : "All";
          })(displayReport.data.fitnessOnlyShowSelectedLevels),

          formattedExceedanceSummary: (function (exceedanceSummary) {
            switch (exceedanceSummary) {
              case "All":
                return "All";
              case "Select":
                return "Select";
              default:
                return "None";
            }
          })(displayReport.data.exceedanceSummary),

          formattedDriftCategory: (function (driftCategory) {
            switch (driftCategory) {
              case "All":
                return "Major and Minor Drift Clusters";
              case "Major":
                return "Major Drift Clusters";
              case "Minor":
                return "Minor Drift Clusters";
              default:
                return driftCategory;
            }
          })(displayReport.data.driftCategory),

          formattedClusterType: (function (clusterType) {
            switch (clusterType) {
              case "All":
                return "All";
              case "Static":
                return "Static";
              case "Dynamic":
                return "Dynamic";
              default:
                return clusterType;
            }
          })(displayReport.data.clusterType),
        };

        /* Extend validation as necessary - controls whether or not you can save the edit. */
        displayReport.editable =
          !flags.set(report, ENTITY_STATES.saving) &&
          (!report.data.locked || this.props.user.role === "Root");

        result[id] = displayReport;
        return result;
      },
      {}
    );

    /* Add custom binding logic for setting 'interesting' properties here.
     * Mostly this should just be used for arrays. An example has been
     * provided. */
    const updateReport = function (id, prop, value) {
      that.setEntityState(id, (report) => {
        if (_.isArray(value)) {
          value = value.map((item) => item.value);
        }

        if (value && value.value) {
          /* handle multiselector objects */
          value = value.value;
        }

        /* Restrictions about what range can be used with what report frequency mean we
         * need to occasionally set default values */
        let prevValue =
          report.edits[prop] != null ? report.edits[prop] : report.data[prop];

        const application = report.edits.application || report.data.application;

        switch (application) {
          case "DRA":
            // DRA specific auto edits.
            if (
              prop === "frequency" &&
              frequencyBin(prevValue) !== frequencyBin(value)
            ) {
              let bin = frequencyBin(value);
              let range = report.edits.range || report.data.range;

              /* TODO: if the currently chosen option is allowed, we shouldn't change it. */
              if (bin === "BiWeekly") {
                report.edits.range = "ThirtyDays";
              }
              if (bin === "Monthly") {
                report.edits.range = "Monthly";
              }
              if (
                bin !== "Monthly" &&
                bin !== "BiWeekly" &&
                range === "Monthly"
              ) {
                report.edits.range = "SevenDays";
              }
            }

            if (prop === "summaryType" && prevValue !== value) {
              report.edits.customDeliveryDays = "All";
              report.edits.customDeliveryTime = DEFAULT_DELIVERY_TIME;
              report.edits.shiftStartDay = "Previous";
              report.edits.shiftEndDay = "Current";
              report.edits.shiftStartTime = DEFAULT_SHIFT_TIME;
              report.edits.shiftEndTime = DEFAULT_SHIFT_TIME;
            }

            break;
          case "ST":
            // Sloping trend specific auto edits.
            break;
          case "FT":
            // Fault tree specific auto edits
            report.edits.application = "FT";
            break;
          case "ARIA":
            // ARIA specific auto edits
            report.edits.application = "ARIA";
            break;
          default:
            break;
        }

        // if application is changing
        if (prop === "application") {
          switch (value) {
            case "DRA":
            case "FT":
              report.edits.order = "Group";
              report.edits.freshAnomalies = "All";
              report.edits.range = "";
              report.edits.faultTrees = ["All"]; // default
              break;
            case "ST":
              report.edits.order = "Trend";
              report.edits.freshAnomalies = "";
              report.edits.range = "";
              break;
            case "OF":
              report.edits.order = "Level";
              report.edits.freshAnomalies = "";
              report.edits.range = "";
              report.edits.fitnessLevels = [OF_REPORT_LEVEL_OPTIONS[0].value]; // ALL
              break;
            default:
              break;
          }
        }

        // if summaryType is changing
        if (prop === "summaryType" && prevValue !== value) {
          report.edits.frequency = "";
          switch (value) {
            case "Shift":
              report.edits.frequency = "Shift";
              break;
            default:
              break;
          }
        }

        report.edits[prop] = value;

        return report;
      });
    };

    /* Sort the list -- substitute your own sorting logic here. */
    const sortedReports = _.orderBy(
      reports,
      (report) => {
        return report.data.updated_at || new Date().toISOString();
      },
      ["desc"]
    );
    const newOpen = Object.keys(reports).includes(NEW_ID);
    const count = Object.keys(reports).length - (newOpen ? 1 : 0);

    const groups = _.map(that.state.groups, (group) => {
      return { value: group._id, label: group.name };
    });

    const faultTrees = _.map(that.state.faultTrees, (faultTree) => {
      return { value: faultTree._id, label: faultTree.name };
    });

    const users = _.map(that.state.users, (user) => {
      return { value: user._id, label: `${user.first} ${user.last}` };
    });

    const teams = _.map(that.state.teams, (team) => {
      return { value: team._id, label: `${team.name}` };
    });

    const hasEditPermission = this.props.user.hasEditPermission;
    return (
      <First>
        <MainLoader match={!loaded && !error} />
        <Error
          match={error}
          message="An error has occured. Please refresh the page."
        />

        {/* Actual Page */}
        <Fragment>
          <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
            <div className="flex items-center mb-2 sm:mb-0">
              <span className="text-[2rem] sm:text-[1.75rem] mr-2">
                {PAGE_NAME}
              </span>

              {hasEditPermission && (
                <CreateNewEntityButton
                  onClick={() =>
                    this.setAsyncState((state) => {
                      state.reports[NEW_ID] = {
                        state: ENTITY_STATES.editing,
                        errors: [],
                        data: {},

                        edits: {
                          application: "DRA",
                          watchlist: "None",
                          anomalies: "All",
                          order: "Group",
                          dri: "All",
                          trendLength: "All",
                          freshAnomalies: "All",
                          users: [],
                        },
                      };

                      return { reports: state.reports };
                    })
                  }
                />
              )}
            </div>
            <div>
              <span className="mr-5 italic text-[1.25rem] text-titlegrey">
                Displaying {count} Specialty Reports
              </span>
              {/* TODO implement download  */}
            </div>
          </div>
          <ul className="pl-0 list-none mt-4 drop-shadow-md">
            {_.map(sortedReports, (report) => {
              const id = report._id;
              const editRef = React.createRef();
              const applicationType = this.applicationTypeToDisplay(
                report.data.application
              );
              return (
                <li
                  key={id}
                  className="bg-white mb-4 rounded-lg border border-bordgrey2 py-2 px-3"
                >
                  <First reverse>
                    <Fragment>
                      <div className="pt-[0.1em] pb-[0.5rem] border-b border-bordgrey2 flex flex-wrap justify-between items-center">
                        <div className="flex items-center">
                          {report.data.locked && (
                            <span className="SpecialtyReportManager__entity__locked">
                              <i className="fa fa-lock" aria-hidden="true" />
                              <span className="SpecialtyReportManager__entity__locked__label">
                                Locked to Root Users
                              </span>
                            </span>
                          )}

                          <span className="SpecialtyReportManager__entity__name">
                            {report.data.name}
                          </span>
                          <Badge variant={"amber"}>
                            {report.extendedData.formattedFrequency}
                            {report.extendedData.formattedShiftTimes}
                          </Badge>
                          {applicationType && (
                            <Badge variant={"primary"} className="ml-3">
                              {applicationType}
                            </Badge>
                          )}
                        </div>
                        <div>
                          {/* Whether or not the entity needs to indicate that a save is taking place. */}
                          <First>
                            <i
                              match={flags.set(
                                report.state,
                                ENTITY_STATES.saving
                              )}
                              className="Manager__entity__saving fa fa-spinner fa-pulse fa-fw"
                              aria-hidden="true"
                            />

                            <Fragment>
                              {hasEditPermission && (
                                <Tooltip label="Delete" withArrow>
                                  <Button
                                    className="ml-auto btn-ghost"
                                    icon="trash-o"
                                    disabled={!report.editable}
                                    onClick={() => {
                                      window.confirm(
                                        "Are you sure you want to delete this Specialty Report?"
                                      ) && that.deleteReport(id);
                                    }}
                                  />
                                </Tooltip>
                              )}

                              {hasEditPermission && (
                                <Tooltip label="Edit" withArrow>
                                  <Button
                                    className="btn-ghost"
                                    id={`${that.prefix}-${id}-EntityEditBtn`}
                                    icon="pencil"
                                    disabled={!report.editable}
                                    onClick={() =>
                                      this.setEntityState(
                                        id,
                                        (report) => {
                                          return {
                                            state: flags.set(
                                              report.state,
                                              ENTITY_STATES.editing,
                                              true
                                            ),
                                          };
                                        },
                                        updateReport
                                      )
                                    }
                                  />
                                </Tooltip>
                              )}

                              <Tooltip label="Email to Yourself" withArrow>
                                <Button
                                  className="btn-ghost"
                                  icon="user"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Do you want to email this report to yourself?"
                                      )
                                    ) {
                                      return that.resendReport(id, [
                                        this.props.user._id,
                                      ]);
                                    }
                                  }}
                                />
                              </Tooltip>

                              {hasEditPermission && (
                                <Tooltip label="Email to Subscribers" withArrow>
                                  <Button
                                    className="btn-ghost"
                                    icon="envelope-o"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Do you want to email this report to all subscribed users?"
                                        )
                                      ) {
                                        return that.resendReport(id);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </Fragment>
                          </First>
                        </div>
                        <div className="italic mb-3 w-4/5 text-xslate-11">
                          {report.data.description}
                        </div>

                        <BadgesForSpecialtyReportCard report={report} />
                      </div>

                      <div className="border-b border-bordgrey2 pb-2">
                        <PillRow
                          label={
                            this.isFaultTreeReport(report)
                              ? "Fault trees"
                              : "Groups"
                          }
                          items={
                            this.isFaultTreeReport(report)
                              ? report.data.faultTrees?.length > 0
                                ? report.data.faultTrees
                                    .map(
                                      (ftid) =>
                                        that.state.faultTrees[ftid]?.name
                                    )
                                    .filter((n) => !!n)
                                : ["All"]
                              : report.extendedData.formattedGroups.map(
                                  ({ label }) => label
                                )
                          }
                        />

                        {/* <div className="SpecialtyReportManager__entity__toggles"> */}
                        {/*   {report.data.watchlist === 'All' && ( */}
                        {/*     <Fragment> */}
                        {/*       <i className="fa fa-long-arrow-right" aria-hidden="true" /> */}
                        {/*       <span className="SpecialtyReportManager__entity__toggle"> */}
                        {/*         Include Watchlist */}
                        {/*       </span> */}
                        {/*   </Fragment>)} */}

                        {/*   {report.data.includeRemaining && ( */}
                        {/*     <Fragment> */}
                        {/*       <i className="fa fa-long-arrow-right" aria-hidden="true" /> */}
                        {/*       <span className="SpecialtyReportManager__entity__toggle">Include Remaining Variables</span> */}
                        {/*     </Fragment> */}
                        {/*   )} */}

                        {/*   {report.data.dri === 'Select' && ( */}
                        {/*     <Fragment> */}
                        {/*       <i className="fa fa-long-arrow-right" aria-hidden="true" /> */}
                        {/*       <span className="SpecialtyReportManager__entity__toggle">Include DRI of Select Groups Only</span> */}
                        {/*     </Fragment> */}
                        {/*   )} */}
                        {/* </div> */}
                      </div>
                      <div className="flex justify-between italic text-[0.9rem] text-textgrey mt-2">
                        <div>
                          <span>
                            {report.extendedData.formattedUserNumberString}
                          </span>
                        </div>
                        <UpdatedAt
                          noContainer={true}
                          timestamp={report.extendedData.updated_at}
                        />
                      </div>
                    </Fragment>

                    {/* Edit Mode */}
                    <Form
                      match={flags.set(report.state, ENTITY_STATES.editing)}
                      classes={{ Form: "p-[0.5em]" }}
                      ref={editRef}
                      disabled={() => !report.editable}
                      selectOnMount={`${that.prefix}-${id}-EntityEditName`}
                      selectOnExit={`${that.prefix}-${id}-EntityEditBtn`}
                      onSubmit={() => {
                        const edits = that.state.reports[id].edits;
                        const endpoint =
                          id === NEW_ID
                            ? that.createReport.bind(
                                that,
                                edits,
                                this.isFaultTreeReport(report)
                              )
                            : that.patchReport.bind(that, id, edits);

                        return endpoint()
                          .then(() => {
                            that.setState((state) => {
                              let report = state.reports[id];

                              if (id === NEW_ID) {
                                return {};
                              }

                              report.state = flags.set(
                                report.state,
                                ENTITY_STATES.editing,
                                false
                              );
                              return { reports: state.reports };
                            });
                            addSuccessToast("Report saved successfully");
                          })
                          .catch((e) => addUnknownErrorToast(e));
                      }}
                      onExit={() =>
                        this.setState((state) => {
                          let report = state.reports[id];
                          if (!report) return;

                          /* We don't currently preserve edits on cancel. */
                          report.edits = {};
                          report.state = flags.set(
                            report.state,
                            ENTITY_STATES.editing,
                            false
                          );

                          if (id === NEW_ID) {
                            state.reports[id] = null;
                          }

                          return { reports: state.reports };
                        })
                      }
                    >
                      <InputOld
                        type="multipleselect"
                        isMulti={false}
                        label={"Report Type"}
                        value={report.data.application}
                        action={(value) =>
                          updateReport(id, "application", value)
                        }
                        options={this.getReportTypes()}
                      />

                      <InputOld
                        id={`${that.prefix}-${id}-EntityEditName`}
                        classes={{
                          Input: "input-bordered w-full",
                        }}
                        value={report.data.name}
                        maxlength={30}
                        label={"Name"}
                        required={true}
                        action={(value) => updateReport(id, "name", value)}
                      />

                      <InputOld
                        classes={{
                          Input: "input-bordered w-full",
                        }}
                        value={report.data.description}
                        label={"Description"}
                        maxlength={200}
                        required={true}
                        action={(value) =>
                          updateReport(id, "description", value)
                        }
                      />

                      <div className="SpecialtyReportManager__entityColumn">
                        <InputOld
                          type="multipleselect"
                          isMulti={false}
                          label={"Summary Type"}
                          classes={{
                            Input: "",
                          }}
                          value={report.data.summaryType}
                          action={(value) => {
                            updateReport(id, "summaryType", value);
                          }}
                          options={this.getSummaryTypes(report)}
                        />

                        {report.data.summaryType === "Shift" && (
                          <div className="SpecialtyReportManager__entityColumn__subContent">
                            <div className="flex flex-row place-items-center gap-1">
                              <InputOld
                                type="multipleselect"
                                isMulti={false}
                                label={"Shift Start"}
                                classes={{
                                  Input: "",
                                }}
                                value={report.data.shiftStartDay}
                                action={(value) =>
                                  updateReport(id, "shiftStartDay", value)
                                }
                                options={[
                                  {
                                    value: "Previous",
                                    label: "Previous Day",
                                  },
                                  {
                                    value: "Current",
                                    label: "Current Day",
                                  },
                                ]}
                              />

                              <CloseableTimePicker
                                value={
                                  new Date(
                                    `2020-01-01 ${report.data.shiftStartTime}`
                                  )
                                }
                                onSubmit={(v) => {
                                  if (!v) return;
                                  updateReport(
                                    id,
                                    "shiftStartTime",
                                    `${v.getHours().toString().padStart(2, "0")}:${v.getMinutes().toString().padStart(2, "0")}`
                                  );
                                }}
                              />
                            </div>
                            <div className="flex flex-row place-items-center gap-1">
                              <InputOld
                                type="multipleselect"
                                isMulti={false}
                                label={"Shift End:"}
                                classes={{
                                  Input: "",
                                }}
                                value={report.data.shiftEndDay}
                                action={(value) =>
                                  updateReport(id, "shiftEndDay", value)
                                }
                                options={[
                                  {
                                    value: "Current",
                                    label: "Current Day",
                                  },
                                ]}
                              />
                              <CloseableTimePicker
                                value={
                                  new Date(
                                    `2020-01-01 ${report.data.shiftEndTime}`
                                  )
                                }
                                onSubmit={(v) => {
                                  if (!v) return;
                                  updateReport(
                                    id,
                                    "shiftEndTime",
                                    `${v.getHours().toString().padStart(2, "0")}:${v.getMinutes().toString().padStart(2, "0")}`
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="SpecialtyReportManager__entityColumn">
                        <InputOld
                          type="multipleselect"
                          isMulti={false}
                          label={"Delivery Time"}
                          classes={{
                            Input: "",
                          }}
                          value={report.data.frequency}
                          action={(value) =>
                            updateReport(id, "frequency", value)
                          }
                          options={this.getFrequencyList(report)}
                        />

                        {report.data.frequency === "Shift" &&
                          report.data.summaryType === "Shift" && (
                            <div className="SpecialtyReportManager__entityColumn__subContent">
                              <div className="flex flex-row place-items-center gap-1">
                                <InputOld
                                  type="multipleselect"
                                  isMulti={false}
                                  label={"Days"}
                                  classes={{
                                    Input: "",
                                  }}
                                  value={report.data.customDeliveryDays}
                                  action={(value) =>
                                    updateReport(
                                      id,
                                      "customDeliveryDays",
                                      value
                                    )
                                  }
                                  options={[
                                    { value: "All", label: "Every Day" },
                                    {
                                      value: "MondayFriday",
                                      label: "Monday - Friday",
                                    },
                                    {
                                      value: "SaturdaySunday",
                                      label: "Saturday - Sunday",
                                    },
                                    {
                                      value: "SundayThursday",
                                      label: "Sunday - Thursday",
                                    },
                                    {
                                      value: "FridaySaturday",
                                      label: "Friday - Saturday",
                                    },
                                  ]}
                                />

                                <CloseableTimePicker
                                  value={
                                    new Date(
                                      `2020-01-01 ${report.data.customDeliveryTime}`
                                    )
                                  }
                                  onSubmit={(v) => {
                                    if (!v) return;
                                    updateReport(
                                      id,
                                      "customDeliveryTime",
                                      `${v.getHours().toString().padStart(2, "0")}:${v.getMinutes().toString().padStart(2, "0")}`
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                      </div>

                      <div className="SpecialtyReportManager__doubleColumn">
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            label={
                              this.isFaultTreeReport(report)
                                ? "Specify Fault Trees"
                                : "Specify Group Order"
                            }
                            value={
                              this.isFaultTreeReport(report)
                                ? report.data.faultTrees === undefined ||
                                  report.data.faultTrees.length === 0
                                  ? ["All"]
                                  : report.data.faultTrees
                                : report.extendedData.formattedGroups
                            }
                            classes={{
                              Input:
                                " SpecialtyReport--short SpecialtyReportManager__entityEdit__groupOrder",
                            }}
                            action={(value) => {
                              updateReport(
                                id,
                                this.isFaultTreeReport(report)
                                  ? "faultTrees"
                                  : "groups",
                                value
                              );
                            }}
                            options={
                              this.isFaultTreeReport(report)
                                ? [
                                    { value: "All", label: "All published" },
                                    ...faultTrees,
                                  ]
                                : groups
                            }
                          />
                        </div>

                        {this.reportTypeUsesMoreOptions(report) && (
                          <div className="SpecialtyReportManager__entityColumn">
                            <InputOld label="More Options" type="group">
                              {this.isDRAReport(report) && (
                                <Fragment>
                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    value={report.data.watchlist}
                                    action={(value) =>
                                      updateReport(id, "watchlist", value)
                                    }
                                    options={[
                                      {
                                        value: "All",
                                        label: "Include All Watchlists",
                                      },
                                      {
                                        value: "Select",
                                        label:
                                          "Include Watchlist of Select Groups Only",
                                      },
                                      {
                                        value: "None",
                                        label: "Do Not Include Watchlist",
                                      },
                                    ]}
                                  />

                                  {/* Some translation here between boolean and multiselect.
                                   * Think about making this an enum in the backend. */}
                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    value={
                                      report.data.includeRemaining
                                        ? "Yes"
                                        : "No"
                                    }
                                    action={(value) => {
                                      value = value.value === "Yes";
                                      return updateReport(
                                        id,
                                        "includeRemaining",
                                        value
                                      );
                                    }}
                                    options={[
                                      {
                                        value: "Yes",
                                        label: "Include Remaining Variables",
                                      },
                                      {
                                        value: "No",
                                        label:
                                          "Do Not Include Remaining Variables",
                                      },
                                    ]}
                                  />

                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    value={report.data.dri}
                                    action={(value) =>
                                      updateReport(id, "dri", value)
                                    }
                                    options={[
                                      {
                                        value: "All",
                                        label: "Include DRI For All Groups",
                                      },
                                      {
                                        value: "Select",
                                        label:
                                          "Include DRI of Select Groups Only",
                                      },
                                      {
                                        value: "None",
                                        label: "Do Not Include DRI",
                                      },
                                    ]}
                                  />
                                </Fragment>
                              )}
                              {this.isOFReport(report) && (
                                <Fragment>
                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    value={
                                      report.data.fitnessSendWithNoVariables
                                        ? "Yes"
                                        : "No"
                                    }
                                    action={(value) => {
                                      value = value.value === "Yes";
                                      return updateReport(
                                        id,
                                        "fitnessSendWithNoVariables",
                                        value
                                      );
                                    }}
                                    options={[
                                      {
                                        value: "No",
                                        label: "Do Not Send Report When Empty",
                                      },
                                      {
                                        value: "Yes",
                                        label: "Send Report When Empty",
                                      },
                                    ]}
                                  />

                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    value={
                                      report.data.fitnessOnlyShowSelectedLevels
                                        ? "Yes"
                                        : "No"
                                    }
                                    action={(value) => {
                                      value = value.value === "Yes";
                                      return updateReport(
                                        id,
                                        "fitnessOnlyShowSelectedLevels",
                                        value
                                      );
                                    }}
                                    options={[
                                      {
                                        value: "No",
                                        label:
                                          "Show All Limits on Trend Charts",
                                      },
                                      {
                                        value: "Yes",
                                        label:
                                          "Show Select Limits on Trend Charts (Shown in Table)",
                                      },
                                    ]}
                                  />
                                </Fragment>
                              )}
                              {this.isFaultTreeReport(report) && (
                                <Fragment>
                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    options={[
                                      {
                                        value: "No",
                                        label:
                                          "Do Not Include Fault Trees Summary",
                                      },
                                      {
                                        value: "Yes",
                                        label: "Include Fault Tree Summary",
                                      },
                                    ]}
                                    action={(value) =>
                                      updateReport(id, "ft_summary", value)
                                    }
                                    value={
                                      report.data.ft_summary === false ||
                                      report.data.ft_summary === "No"
                                        ? "No"
                                        : "Yes"
                                    }
                                  />
                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    options={[
                                      {
                                        label:
                                          "Show FT only when Main Node is Active",
                                        value: "No",
                                      },
                                      {
                                        label:
                                          "Show FT when any Node is Active",
                                        value: "Yes",
                                      },
                                    ]}
                                    action={(value) =>
                                      updateReport(id, "any_node_active", value)
                                    }
                                    value={
                                      report.data.any_node_active === false ||
                                      report.data.any_node_active === "No"
                                        ? "No"
                                        : "Yes"
                                    }
                                  />
                                  <InputOld
                                    type="multipleselect"
                                    isMulti={false}
                                    classes={{
                                      Input: "",
                                    }}
                                    options={[
                                      {
                                        value: "No",
                                        label: "Do Not Send Report When Empty",
                                      },
                                      {
                                        value: "Yes",
                                        label: "Send Report When Empty",
                                      },
                                    ]}
                                    action={(value) =>
                                      updateReport(id, "send_empty", value)
                                    }
                                    value={
                                      report.data.send_empty === false ||
                                      report.data.send_empty === "No" ||
                                      report.data.send_empty === undefined
                                        ? "No"
                                        : "Yes"
                                    }
                                  />
                                </Fragment>
                              )}
                              {this.isAriaReport(report) && (
                                <InputOld
                                  type="multipleselect"
                                  isMulti={false}
                                  classes={{
                                    Input: "",
                                  }}
                                  options={[
                                    {
                                      value: "No",
                                      label: "Do Not Send Report When Empty",
                                    },
                                    {
                                      value: "Yes",
                                      label: "Send Report When Empty",
                                    },
                                  ]}
                                  action={(value) =>
                                    updateReport(id, "send_empty", value)
                                  }
                                  value={
                                    report.data.send_empty === false ||
                                    report.data.send_empty === "No" ||
                                    report.data.send_empty === undefined
                                      ? "No"
                                      : "Yes"
                                  }
                                />
                              )}
                            </InputOld>
                          </div>
                        )}
                      </div>

                      {/* Final Double Column Section (single column in FT mode) */}
                      <div className="SpecialtyReportManager__entityColumn pr-2">
                        <InputOld
                          type="multipleselect"
                          label={"Subscribed Users"}
                          value={report.extendedData.formattedUsers}
                          classes={{
                            Input: "",
                          }}
                          action={(value) => updateReport(id, "users", value)}
                          options={users}
                        />
                      </div>

                      {/* Final Double Column Section (single column in FT mode) */}
                      <div className="SpecialtyReportManager__entityColumn">
                        <InputOld
                          type="multipleselect"
                          label={"Subscribed Teams"}
                          value={report.extendedData.formattedTeams}
                          classes={{
                            Input: "",
                          }}
                          action={(value) => updateReport(id, "teams", value)}
                          options={teams}
                        />
                      </div>

                      {/* Final Double Column Section (single column in FT mode) */}
                      <div className="SpecialtyReportManager__entityColumn">
                        {this.isAriaReport(report) && (
                          <>
                            <InputOld
                              type="multipleselect"
                              isMulti={false}
                              label="Drift Category"
                              classes={{
                                Input: "",
                              }}
                              options={[
                                {
                                  value: "All",
                                  label: "Major and Minor Drift Clusters",
                                },
                                {
                                  value: "Major",
                                  label: "Major Drift Clusters",
                                },
                                {
                                  value: "Minor",
                                  label: "Minor Drift Clusters",
                                },
                              ]}
                              action={(value) =>
                                updateReport(id, "drift_category", value)
                              }
                              value={report.data.drift_category}
                            />
                            <InputOld
                              type="multipleselect"
                              isMulti={false}
                              label="Cluster Type"
                              classes={{
                                Input: "",
                              }}
                              options={[
                                {
                                  value: "All",
                                  label: "All",
                                },
                                {
                                  value: "Static",
                                  label: "Static",
                                },
                                {
                                  value: "Dynamic",
                                  label: "Dynamic",
                                },
                              ]}
                              action={(value) =>
                                updateReport(id, "cluster_type", value)
                              }
                              value={report.data.cluster_type}
                            />
                          </>
                        )}
                      </div>

                      {/* Date Range -- needs some custom behavior. */}
                      <div className="SpecialtyReportManager__entityColumn">
                        <InputOld
                          type="multipleselect"
                          isMulti={false}
                          label={"Date Range of Charts"}
                          classes={{
                            Input__label:
                              "SpecialtyReportManager__entityEdit__label--short",
                            Input: " SpecialtyReport--short",
                          }}
                          value={report.data.range}
                          action={(value) => updateReport(id, "range", value)}
                          options={this.getRangeList(report)}
                        />
                      </div>

                      {!this.isFaultTreeReport(report) && (
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            isMulti={false}
                            label={"Order by"}
                            classes={{
                              Input__label:
                                "SpecialtyReportManager__entityEdit__label--short",
                              Input: " SpecialtyReport--short",
                            }}
                            value={report.data.order}
                            action={(value) => updateReport(id, "order", value)}
                            options={this.getOrderList(report)}
                          />
                        </div>
                      )}

                      {this.isOFReport(report) && (
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            isMulti={false}
                            label={"Analysis Type"}
                            classes={{
                              Input__label:
                                "SpecialtyReportManager__entityEdit__label--short",
                              Input: " SpecialtyReport--short",
                            }}
                            value={report.data.fitnessAnalysisType}
                            action={(value) =>
                              updateReport(id, "fitnessAnalysisType", value)
                            }
                            options={[
                              {
                                value: "Exceedances",
                                label: "Exceedances",
                              },
                              {
                                value: "PredProb75",
                                label: "Pred. Prob. > 75% (7d)",
                              },
                              {
                                value: "PredProb50",
                                label: "Pred. Prob. > 50% (7d)",
                              },
                              {
                                value: "PredProbWithIncrease",
                                label:
                                  "Significant Increase in Pred. Prob. (7d)",
                              },
                              {
                                value: "PredProb75NoExceedances",
                                label: "Pred. Prob. > 75% (7d), No Exceedances",
                              },
                              {
                                value: "PredProb50NoExceedances",
                                label: "Pred. Prob. > 50% (7d), No Exceedances",
                              },
                              {
                                value: "PredProbWithIncreaseNoExceedance",
                                label:
                                  "Significant Increase in Pred. Prob. (7d), No Exceedances",
                              },
                            ]}
                          />
                        </div>
                      )}

                      {this.isOFReport(report) && (
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            label={"Levels"}
                            classes={{
                              Input__label:
                                "SpecialtyReportManager__entityEdit__label--short",
                              Input: " SpecialtyReport--short",
                            }}
                            value={report.data.fitnessLevels}
                            action={(value) => {
                              updateReport(id, "fitnessLevels", value);
                            }}
                            options={OF_REPORT_LEVEL_OPTIONS}
                          />
                        </div>
                      )}
                      {this.isVariabilityReport(report) && (
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            isMulti={false}
                            label={"Change In Variability"}
                            classes={{
                              Input__label:
                                "SpecialtyReportManager__entityEdit__label--short",
                              Input: " SpecialtyReport--short",
                            }}
                            value={report.data.changeInVariability}
                            action={(value) =>
                              updateReport(id, "changeInVariability", value)
                            }
                            options={[
                              { value: "All", label: "All" },
                              { value: "Major", label: "Major" },
                              { value: "Moderate", label: "Moderate" },
                              { value: "Minor", label: "Minor" },
                            ]}
                          />
                        </div>
                      )}

                      {this.reportTypeUsesAnomalies(report) && (
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            isMulti={false}
                            label={"Anomaly Category"}
                            classes={{
                              Input__label:
                                "SpecialtyReportManager__entityEdit__label--short",
                              Input: " SpecialtyReport--short",
                            }}
                            value={report.data.anomalies}
                            action={(value) =>
                              updateReport(id, "anomalies", value)
                            }
                            options={[
                              {
                                value: "All",
                                label:
                                  "3rd and 2nd Degree Anomalies (Recommended)",
                              },
                              {
                                value: "Third",
                                label: "3rd Degree Anomalies",
                              },
                              {
                                value: "Second",
                                label: "2nd Degree Anomalies",
                              },
                            ]}
                          />
                        </div>
                      )}

                      {this.reportTypeUsesDRIValues(report) && (
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            isMulti={false}
                            label={"Anomaly Type"}
                            tooltip={
                              "Fresh Anomaly Definition: When a variable's degree of anomaly for a day " +
                              "is greater than its degree of anomaly for the previous day, it is considered a Fresh " +
                              "or New Anomaly. That is, if the degree of anomaly goes down or remains the same " +
                              "compared to the previous day, it is not included as a Fresh Anomaly."
                            }
                            classes={{
                              Input__label:
                                "SpecialtyReportManager__entityEdit__label--short",
                              Input: " SpecialtyReport--short",
                            }}
                            value={report.data.freshAnomalies}
                            action={(value) =>
                              updateReport(id, "freshAnomalies", value)
                            }
                            options={[
                              { value: "All", label: "All (Default)" },
                              { value: "Fresh", label: "Fresh Only" },
                              {
                                value: "Ordered",
                                label: "Fresh First, Followed by Other",
                              },
                            ]}
                          />
                        </div>
                      )}

                      {this.reportTypeUsesTrendLength(report) && (
                        <div className="SpecialtyReportManager__entityColumn">
                          <InputOld
                            type="multipleselect"
                            isMulti={false}
                            label={"Trends"}
                            classes={{
                              Input__label:
                                "SpecialtyReportManager__entityEdit__label--short",
                              Input: " SpecialtyReport--short",
                            }}
                            value={report.data.trendLength}
                            action={(value) =>
                              updateReport(id, "trendLength", value)
                            }
                            options={[
                              {
                                value: "All",
                                label: "Long-term, Medium-term, Short-term",
                              },
                              { value: "LongTerm", label: "Long-term" },
                              {
                                value: "MediumTerm",
                                label: "Medium-term",
                              },
                              { value: "ShortTerm", label: "Short-term" },
                            ]}
                          />
                        </div>
                      )}
                      <div className="flex pt-6 justify-between w-full">
                        <Button
                          label="Cancel"
                          className="btn-outline btn-error"
                          id={`${that.prefix}-${id}-EntityEditCancel`}
                          onClick={(e) => {
                            e.preventDefault();
                            editRef.current.exit();
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          id={`${that.prefix}-${id}-ModeEditSave`}
                          loading={
                            !!flags.set(report.state, ENTITY_STATES.saving)
                          }
                          disabled={!report.editable}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  </First>

                  <First>
                    <Fragment match={!!report.errors.length}>
                      <div className="Manager__saveMessage Manager__saveMessage--error">
                        {/* React DOM code is still compiled even when it's not rendered.
                         * So we need to account for when there's no error. */}
                        {(report.errors[0] || {}).msg}
                      </div>
                    </Fragment>

                    <Fragment
                      match={flags.set(
                        report.state,
                        ENTITY_STATES.save_completed
                      )}
                    >
                      <div className="Manager__saveMessage">Success!</div>
                    </Fragment>
                  </First>
                </li>
              );
            })}
          </ul>
        </Fragment>
      </First>
    );
  }
}

/**
 * A work around for using React Hooks with a class based component
 */
function PageWithHooks(props) {
  const user = useAuthStore((s) => s.user);
  const currentUnit = useCurrentUnitObject();
  usePermissionBasedDocumentTitleForSettingsPage();
  return (
    <AddonRoute addonKey="specialtyReports">
      <UserPermissionRoute rolenum={2}>
        <SpecialtyReportManager
          user={user}
          currentUnit={currentUnit}
          {...props}
        />
      </UserPermissionRoute>
    </AddonRoute>
  );
}

function CloseableTimePicker({ ...props }) {
  const [open, setOpen] = useState(false);
  return <TimePicker {...props} open={open} onOpenChange={(o) => setOpen(o)} />;
}

export default PageWithHooks;
