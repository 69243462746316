import React, { useRef, useState } from "react";
import { useModal } from "../../common/useModal";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Modal } from "@mantine/core";
import Button from "../../common/Button/Button";
import classNames from "classnames";
import { Badge } from "../../ui/badge";
import {
  useFaultTreeCopyMutation,
  useFaultTreeDeleteMutation,
  useFaultTreeMutation,
  useFaultTreesQuery,
  useFtSectionsQuery,
} from "../../../hooks/tanstack-query";
import { faultTreeSchema } from "../../../lib/api-schema/ft/fault-tree";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import { useSortable } from "@dnd-kit/sortable";
import { cn, iife } from "../../../lib/utils";
import { CSS } from "@dnd-kit/utilities";
import { ArrowRightToLine, GripVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
} from "../../../shared-ui/frontend/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { Button as Button2 } from "../../ui/button";
import { Sections } from "../../../lib/api-schema/sections";
import { useEditSectionMutation } from "../../groups/manager/ft-section-card";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";

export function FaultTreeManagerCard({
  tree,
  renderAsDraggable,
  dragDisabled,
}: {
  tree: faultTreeSchema;
  renderAsDraggable?: boolean;
  dragDisabled?: boolean;
}) {
  const deleteMut = useFaultTreeDeleteMutation();
  const editMut = useFaultTreeMutation();
  const copyMut = useFaultTreeCopyMutation();
  const togglePublished = () => {
    editMut.mutate({
      ...tree,
      published: !tree.published,
    });
  };
  const [showCopyModal, setshowCopyModal] = useState(false);
  const [showPublishingModal, setShowPublishingModal] = useState(false);

  const MODAL = useModal();
  const hasEditAccess = useHasEditPermission();
  const textInput = useRef<HTMLInputElement>(null);

  const numberOfNodes = tree.nodeCount || 0;
  const nodeCountString = `${numberOfNodes} node${
    numberOfNodes !== 1 ? "s" : ""
  }`;

  const loc = useLocation();
  const pathname = loc.pathname;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: tree._id,
    disabled: iife(() => {
      if (!renderAsDraggable) return true;
      if (!hasEditAccess) return true;

      return !!dragDisabled;
    }),
  });

  const publishButton = (
    <Button
      size="xs"
      className={classNames({
        "btn-success": tree.published,
        "pointer-events-none": !hasEditAccess,
      })}
      onClick={() =>
        hasEditAccess &&
        (tree.published ? togglePublished() : setShowPublishingModal(true))
      }
    >
      {tree.published ? "Published" : "Unpublished"}
    </Button>
  );

  return (
    <div
      ref={renderAsDraggable ? setNodeRef : undefined}
      {...(renderAsDraggable ? listeners : undefined)}
      {...(renderAsDraggable ? attributes : undefined)}
      style={
        renderAsDraggable
          ? {
              // see https://github.com/clauderic/dnd-kit/issues/44
              transform: CSS.Translate.toString(transform),
              transition,
            }
          : undefined
      }
      className={cn(
        "p-1 pl-0 mt-2.5 border border-solid border-[#dbdbdb] bg-white rounded-xl drop-shadow-sm z-0 pb-0",
        renderAsDraggable &&
          isDragging &&
          "ring-2 ring-offset-2 ring-purple-500 opacity-50"
      )}
    >
      <div className="relative">
        {/* hide this btn for User role  */}
        {renderAsDraggable && hasEditAccess && (
          <GripVertical className="size-5 text-xslate-9 absolute left-0 top-1/2 -translate-x-[130%] -translate-y-1/2" />
        )}
        {/* name and description  */}
        <div className="text-[#000000] flex items-center justify-between text-[1.05em] pb-1 pr-1 pl-3 rounded-t-xl">
          <div className="py-[0.4em] w-1/2 sm:w-auto sm:block flex flex-col">
            <span className="font-medium px-0 my-[0.2em] mx-0">
              {tree.name}
            </span>
            <Badge variant={"amber"} className="ml-3">
              {nodeCountString}
            </Badge>
          </div>

          {/* edit and delete buttons  */}
          <div>
            {tree.published == null ? null : (
              <>
                {hasEditAccess ? (
                  <Tooltip>
                    <TooltipContent>
                      Click to {tree.published ? "un" : ""}publish
                    </TooltipContent>
                    <TooltipTrigger asChild>{publishButton}</TooltipTrigger>
                  </Tooltip>
                ) : (
                  publishButton
                )}
                <Modal
                  classNames={{ modal: "rounded-xl" }}
                  size="35%"
                  opened={showPublishingModal}
                  centered={true}
                  onClose={() => {
                    setShowPublishingModal(false);
                  }}
                  title={`Are you sure you want to publish ${tree.name}? Processing will take some time.`}
                >
                  <form
                    className="flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setShowPublishingModal(false);
                      togglePublished();
                    }}
                  >
                    <Button type="submit" className="ml-auto">
                      Publish
                    </Button>
                  </form>
                </Modal>
              </>
            )}

            <CreateSectionWithOrAddToExistingDropdownMenu tree={tree} />

            {/* this modal won't be rendered until showCopyModal is true */}
            {hasEditAccess ? (
              <>
                <Modal
                  classNames={{ modal: "rounded-xl" }}
                  size="35%"
                  opened={showCopyModal}
                  centered={true}
                  onClose={() => {
                    setshowCopyModal(false);
                    textInput.current!.value = ""; // reset the form
                  }}
                  title={`Enter a new name for the copy of ${tree.name}`}
                >
                  <form
                    className="input-group"
                    onSubmit={(e) => {
                      e.preventDefault();
                      copyMut.mutate({
                        id: tree._id,
                        name: textInput.current!.value,
                      });
                      setshowCopyModal(false);
                    }}
                  >
                    <input
                      type="text"
                      ref={textInput}
                      required={true}
                      placeholder="New fault tree name"
                      className="input input-bordered w-full input-sm"
                    />
                    <Button type="submit">Create</Button>
                  </form>
                </Modal>
                <Tooltip>
                  <TooltipContent>Copy</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      className="ml-auto btn-ghost"
                      icon="copy"
                      onClick={() => setshowCopyModal(true)}
                    />
                  </TooltipTrigger>
                </Tooltip>
              </>
            ) : null}

            {!tree.published && hasEditAccess && (
              <Tooltip>
                <TooltipContent>Delete</TooltipContent>
                <TooltipTrigger asChild>
                  <Button
                    className="ml-auto btn-ghost"
                    icon="trash-o"
                    onClick={() => {
                      MODAL.openModal({
                        title: `Are you sure you want to delete Fault Tree: ${tree.name}?`,
                        buttons: [
                          {
                            type: "button",
                            label: "DELETE",
                            className: "btn-error",
                            onClick: () => {
                              deleteMut.mutate(tree._id);
                              MODAL.closeModal();
                            },
                          },
                        ],
                        closeLabel: "CANCEL",
                      });
                    }}
                  />
                </TooltipTrigger>
              </Tooltip>
            )}
            {/* mark where we come from because another page links to the edit page as well */}
            {hasEditAccess ? (
              <Link to={`../../ft/edit/${tree._id}`} state={{ from: pathname }}>
                <Tooltip>
                  <TooltipContent>Edit</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button icon="pencil" className="btn-ghost" />
                  </TooltipTrigger>
                </Tooltip>
              </Link>
            ) : (
              <Link to={`../../ft/edit/${tree._id}`} state={{ from: pathname }}>
                <Tooltip>
                  <TooltipContent>View Tree</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button icon="arrow-circle-right" className="btn-ghost" />
                  </TooltipTrigger>
                </Tooltip>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function CreateSectionWithOrAddToExistingDropdownMenu({
  tree,
}: {
  tree: faultTreeSchema;
}) {
  const sections = useFtSectionsQuery().data;
  const hasEditAccess = useHasEditPermission();

  if (!hasEditAccess) return null;

  // dont show button if no section
  if (!sections || sections.sections.length === 0) return null;

  return (
    <Tooltip>
      <TooltipContent>
        <span>Move to a section</span>
      </TooltipContent>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <TooltipTrigger asChild>
            <Button2 variant={"ghost"} size={"icon"}>
              <ArrowRightToLine className="size-4" />
            </Button2>
          </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <DropdownMenuLabel>Sections</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            {sections.sections.map((s) => {
              return (
                <AddToExistingSectionDropdownMenuButton
                  section={s}
                  tree={tree}
                  key={s._id}
                />
              );
            })}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </Tooltip>
  );
}

function AddToExistingSectionDropdownMenuButton({
  section,
  tree,
}: {
  section: Sections["sections"][number];
  tree: faultTreeSchema;
}) {
  const alreadyIncluded = section.groups.includes(tree._id);

  const editMut = useEditSectionMutation({
    onSuccess: () => {
      addSuccessToast(`${tree.name} added to ${section.name}`);
    },
  });

  const treesQuery = useFaultTreesQuery();
  if (!treesQuery.data) return null;

  return (
    <DropdownMenuCheckboxItem
      checked={alreadyIncluded}
      disabled={editMut.isLoading || alreadyIncluded}
      onSelect={() => {
        if (alreadyIncluded)
          return addUnknownErrorToast(
            `${tree.name} is already a part of ${section.name}`
          );

        const current = section.groups.slice().sort((a, b) => {
          const aGroup = treesQuery.data?.find((x) => x._id === a);
          const bGroup = treesQuery.data?.find((x) => x._id === b);

          if (!aGroup) return 1;
          if (!bGroup) return -1;

          return aGroup.displayId - bGroup.displayId;
        });

        editMut.mutate({
          groupIds: assertMinLen1(current.concat(tree._id)),
          name: section.name,
          sectionId: section._id,
        });
      }}
    >
      <span>{section.name}</span>
    </DropdownMenuCheckboxItem>
  );
}

export default FaultTreeManagerCard;
