import {
  type ComponentProps,
  memo,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useIsMyFitnessLimitsOnStore } from "../use-is-my-fitness-limits-on";
import { getTzAwareDateStateDomainSync } from "./chart-range/use-tz-aware-date-state-domain";
import {
  MyFitnessLimit,
  TrendChartQueryType,
  useInstantCalculatorQuery,
  useTrendChartQuery,
} from "../../../hooks/tanstack-query";
import { useMemo } from "use-memo-one";
import {
  atom,
  createStore,
  Provider,
  useAtomValue,
  useSetAtom,
  useStore,
} from "jotai";
import {
  Atoms,
  ChartVariant,
  InitialTrendLine,
  MyFitnessLimitForSelectedVariable,
  SvvMyLimit,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { chartFormat } from "../../../shared-ui/time-series-2/fetcher-utils";
import {
  GenericSecondaryVariableViewNeedsProvider,
  type AvailableProps,
  useDebouncedEffectiveRangeForTimeseriesQuery,
} from "./dra-and-aria-secondary-variable.view";
import { useDateState } from "../../../zustand/useDateState";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { useGetUseProfileBookStoreNotRequired } from "../../pb/use-profile-book-store";
import { TimeseriesForBv } from "../../../shared-ui/time-series-2/types";
import { useGetUseViewModeStoreNotRequired } from "../../../shared-ui/time-series-2/grid-view-store";
import { minutesToMilliseconds } from "date-fns";
import { useCommentsListQuery } from "./comments/comment-pills";
import { useGetUseDriStoreNotRequired } from "../../dri/hooks/create-use-dri-store";
import { HeaderGuts } from "./header";
import * as HeaderPrimitive from "../../../shared-ui/time-series-2/secondary-variable-view/header";
import { focusAtom } from "jotai-optics";
import { isVariableVariant } from "../../../shared-ui/time-series-2/draw/draw";

export function chartDimensionsConfig(
  opts:
    | {
        isGridView: false;
      }
    | {
        isGridView: true;
        numCols: number;
      }
): Required<
  Pick<
    ComponentProps<typeof DRASecondaryVariableViewNeedsProvider>,
    "axesFontScale" | "height" | "width" | "lineWidthScale"
  >
> {
  if (opts.isGridView) {
    const ratio = 3;
    const width = 2000;
    const height = width / ratio;

    const twoColsOrLess = opts.numCols <= 2;

    return {
      axesFontScale: twoColsOrLess ? 1.1 : 1.6,
      height,
      width,
      lineWidthScale: 0.3,
    };
  }

  const ratio = 4;
  const width = 3000;
  const height = width / ratio;

  return {
    axesFontScale: 0.9,
    height: height,
    width: width,
    lineWidthScale: 0.3,
  };
}

/**
 * Use this if you want to provide your own store provider. See
 * the component below for an example of how to provide your
 * own store
 *
 * If you don't want to provide your own store provider, use the
 * component below.
 */
function DRASecondaryVariableViewNeedsProvider({
  onClose,
  expandable,
  ...props
}: ComponentProps<typeof DRAChartHeader> &
  Omit<
    AvailableProps,
    keyof Pick<
      AvailableProps,
      | "data"
      | "analysisPeriod"
      | "allowModeTransparency"
      | "header"
      | "myFitnessLimitsMapSorted"
    >
  >) {
  const s = useStore();
  const myFitnessLimitsMapForChart = useAtomValue(bvsToMyFitnessLimitsAtomAtom);
  const myFitnessLimitsOn = useIsMyFitnessLimitsOnStore((s) => s.on);
  const usePbStore = useGetUseProfileBookStoreNotRequired();
  const useDriStore = useGetUseDriStoreNotRequired();
  const useViewModeStore = useGetUseViewModeStoreNotRequired();
  const setExpanded = useSetAtom(Atoms.expandedAtom);

  const setAnomAll = useSetAtom(Atoms.setAllAnomalyColorationAtom);
  const setShutdownAll = useSetAtom(Atoms.setAllShutdownMaskAtom);
  const setModeTransparencyAll = useSetAtom(Atoms.setAllModeTransparencyAtom);
  const variableIds = useAtomValue(Atoms.onlyVariableTrendLinesAtom)
    .map((x) => x.bv.slice(24))
    .sort();
  const [start, end] = useAtomValue(Atoms.getDomainAtom);
  const [startRequest, endRequest] =
    useDebouncedEffectiveRangeForTimeseriesQuery();

  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);

  const isZoomed = start !== startRequest || end !== endRequest;

  const timeseriesQuery = useTrendChartQuery(
    {
      variables: variableIds,
      start: startRequest,
      end: endRequest,
      idk: isZoomed
        ? {
            type: TrendChartQueryType.Zoomed,
            originalEnd: end,
            originalStart: start,
          }
        : { type: TrendChartQueryType.Regular },
    },
    {
      enabled: canRequestData && variableIds.length > 0,
      staleTime: minutesToMilliseconds(3),
    }
  );

  const expressionsToRequest = useAtomValue(
    Atoms.onlyExpressionTrendLinesAtom
  ).map(({ expression, id }) => ({ expression, id }));

  const icQuery = useInstantCalculatorQuery(expressionsToRequest, start, end, {
    enabled: canRequestData && expressionsToRequest.length > 0,
  });

  const timeseriesDataAsChartFormat = useMemo(() => {
    const ts: TimeseriesForBv[] =
      (timeseriesQuery.data &&
        chartFormat(timeseriesQuery.data, "0".repeat(24))) ??
      [];

    const ic = icQuery.data ?? [];

    return ts.concat(ic);
  }, [timeseriesQuery.data, icQuery.data]);

  useEffect(() => {
    if (!usePbStore) return;
    return usePbStore.subscribe(
      (s) => s.showAnomalies,
      (currAnomOn) => {
        setAnomAll(currAnomOn);
      }
    );
  }, [usePbStore, setAnomAll]);
  useEffect(() => {
    if (!usePbStore) return;
    return usePbStore.subscribe(
      (s) => s.globalShutdownToggle,
      (currShutdownOn) => {
        setShutdownAll(currShutdownOn);
      }
    );
  }, [usePbStore, setShutdownAll]);
  useEffect(() => {
    if (!usePbStore) return;
    return usePbStore.subscribe(
      (s) => s.globalModeTransparencyToggle,
      (currModeTransparencyOn) => {
        setModeTransparencyAll(currModeTransparencyOn);
      }
    );
  }, [usePbStore, setModeTransparencyAll]);
  useEffect(() => {
    if (!useDriStore) return;
    return useDriStore.subscribe(
      (s) => s.showAnomalies,
      (currAnomOn) => {
        setAnomAll(currAnomOn);
      }
    );
  });
  useEffect(() => {
    if (!useDriStore) return;
    return useDriStore.subscribe(
      (s) => s.globalShutdownToggle,
      (currShutdownOn) => {
        setShutdownAll(currShutdownOn);
      }
    );
  });
  useEffect(() => {
    if (!useDriStore) return;
    return useDriStore.subscribe(
      (s) => s.globalModeTransparencyToggle,
      (currModeTransparencyOn) => {
        setModeTransparencyAll(currModeTransparencyOn);
      }
    );
  });
  useEffect(() => {
    if (!useViewModeStore) return;
    return useViewModeStore.subscribe(
      (s) => s.viewMode,
      (newViewMode) => {
        if (newViewMode === "grid") {
          setExpanded(false);
        }
      }
    );
  }, [useViewModeStore, setExpanded]);
  const zone = useTimezone();
  const ds = useDateState();
  const startAnalysis = DateTime.fromISO(ds.axisRangeTo.dateString, {
    zone: zone,
  });
  const endAnalysis = startAnalysis.endOf("day").toMillis();

  const commentsQuery = useCommentsListQuery({
    /**
     * When changing the date range, this query will
     * momentarily have no data as it loads, which causes
     * the red block to disappear momentarily. Keep the
     * previous data until the new data arrives so the
     * red block doesn't flicker.
     */
    keepPreviousData: true,
  });

  const myFitnessLimitsQuery = MyFitnessLimit.useListQuery({
    staleTime: minutesToMilliseconds(10),
    refetchOnMount: false,
  });

  const myFitnessLimitsFromApi = myFitnessLimitsQuery.data;

  const limitsAndBvsData = useAtomValue(limitsAndBvs);

  useEffect(() => {
    const reconcile = (apiData: typeof myFitnessLimitsFromApi) => {
      if (!apiData) {
        for (const x of limitsAndBvsData) {
          const limitsEnabled = s.get(x.myLimits);
          if (!limitsEnabled) continue;
          s.set(limitsEnabled, (curr) => {
            return {
              ...curr,
              limits: [],
            };
          });
        }

        return;
      }

      for (const selectedVariable of limitsAndBvsData) {
        const myLimitsStateAtom = s.get(selectedVariable.myLimits);
        if (!myLimitsStateAtom) continue;

        const belong = apiData.filter(
          (x) => x.variableId === selectedVariable.bv.slice(24)
        );

        s.set(myLimitsStateAtom, (curr) => {
          const currLimits = curr.limits;

          return {
            ...curr,
            limits: belong
              .map((x): SvvMyLimit => {
                const editAtom = currLimits.find(
                  (y) => y._id === x._id
                )?.editAtom;

                return {
                  color: x.color, // inherit trend line color
                  _id: x._id,
                  value: x.value,
                  editAtom,
                  type: x.type,
                };
              })
              .sort((a, b) => b.value - a.value),
          };
        });
      }
    };

    reconcile(myFitnessLimitsFromApi);
  }, [myFitnessLimitsFromApi, s, limitsAndBvsData]);

  return (
    <GenericSecondaryVariableViewNeedsProvider
      myFitnessLimitsMapSorted={
        myFitnessLimitsOn ? myFitnessLimitsMapForChart : undefined
      }
      header={<DRAChartHeader expandable={expandable} onClose={onClose} />}
      allowModeTransparency
      analysisPeriod={[startAnalysis.toMillis(), endAnalysis]}
      data={timeseriesDataAsChartFormat}
      redBlockStart={useMemo(() => {
        if (!commentsQuery.publicComments.data) return;
        const comments = commentsQuery.publicComments.data.docs;
        const openIssues = comments.filter(
          (x) => x.type === "Issue" && !x.issue_resolved
        );

        if (!openIssues.length) return undefined;

        let min = Infinity;

        for (const x of openIssues) {
          const t = DateTime.fromISO(x.start_date, { zone: "utc" })
            // comments still use fake dates
            .setZone(zone, {
              keepLocalTime: true,
            })
            .toMillis();
          min = t < min ? t : min;
        }

        return min; // guaranteed not to be Infinity
      }, [commentsQuery.publicComments.data, zone])}
      {...props}
    />
  );
}

function DRASecondaryVariableViewProvider({
  initialBatchVariables,
  initialExpanded,
  decideAnomBooleanForNewlyAddedVariableTrendLines,
  children,
  variant,
}: {
  initialExpanded: boolean;
  initialBatchVariables: [InitialTrendLine, ...InitialTrendLine[]];
  variant?: ChartVariant;
  decideAnomBooleanForNewlyAddedVariableTrendLines?: (
    initialSt: boolean,
    isOperatingLimitsVariant: boolean
  ) => boolean;
} & PropsWithChildren) {
  const zone = useTimezone();
  const [s] = useState(() =>
    createJotaiStoreForChartSync({
      zone,
      decideAnomBooleanForNewlyAddedVariableTrendLines,
      initialBatchVariables,
      initialExpanded,
      variant,
    })
  );
  return <Provider store={s}>{children}</Provider>;
}

function createJotaiStoreForChartSync({
  initialBatchVariables,
  initialExpanded,
  decideAnomBooleanForNewlyAddedVariableTrendLines,
  variant,
  zone,
}: {
  initialExpanded: boolean;
  initialBatchVariables: [InitialTrendLine, ...InitialTrendLine[]];
  variant?: ChartVariant;
  decideAnomBooleanForNewlyAddedVariableTrendLines?: (
    initialSt: boolean,
    isOperatingLimitsVariant: boolean
  ) => boolean;
  zone: string;
}) {
  const s = createStore();
  s.set(Atoms.setInitialStateAtom, {
    batchVariablesOrExpressions: initialBatchVariables,
    decideAnomBooleanForNewlyAddedVariableTrendLines,
    expanded: initialExpanded,
    variant,
  });

  const [start, end] = getTzAwareDateStateDomainSync(
    new URLSearchParams(window.location.search),
    zone
  );
  s.set(Atoms.setDomainAtom, [start, end]);
  return s;
}

const limitsAndBvs = focusAtom(Atoms.selectedVariablesAtom, (o) =>
  o.elems().guard(isVariableVariant).pick(["myLimits", "bv", "color"])
);

const bvsToMyFitnessLimitsAtomAtom = atom((get) => {
  return Object.fromEntries(
    get(limitsAndBvs)
      .map((x): undefined | [string, MyFitnessLimitForSelectedVariable[]] => {
        const myLimitsStateAtom = get(x.myLimits);

        if (!myLimitsStateAtom) return undefined;

        const myLimitsState = get(myLimitsStateAtom);

        const { limits } = myLimitsState;

        const out = limits
          .map((x): MyFitnessLimitForSelectedVariable => {
            const isEditing = x.editAtom;
            if (isEditing) {
              const edits = get(isEditing);
              const editedValueParsed = parseFloat(edits.editedValue);

              return {
                _id: x._id,
                value: isNaN(editedValueParsed) ? x.value : editedValueParsed,
                type: edits.editedType,
                color: edits.editedColor,
              };
            } else {
              return {
                _id: x._id,
                value: x.value,
                type: x.type,
                color: x.color,
              };
            }
          })
          .sort((a, b) => a.value - b.value);

        return [x.bv, out] as const;
      })
      .filter((x) => x !== undefined)
  );
});

const DRAChartHeader = memo<ComponentProps<typeof HeaderGuts>>(
  function DRAChartHeader(props) {
    return (
      <HeaderPrimitive.Container className="gap-0 @lg:gap-2">
        <HeaderGuts {...props} />
      </HeaderPrimitive.Container>
    );
  }
);

export {
  DRASecondaryVariableViewProvider,
  DRASecondaryVariableViewNeedsProvider,
  createJotaiStoreForChartSync,
};
