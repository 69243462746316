import * as R from "remeda";
import {
  useGetUseVariabilityDrawerStore,
  useMin1Groups,
} from "../variability-drawer";
import { useVariablesMappedByIdQuery } from "../../../hooks/tanstack-query";
import { cn } from "../../../lib/utils";
import { Copy, X } from "lucide-react";
import { CustomRangePopover } from "../../dateSelector/custom-range-button/custom-range-button";
import { useEffect, useState } from "react";
import { DTCMasksDropdown2 } from "../../charts/DTC/dtc-masks-dropdown-2";
import { ellipsify } from "../../utils/stylable";
import { FaEllipsisV } from "react-icons/fa";
import { VariableInfoTooltip } from "../../charts/DTC/variable-info-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { ScrollArea, ScrollBar } from "../../ui/scroll-area";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import { Popover, PopoverTrigger } from "../../ui/popover";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../shared-ui/frontend/dialog";
import { BsFillPinAngleFill } from "react-icons/bs";
import { GroupStatistics } from "./components/GroupStatistics";
import CapabilityCalculator from "./components/CapabilityCalculator";
import { useVariabilityDataQueries } from "../use-variability-query";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import {
  ABBREVIATED_MONTH_DATE,
  LONG,
  TWELVE_HR_TIME,
} from "../../../shared-ui/lib/luxon-format-tokens";
import { Button } from "../../../shared-ui/frontend/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";

export function CardsHeader() {
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const allGroups = useMin1Groups();

  const variablesMap = variablesMapQuery.data;

  return (
    <>
      {variablesMap && (
        <ScrollArea className="w-[100dvw]">
          <div className="flex p-4 gap-5 w-max">
            {allGroups.map((group) => {
              return <Group group={group} key={group.id} />;
            })}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      )}
    </>
  );
}

function Group({ group }: { group: ReturnType<typeof useMin1Groups>[number] }) {
  const useStore = useGetUseVariabilityDrawerStore();
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const variablesMap = variablesMapQuery.data;
  const allGroups = useMin1Groups();
  const variabilityQueries = useVariabilityDataQueries(allGroups);
  const data = R.filter(
    variabilityQueries.map((query) => query.data),
    R.isTruthy
  );
  const tz = useTimezone();

  const showLimitStats = useStore((s) => {
    const gs = s.groups;
    if (!gs) throw new Error("No groups");

    return (
      R.uniq(gs.flatMap((g) => g.variables.map((v) => v._id))).length === 1 &&
      s.showLimitLines
    );
  });

  if (!variablesMap) return null;

  return (
    <div
      className="border border-xslate-8 bg-xslate-2 p-4 rounded-lg inline-flex gap-2.5 relative"
      id={group.id}
    >
      <EditRangeForGroupDialog group={group} />
      {allGroups.length !== 1 && (
        <Button
          variant={"destructive"}
          className="h-5 w-5 p-0 absolute top-0 -translate-y-1/2 right-0 translate-x-1/2"
          onClick={() => {
            useStore.getState().removeGroup(group.id);
          }}
        >
          <X className="h-3 w-3" />
        </Button>
      )}
      <AnimatePresence>
        {group.variables.map((v) => {
          const variableObj = variablesMap[v._id];
          const variableData = data.find((data) => data.ref === v.id);

          if (!variableObj) return null;

          const allowVariableDelete = group.variables.length > 1;

          return (
            <motion.div
              initial={{ opacity: 0.3, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.3 }}
              key={v.id}
              className={cn(
                "relative flex flex-col hover:border-xslate-8 justify-between shadow-sm rounded-lg border border-xslate-5 group h-[85px] w-[300px] pl-2 pr-1.5 py-1.5 bg-white transition-all shrink-0",
                v.selected && "ring-2 ring-xindigo-8 text-xindigo-11"
              )}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      const err = useStore
                        .getState()
                        .copyVariableIntoNewGroupWithPreviousDateRange(
                          v.id,
                          tz
                        );
                      err && addUnknownErrorToast(err);
                    }}
                    size="icon"
                    className="h-7 w-7 absolute left-1 bottom-1"
                    variant={"ghost"}
                  >
                    <Copy className="h-3.5 w-3.5 text-xslate-9" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Previous Period</TooltipContent>
              </Tooltip>
              <div className="flex gap-2 items-start justify-between">
                <div className="flex flex-row">
                  <div
                    className="h-3 w-3 rounded-full shrink-0 mt-1"
                    style={{
                      backgroundColor: v.color,
                    }}
                  />
                  <span
                    className={cn(
                      "text-sm tracking-tight font-medium text-xslate-11 transition-colors break-all ml-2",
                      v.selected ? "text-xindigo-11" : "group-hover:text-black"
                    )}
                  >
                    {ellipsify(variableObj.trimmedName, 50)}
                  </span>
                </div>
                <Button
                  variant={"ghost"}
                  size={"icon"}
                  className={cn(
                    "h-5 w-5 text-xslate-11",
                    v.selected && "text-xindigo-11"
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    useStore.getState().toggleHighlightVariable(v.id);
                  }}
                >
                  <BsFillPinAngleFill className="h-3 w-3 hover:text-xindigo-11" />
                </Button>
              </div>
              {allowVariableDelete && (
                <Button
                  variant={"destructive"}
                  size={"icon"}
                  className="h-4 w-4 absolute right-0 top-0 -translate-y-1/2 translate-x-1/2"
                  onClick={(e) => {
                    e.stopPropagation();
                    useStore.getState().removeVariable(v.id);
                    // if (isHighlighted) useStore.getState().toggleSelectedVariable(undefined);
                  }}
                >
                  <X className="h-3 w-3" />
                </Button>
              )}

              <div className="inline-flex shrink-0 flex-row ml-auto items-end">
                {variableData && variableData.stats && v && (
                  <CapabilityCalculator
                    variable={{ ...variableData, ...v }}
                    start={group.start}
                    end={group.end}
                    variableName={variableObj.trimmedName}
                    withoutPortal
                  />
                )}
                <GroupStatistics
                  className="hover:text-xindigo-11"
                  showLimitStats={showLimitStats}
                  end={group.end}
                  start={group.start}
                  excludedModes={v.excludedModesMap}
                  variableId={v._id}
                  color={v.color}
                  withoutPortal
                />
                <VariableInfoTooltip variableId={v._id} />
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"ghost"}
                      size={"icon-sm"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FaEllipsisV className="size-3 text-xslate-11 hover:text-xindigo-11" />
                    </Button>
                  </PopoverTrigger>
                  <DTCMasksDropdown2
                    withoutPortal
                    side="bottom"
                    align="center"
                    dates={[group.start, group.end]}
                    variableId={v._id}
                    toggledModes={v.excludedModesMap}
                    onModeToggle={(newExcludedModesMap) => {
                      useStore
                        .getState()
                        .updateModesMapForSingleBucket(
                          v.id,
                          newExcludedModesMap
                        );
                    }}
                    showExcludedDates={true}
                  />
                </Popover>
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

function EditRangeForGroupDialog({
  group,
}: {
  group: ReturnType<typeof useMin1Groups>[number];
}) {
  const updateRangeForGroup = useGetUseVariabilityDrawerStore()(
    (s) => s.updateRange
  );
  const [open, setOpen] = useState(false);

  const zone = useTimezone();

  const [dates, setDates] = useState<[DateTime, DateTime]>(() => [
    DateTime.fromMillis(group.start, { zone }),
    DateTime.fromMillis(group.end, { zone }),
  ]);

  function handleOpenChange(b: boolean) {
    setOpen(b);
    setDates([
      DateTime.fromMillis(group.start, { zone }),
      DateTime.fromMillis(group.end, { zone }),
    ]);
  }

  const getDateLabel = (): {
    start: string;
    end: string;
    label: string;
    full?: string;
  } => {
    const { end, start } = group;
    const startDate = DateTime.fromMillis(start, { zone });
    const endDate = DateTime.fromMillis(end, { zone });

    const isDefaultTime = () => {
      const startTime = startDate.toFormat(TWELVE_HR_TIME);
      const endTime = endDate.toFormat(TWELVE_HR_TIME);

      return startTime === "12:00 AM" && endTime === "11:59 PM";
    };

    const shortFormat = ABBREVIATED_MONTH_DATE;
    const fullFormat = LONG;

    const fullStartLabel = startDate.toFormat(fullFormat);
    const fullEndLabel = endDate.toFormat(fullFormat);

    const full = `${fullStartLabel}  —  ${fullEndLabel}`;

    if (isDefaultTime()) {
      const startLabel = startDate.toFormat(shortFormat);
      const endLabel = endDate.toFormat(shortFormat);
      return {
        start: startLabel,
        end: endLabel,
        label: `${startLabel}  —  ${endLabel}`,
        full,
      };
    }

    return {
      start: fullStartLabel,
      end: fullEndLabel,
      label: full,
    };
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button
          variant={"default"}
          className="z-[5001] whitespace-pre absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[60%] rounded-md h-5 text-xs"
        >
          {getDateLabel().label}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit date range</DialogTitle>
          <DialogDescription>
            The new date range will be applied to all variables in this group.
          </DialogDescription>
        </DialogHeader>
        <CustomRangePopover
          withoutPortal
          side="bottom"
          value={{
            start: dates[0]
              .setZone("local", { keepLocalTime: true })
              .toJSDate(),
            end: dates[1].setZone("local", { keepLocalTime: true }).toJSDate(),
          }}
          calendarPosition="bottom"
          onChange={(local) => {
            const { start, end } = local;
            setDates([
              DateTime.fromJSDate(start).setZone(zone, { keepLocalTime: true }),
              DateTime.fromJSDate(end).setZone(zone, { keepLocalTime: true }),
            ]);
          }}
        >
          <Button variant="outline" size="sm" className="w-full my-4">
            <span>
              {dates[0].toFormat(LONG)} - {dates[1].toFormat(LONG)}
            </span>
          </Button>
        </CustomRangePopover>
        <DialogFooter>
          <Button
            variant="outline"
            size={"sm"}
            onClick={() => {
              // we need to utc-ify the dates
              const [startAsLocal, endAsLocal] = dates;
              updateRangeForGroup(group.id, [
                startAsLocal.toMillis(),
                endAsLocal.toMillis(),
              ]);
              handleOpenChange(false);
            }}
          >
            Apply
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
