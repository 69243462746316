import { useMemo, useRef, useState } from "react";
import {
  useAriaClustersQuery,
  useGroupsQuery,
  useToggleAria,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import ClusterCard from "../../clusters/manager/ClusterCard";
import SearchBar from "../../common/SearchBar";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import useSearch from "../../common/useSearch";
import {
  ClusterCardType,
  useFormOptions,
} from "../../clusters/manager/ClustersManager";
import { clusterSchema } from "../../../lib/api-schema/cluster";
import { FullscreenProvider } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { TimeseriesChartTooltipStoreProvider } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { ClickedLineControlsNeedsTimeseriesTooltipStoreProvider } from "../../time-series/secondary-variable-view/control-buttons";
import { GlobalTooltip } from "../../time-series/global-tooltip";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { cn } from "../../../lib/utils";
import { Info } from "lucide-react";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import { Collapse } from "@mantine/core";
import CreateEntityForm from "../../common/manager/CreateEntityForm";
import { GroupSelect, ProfileBookFullscreenChart } from "../../pb/profile-book";
import { useSearchParams } from "react-router-dom";
import { DRAParamsMap } from "../../boundaries/hooks/useDRAParams2";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";

const PAGE_NAME = "ARIA + Dynamic Clusters";

function AriaManager() {
  usePermissionBasedDocumentTitleForSettingsPage();
  const hasEditAccess = useAuthStore((s) => s.user?.hasEditPermission);
  const [createMode, setCreateMode] = useState(false);
  const clustersQuery = useAriaClustersQuery();
  const toggleAria = useToggleAria();
  const tagsQuery = useVariablesArrayQuery();
  const [selectedClusterType, setSelectedClusterType] = useState<
    "static" | "dynamic"
  >("static");

  const clustersWithTagNames = useMemo(() => {
    const clusters = clustersQuery.data || [];
    const tags = tagsQuery.data || [];
    return clusters.map((c) => {
      return {
        ...c,
        variables: c.variables
          .map((v) => {
            const tagFound = tags.find((t) => t._id === v);
            // Javascript will take in whole Tag object even if we cast it differently in TS
            return {
              _id: tagFound?._id,
              name: tagFound?.name,
              trimmedName: tagFound?.trimmedName,
              nameWithDescription: tagFound?.nameWithDescription,
            };
          })
          .filter((x) => x !== undefined),
      };
    });
  }, [clustersQuery.data, tagsQuery.data]);

  const sortOptions = useRef({
    options: {
      "cluster score": (a: ClusterCardType, b: ClusterCardType) => {
        const diff = (b.score || 0) - (a.score || 0);
        if (diff === 0) {
          return a.name.localeCompare(b.name);
        }
        return diff;
      },
      "last updated": (a: clusterSchema, b: clusterSchema) => {
        const diff =
          new Date(b.updated_at || "").getTime() -
          new Date(a.updated_at || "").getTime();
        if (diff === 0) {
          return a.name.localeCompare(b.name);
        }
        return diff;
      },
      alphabetical: (a: ClusterCardType, b: ClusterCardType) =>
        a.name.localeCompare(b.name),
      "# of members (ascending)": (a: ClusterCardType, b: ClusterCardType) => {
        const diff = a.variables.length - b.variables.length;
        if (diff === 0) {
          return a.name.localeCompare(b.name);
        }
        return diff;
      },
      "# of members (descending)": (a: ClusterCardType, b: ClusterCardType) => {
        const diff = b.variables.length - a.variables.length;
        if (diff === 0) {
          return a.name.localeCompare(b.name);
        }
        return diff;
      },
    },
  }).current;

  const searchQueryLabelsAndKeys: Record<string, { keys: string[] }> = {
    "cluster name": { keys: ["name" satisfies keyof ClusterCardType] },
    description: { keys: ["description" satisfies keyof ClusterCardType] },
    "associated tags": {
      keys: [
        "variables.*.nameWithDescription" satisfies `${keyof ClusterCardType}.*.${keyof ClusterCardType["variables"][number]}`,
      ],
    },
  };

  // map the potential search keys' display names to object keys (this is the format match-sorter uses)
  const searchOptions = useRef({
    options: {
      "All attributes": {
        keys: Object.values(searchQueryLabelsAndKeys)
          .map((b) => b.keys)
          .flat(),
      },
      ...searchQueryLabelsAndKeys,
    },
  }).current;

  // @ts-ignore
  const { matches, filter, search, sort } = useSearch(clustersWithTagNames, {
    search: searchOptions,
    sort: sortOptions,
  });
  const typedMatches = matches as ClusterCardType[];

  // filter clusters by selected group
  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;
  const [sp, _setSp] = useSearchParams();
  const groupId = sp.get(DRAParamsMap.g)?.trim() || null;
  const groupObj = groups?.find(
    (g) => g.short_id === parseInt(groupId || "-1")
  );

  const filteredMatches = typedMatches.filter(
    (m) =>
      m.type === selectedClusterType &&
      (!groupObj || m.variables.some((v) => groupObj.variables.includes(v._id)))
  );

  const formOptions = useFormOptions();

  const unit = useCurrentUnitObject();
  const ariaEnabledThreshold = unit?.ariaEnabledThreshold || 0;

  return (
    <div className="mb-10">
      <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
        <div className="flex items-center mb-2">
          <span className="text-[2rem] sm:text-[1.75rem] mr-2">
            {PAGE_NAME}
          </span>

          {hasEditAccess && (
            <CreateNewEntityButton onClick={() => setCreateMode(true)} />
          )}
        </div>
      </div>
      <SearchBar
        filter={filter}
        search={search}
        sort={sort}
        children={
          <div className="input-group max-w-min">
            <span className="bg-neutral text-neutral-content text-[0.9rem]">
              Filter
            </span>
            <div className="w-40">
              <GroupSelect
                onChange={(shortIdStr) => {
                  _setSp((curr) => {
                    const copy = new URLSearchParams(curr);
                    copy.set(DRAParamsMap.g, shortIdStr);
                    return copy;
                  });
                }}
              />
            </div>
          </div>
        }
      />
      <Collapse in={createMode}>
        <CreateEntityForm
          {...formOptions}
          close={() => setCreateMode(false)}
          bordered={true}
          rounded={true}
        />
      </Collapse>
      <div className="flex w-full mt-4 mb-1">
        {(["static", "dynamic"] as const).map((tab) => (
          <div
            key={tab}
            className={cn(
              `text-md text-center flex-1 py-1 cursor-pointer capitalize`,
              selectedClusterType === tab
                ? "border-indigo-800 text-indigo-800 border-b-[3px]"
                : "border-slate-200 border-b-[1.2px]"
            )}
            onClick={() => setSelectedClusterType(tab)}
          >
            {tab === "static" ? "Custom" : "Dynamic"} clusters (
            {
              typedMatches.filter(
                (m) =>
                  m.type === tab &&
                  (!groupObj ||
                    m.variables.some((v) => groupObj.variables.includes(v._id)))
              ).length
            }
            )
            {tab === "dynamic" && (
              <TooltipProvider delayDuration={50}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <button
                      type="button"
                      className={`btn btn-circle btn-ghost btn-xs relative top-0.5`}
                    >
                      <Info className="size-4" />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      Dynamic Clusters are updated automatically by DRA on a
                      periodic basis
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        ))}
      </div>
      {filteredMatches.map((cluster) => {
        const disableToggle = // if cluster score is available and it's bad, disable the toggle
          cluster.score !== null &&
          cluster.score !== undefined &&
          cluster.score < ariaEnabledThreshold;
        return (
          <>
            <ClusterCard
              cluster={cluster}
              key={cluster._id}
              className={cluster.type === "dynamic" ? "bg-blue-50" : undefined}
              controls={
                cluster.type === "static" && (
                  <div className="form-control ml-auto">
                    <label
                      className={
                        disableToggle || !hasEditAccess
                          ? "label cursor-not-allowed pointer-events-none"
                          : "label cursor-pointer"
                      }
                    >
                      <span className="label-text mr-2">
                        ARIA {cluster.aria_enabled ? "enabled" : "disabled"}
                      </span>
                      <TooltipProvider delayDuration={50}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <input
                              type="checkbox"
                              className="toggle toggle-primary"
                              defaultChecked={cluster.aria_enabled}
                              // disabled={disableToggle || !hasEditAccess}
                              onInput={() => {
                                if (disableToggle || !hasEditAccess) return;
                                toggleAria.mutate(cluster._id);
                              }}
                            />
                          </TooltipTrigger>
                          {disableToggle && (
                            <TooltipContent>
                              <p>
                                ARIA analysis is recommended only for clusters
                                with 'Excellent' or 'Very Good' scores; clusters
                                with 'Poor' scores are not eligible.
                              </p>
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>
                    </label>
                  </div>
                )
              }
            />
          </>
        );
      })}
    </div>
  );
}

export default function Wrapped() {
  return (
    <FullscreenProvider>
      <TimeseriesChartTooltipStoreProvider>
        {() => (
          <>
            <ClickedLineControlsNeedsTimeseriesTooltipStoreProvider />
            <GlobalTooltip />
            <ProfileBookFullscreenChart />
            <AriaManager />
          </>
        )}
      </TimeseriesChartTooltipStoreProvider>
    </FullscreenProvider>
  );
}
