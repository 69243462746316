import { useEffect, useState } from "react";
import useAuthStore from "../zustand/auth/useAuthStore";
import {
  createUseAnalyticsStore,
  UseAnalyticsStore,
} from "./create-use-analytics-store";

function onVisibilityChange(fn: () => void) {
  document.addEventListener("visibilitychange", fn);
  return () => document.removeEventListener("visibilitychange", fn);
}

/**
 * These useEffects are pretty complicated because it matters a lot
 * when we create the store and when we fire off the events and when
 * to send/not send an event because the browser is/isn't focused.
 */
export function useAnalytics() {
  const [useAnalyticsStore, setUseAnalyticsStore] =
    useState<UseAnalyticsStore>();
  const userId = useAuthStore((s) => s.user?._id);

  useEffect(() => {
    if (!userId) return;
    if (useAnalyticsStore) return;

    // when the userId changes, we will create a new analytics store
    const focused = !document.hidden;

    if (focused) {
      setUseAnalyticsStore(createUseAnalyticsStore);
      return;
    }

    const handle = () => {
      if (!document.hidden) {
        setUseAnalyticsStore(createUseAnalyticsStore);
      }
    };

    return onVisibilityChange(handle);
  }, [userId, useAnalyticsStore]);

  useEffect(() => {
    if (!useAnalyticsStore) return;

    const analytics = useAnalyticsStore.getState();

    const handleFocus = () => analytics.addFocusEvent();
    const handleBlur = () => analytics.addBlurEvent();

    const handleVisibilityChange = () => {
      if (document.hidden) {
        analytics.persist();
        setUseAnalyticsStore(undefined);
      } else {
        analytics.addFocusEvent();
      }
    };

    const cleanup = onVisibilityChange(handleVisibilityChange);
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      cleanup();
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, [useAnalyticsStore]);
}
