import { memo, PropsWithChildren, useEffect, useState } from "react";
import classNames from "classnames";
import {
  VariableSearch,
  type VariableSearchResultComponent,
} from "./profile-book-variable-search";
import { cn } from "../../../lib/utils";
import { useGetUseViewModeStore } from "../../../shared-ui/time-series-2/grid-view-store";
import {
  SidebarTab,
  useGetUseProfileBookStoreRequired,
} from "../use-profile-book-store";
import { createStore, Provider, useAtomValue } from "jotai";
import { ProfileBookCurrentTagsList } from "./profile-book-current-tags-list";
import {
  ClusterFolders,
  GroupsFolders,
  PersonalFolders,
  SlopingTrendsFolders,
  AnomaliesAndWatchlistFolders,
} from "./profile-book-folders";
import { useSearchParams } from "react-router-dom";
import { Input } from "../../../shared-ui/frontend/input";
import {
  PersonalFoldersQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { useGetUseCreateOrEditFolderStore } from "../create-or-edit-folder-store";
import * as R from "remeda";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Badge } from "../../../shared-ui/frontend/badge";
import {
  Atoms,
  isVariableTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { Button } from "../../../shared-ui/frontend/button";
import { Label } from "../../../shared-ui/frontend/label";
import { variableSchema } from "../../../lib/api-validators";
import { useMutation } from "@tanstack/react-query";
import {
  editFolder,
  postFolder,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";
import { DATESTATE_SP_KEYS } from "../../../zustand/useDateState";
import { defaultRangeIndex } from "../../../constants/dateState";
import { createJotaiStoreForChartSync } from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { ScrollArea } from "../../../shared-ui/frontend/scroll-area";
import { useMemo, useMemoOne } from "use-memo-one";
import { useGetUseDisplayedChartsStores } from "../use-displayed-charts-store";
import { v4 } from "uuid";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { VariableTypeString } from "../../../types/api/Variable";
import {
  LocalSidebarCollapseButton,
  LocalSidebarContainer,
} from "../../common/sidebar/local-sidebar";
import { useBodyElementIsFullscreen } from "../full-screen";

const ProfileBookSidebar = memo(function () {
  const usePbStore = useGetUseProfileBookStoreRequired();
  const localSidebarOpen = usePbStore((s) => s.sidebarOpen);
  const setLocalSidebarOpen = usePbStore((s) => s.setSidebarOpen);
  const useViewModeStore = useGetUseViewModeStore();

  const isTvMode = useBodyElementIsFullscreen();

  useEffect(
    function enterGridModeWhenSidebarCloses() {
      return usePbStore.subscribe(
        (s) => s.sidebarOpen,
        (open) => {
          !open && useViewModeStore.getState().setViewMode("grid");
        }
      );
    },
    [usePbStore, useViewModeStore]
  );

  const isCreatingOrEditing = useGetUseCreateOrEditFolderStore()(
    (s) => s.data !== undefined
  );

  return (
    <>
      <LocalSidebarContainer
        open={localSidebarOpen}
        className={cn("mt-0", isTvMode && "hidden")}
      >
        {isCreatingOrEditing ? (
          <CreatePersonalFolderForm />
        ) : (
          <SidebarContent />
        )}
      </LocalSidebarContainer>
      <LocalSidebarCollapseButton
        className={cn(isTvMode && "hidden")}
        open={localSidebarOpen}
        onOpenToggle={() => setLocalSidebarOpen(!localSidebarOpen)}
      />
    </>
  );
});

function CreatePersonalFolderForm() {
  const variables = useVariablesArrayQuery().data;
  const usePbStore = useGetUseProfileBookStoreRequired();
  const setTitle = usePbStore((s) => s.setTitle);

  const folders = PersonalFoldersQuery.useQuery().data;

  const invalidateFoldersQuery = PersonalFoldersQuery.useInvalidate();

  const [name, setName] = useState("");

  const useCreateOrEditFoldersStore = useGetUseCreateOrEditFolderStore();
  const data = useCreateOrEditFoldersStore((s) => {
    const data = s.data;

    if (!data) throw new Error("Should not render this component without data");

    return data;
  });

  const { charts, editingFolderId } = data;

  const editingFolderName = folders?.folder.find(
    (f) => f._id === editingFolderId
  )?.name;

  useEffect(() => {
    if (!editingFolderId) return;
    if (editingFolderName !== undefined) setName(editingFolderName);
  }, [editingFolderName, editingFolderId]);

  const closeForm = useCreateOrEditFoldersStore((s) => s.exit);

  const b = useBaseUrlExperimental();

  const createFolderMutation = useMutation({
    mutationFn: async (payload: {
      name: string;
      variables: [string, ...string[]][];
    }) => {
      return await postFolder(b, payload);
    },
    onSuccess: ({ _id }) => {
      closeForm();
      invalidateFoldersQuery();

      setTitle({
        type: "personal-folders",
        _id,
      });
    },
    onError: (e) => addUnknownErrorToast(e),
  });

  const editFolderMutation = useMutation({
    mutationFn: async (payload: {
      _id: string;
      name: string;
      variables: (string | [string, ...string[]])[];
    }) => {
      return await editFolder(b, payload._id, payload.name, payload.variables);
    },
    onSuccess: ({ _id }) => {
      closeForm();
      invalidateFoldersQuery();

      setTitle({
        type: "personal-folders",
        _id,
      });
    },
    onError: (e) => addUnknownErrorToast(e),
  });

  const exitForm = useCreateOrEditFoldersStore((s) => s.exit);

  const includedExcluded = useMemoOne(() => {
    if (!variables) return undefined;
    const [variablesIncluded, variablesExcluded] = R.partition(
      variables.filter((x) => x.type === VariableTypeString.Tag),
      (v) => charts.some((c) => c.primaryVariableId === v._id)
    );

    return {
      variablesIncluded: variablesIncluded
        .map(
          (
            v
          ): {
            variable: typeof v;
            jotaiStore: ReturnType<typeof createStore>;
          } => {
            const found = charts.find((c) => c.primaryVariableId === v._id);
            if (!found) throw new Error("impossible");

            return {
              variable: v,
              jotaiStore: found.chartJotaiStore,
            };
          }
        )
        .sort(
          (a, b) =>
            charts.findIndex((c) => c.primaryVariableId === a.variable._id) -
            charts.findIndex((c) => c.primaryVariableId === b.variable._id)
        ),
      variablesExcluded,
    };
  }, [charts, variables]);

  if (!variables || !includedExcluded) return undefined;

  return (
    <form
      className="p-3 flex flex-col gap-2 animate-in slide-in-from-left-6 fade-in-15"
      onSubmit={(e) => {
        e.preventDefault();

        const trimmedName = name.trim();

        if (!trimmedName.length) {
          return addUnknownErrorToast(new Error("Folder name is required"));
        }

        const variablesIds: [string, ...string[]][] = charts.map((x) => {
          const selectedVariables = x.chartJotaiStore.get(
            Atoms.selectedVariablesAtom
          );
          return assertMinLen1(
            selectedVariables
              .filter(isVariableTrendLine)
              .map((x) => x.bv.slice(24))
          );
        });

        if (editingFolderId) {
          editFolderMutation.mutate({
            _id: editingFolderId,
            name: trimmedName,
            variables: variablesIds,
          });
        } else {
          createFolderMutation.mutate({
            name: trimmedName,
            variables: variablesIds,
          });
        }
      }}
    >
      <div
        className="flex flex-col gap-2"
        // always want the cancel / create buttons to be visible
        style={{ maxHeight: "calc(100vh - 150px)" }}
      >
        <Label>Folder Name</Label>
        <Input
          disabled={
            createFolderMutation.isLoading || editFolderMutation.isLoading
          }
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter folder name"
          required
        />

        <span className="flex items-center text-sm gap-2 font-medium">
          In this folder{" "}
          <Badge variant={"primary"}>
            {includedExcluded.variablesIncluded.length}
          </Badge>
        </span>
        <SwapListForIncluded
          disabled={
            createFolderMutation.isLoading || editFolderMutation.isLoading
          }
          variables={includedExcluded.variablesIncluded}
        />

        <span className="flex items-center text-sm gap-2 font-medium">
          All tags{" "}
          <Badge variant={"primary"}>
            {includedExcluded.variablesExcluded.length}
          </Badge>
        </span>
        <SwapListForAllTags
          disabled={
            createFolderMutation.isLoading || editFolderMutation.isLoading
          }
          included={includedExcluded.variablesIncluded}
          excluded={includedExcluded.variablesExcluded}
        />
      </div>

      <div className="flex justify-end gap-1">
        <Button
          disabled={
            createFolderMutation.isLoading || editFolderMutation.isLoading
          }
          variant={"destructive"}
          size={"sm"}
          type="button"
          onClick={exitForm}
        >
          Cancel
        </Button>
        <Button
          variant={"default"}
          size={"sm"}
          type="submit"
          disabled={
            createFolderMutation.isLoading || editFolderMutation.isLoading
          }
        >
          {editingFolderId === undefined ? "Create Folder" : "Submit"}
        </Button>
      </div>
    </form>
  );
}

type Included = {
  variable: variableSchema;
  jotaiStore: ReturnType<typeof createStore>;
};

function SwapListForIncluded({
  variables,
  disabled,
}: {
  variables: Included[];
  disabled?: boolean;
}) {
  const useCreateOrEditFoldersStore = useGetUseCreateOrEditFolderStore();
  const removeChart = useCreateOrEditFoldersStore((s) => s.removeChart);

  return (
    <SwapListScrollArea>
      {variables.map((v) => {
        return (
          <Provider key={v.variable._id} store={v.jotaiStore}>
            <IncludedButton
              onClick={() => removeChart(v.variable._id)}
              disabled={disabled}
            />
          </Provider>
        );
      })}
    </SwapListScrollArea>
  );
}

function SwapListScrollArea({ children }: PropsWithChildren) {
  return (
    <ScrollArea className="border rounded-md border-xslate-7 h-[33dvh]">
      <div className="flex flex-col divide-y divide-xslate-6">{children}</div>
    </ScrollArea>
  );
}

function IncludedButton({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) {
  const selectedVariablesForThisButton = useAtomValue(
    Atoms.onlyVariableTrendLinesAtom
  );

  const vs = useVariablesArrayQuery().data;

  const variableObjects = useMemoOne(() => {
    if (!vs) return undefined;

    const variableObjects = selectedVariablesForThisButton.map((x) => {
      const variableObj = vs.find((v) => v._id === x.bv.slice(24));
      if (!variableObj) throw new Error("impossible");

      return variableObj;
    });

    return assertMinLen1(variableObjects);
  }, [vs, selectedVariablesForThisButton]);

  if (!variableObjects) return null;

  const [first, ...rest] = variableObjects;

  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className="flex text-sm px-4 py-1 hover:pl-5 transition-all hover:bg-xslate-3 active:scale-95 items-center disabled:opacity-50 disabled:pointer-events-none disabled:bg-xslate-3"
    >
      <div className="inline-flex flex-col items-start">
        <span className="break-all text-left">{first.trimmedName}</span>
        <span className="break-all text-left not-last:mb-1.5">
          {first.description}
        </span>
        {rest.map((x) => {
          return (
            <span className="font-light text-xs ml-2" key={x._id}>
              - {x.trimmedName}
            </span>
          );
        })}
      </div>
      <FaMinus className="size-3 shrink-0 ml-auto text-xslate-11" />
    </button>
  );
}

function SwapListForAllTags({
  included: variables,
  excluded,
  disabled,
}: {
  included: {
    variable: variableSchema;
    jotaiStore: ReturnType<typeof createStore>;
  }[];
  excluded: variableSchema[];
  disabled?: boolean;
}) {
  const useCreateOrEditFoldersStore = useGetUseCreateOrEditFolderStore();
  const addChart = useCreateOrEditFoldersStore((s) => s.addChart);
  const zone = useTimezone();
  const [search, setSearch] = useState("");

  const trimmedSearch = search.trim().toLowerCase();

  const [includedFilteredBySearch, excludedFilteredBySearch] = useMemo(() => {
    const includedFilteredBySearch = variables.filter(
      (x) =>
        x.variable.trimmedName.toLowerCase().includes(trimmedSearch) ||
        x.variable.description.toLowerCase().includes(trimmedSearch)
    );

    const excludedFilteredBySearch = excluded.filter(
      (x) =>
        x.trimmedName.toLowerCase().includes(trimmedSearch) ||
        x.description.toLowerCase().includes(trimmedSearch)
    );

    return [includedFilteredBySearch, excludedFilteredBySearch] as const;
  }, [trimmedSearch, variables, excluded]);

  return (
    <>
      <Input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
      />
      <SwapListScrollArea>
        {includedFilteredBySearch.map((v) => {
          return (
            <Provider key={v.variable._id} store={v.jotaiStore}>
              <IncludedButton disabled />
            </Provider>
          );
        })}
        {excludedFilteredBySearch.map((v) => {
          return (
            <button
              disabled={disabled}
              type="button"
              onClick={() => {
                addChart(
                  v._id,
                  createJotaiStoreForChartSync({
                    zone,
                    initialBatchVariables: [
                      {
                        type: "variable",
                        bv: v._id.padStart(48, "0"),
                      },
                    ],
                    initialExpanded: false,
                  })
                );
              }}
              className="flex text-sm px-4 py-1 hover:pl-5 transition-all hover:bg-xslate-3 active:scale-95 items-center"
            >
              <div className="inline-flex flex-col items-start">
                <span className="break-all text-left">{v.trimmedName}</span>
                <span className="break-all text-left">{v.description}</span>
              </div>
              <FaPlus className="size-3 shrink-0 ml-auto text-xslate-11" />
            </button>
          );
        })}
      </SwapListScrollArea>
    </>
  );
}

const ProfileBookVariableSearch: VariableSearchResultComponent = ({
  variable,
}) => {
  const [, setSp] = useSearchParams();

  const usePbStore = useGetUseProfileBookStoreRequired();
  const setTab = usePbStore((s) => s.setSidebarTab);
  const title = usePbStore((s) => s.title);
  const setTitle = usePbStore((s) => s.setTitle);

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();
  const displayedCharts = useDisplayedChartsStore((s) => s.charts);
  const zone = useTimezone();

  const selectedByThisChart = (displayedCharts ?? []).find((c) => {
    const thisChartVariables = c.store
      .get(Atoms.allBvsAtom)
      .map((x) => x.slice(24));

    const isEq =
      thisChartVariables.length === 1 &&
      R.isDeepEqual(thisChartVariables, [variable._id]);

    return isEq;
  });

  const selected = !!selectedByThisChart;

  return (
    <button
      onClick={() => {
        setTab("Tags");
        setTitle({ type: "current-tags" });

        setSp((curr) => {
          const copy = new URLSearchParams(curr);

          // handle leaving the slopes view if we're in one
          title.type === "slopes" &&
            copy.set(
              DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
              defaultRangeIndex.toString()
            );
          return copy;
        });

        const currCharts = useDisplayedChartsStore.getState().charts ?? [];

        if (selectedByThisChart) {
          // this chart already exists, just move it to the top
          useDisplayedChartsStore
            .getState()
            .replaceCharts([
              selectedByThisChart,
              ...currCharts.filter((c) => c !== selectedByThisChart),
            ]);

          return; // dont add the chart below
        } else {
          const store = createJotaiStoreForChartSync({
            zone,
            initialExpanded: currCharts.length === 0, // adding this chart will make it the only one on the page
            initialBatchVariables: [
              {
                type: "variable",
                bv: variable._id.padStart(48, "0"),
              },
            ],
          });

          store.set(Atoms.setSlopingTrendsAtom, {
            on: usePbStore.getState().showSlopingTrends,
          });
          useDisplayedChartsStore.getState().addChart({
            id: v4(),
            store,
          });
        }
      }}
      className={classNames(
        "select-none active:scale-95 border-b border-xslate-5 w-full px-4 py-1 min-h-[2.5rem] flex flex-col justify-between text-[0.8rem] transition",
        {
          "bg-xindigo-4 text-xindigo-11 font-medium": selected,
          "hover:bg-xslate-2 hover:text-xindigo-11": !selected,
        }
      )}
    >
      <div className="break-all">{variable.trimmedName || " "}</div>
      <div className="break-all">{variable.description}</div>
    </button>
  );
};

function SidebarContent() {
  const [query, setQuery] = useState("");
  const usePbStore = useGetUseProfileBookStoreRequired();
  const tab = usePbStore((s) => s.sidebarTab);

  return (
    <>
      <div className="p-2 pt-4">
        <VariableSearch
          buildResult={ProfileBookVariableSearch}
          query={query}
          setQuery={setQuery}
        />
      </div>
      <TabsSelector onChange={() => setQuery("")} />

      {tab === "Folders" && (
        <div className="flex flex-col px-5 mt-4 pb-4 text-[13px] animate-in slide-in-from-bottom-5 gap-0.5">
          <AnomaliesAndWatchlistFolders />
          <SlopingTrendsFolders />
          <PersonalFolders />
        </div>
      )}
      {tab === "Groups" && (
        <div className="flex flex-col px-5 mt-4 pb-4 text-[13px] animate-in slide-in-from-bottom-5 gap-0.5">
          <GroupsFolders />
        </div>
      )}
      {tab === "Clusters" && (
        <div className="flex flex-col px-5 mt-4 pb-4 text-[13px] animate-in slide-in-from-bottom-5 gap-0.5">
          <ClusterFolders />
        </div>
      )}
      {tab === "Tags" && <ProfileBookCurrentTagsList />}
    </>
  );
}

function TabsSelector({
  onChange,
}: {
  onChange?: (query: SidebarTab) => void;
}) {
  const usePbStore = useGetUseProfileBookStoreRequired();
  const currentTab = usePbStore((s) => s.sidebarTab);
  const setTab = usePbStore((s) => s.setSidebarTab);

  return (
    <div className="flex">
      {(["Folders", "Clusters", "Groups", "Tags"] as const).map((tab) => (
        <div
          className={cn(
            `text-[13px] text-center flex-1 py-1 cursor-pointer`,
            currentTab === tab
              ? "border-indigo-800 text-indigo-800 border-b-[3px]"
              : "border-slate-200 border-b-[1.2px]"
          )}
          onClick={() => {
            onChange?.(tab);
            setTab(tab);
          }}
          key={tab}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}

export { ProfileBookSidebar };
