import { useEffect, useMemo, useState } from "react";
import { useDateState } from "../../../zustand/useDateState";
import {
  BsFillGrid3X3GapFill,
  BsFillLightningChargeFill,
} from "react-icons/bs";
import {
  useAriaClustersQuery,
  useClusterDriftScoreQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { type clusterSchema } from "../../../lib/api-schema/cluster";
import getAriaColors from "../../common/aria-colors";
import { useShowClickedLine } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import {
  AriaSecondaryVariableViewNeedsProvider,
  AriaSecondaryVariableViewProvider,
} from "../../time-series/secondary-variable-view/aria-secondary-variable.view";
import { useTzAwareDateStateDomain } from "../../time-series/secondary-variable-view/chart-range/use-tz-aware-date-state-domain";
import { StatusSeries } from "./status-series";
import { colorGen } from "../../../shared-ui/lib/getColors";
import Color from "color";
import { cn } from "../../../lib/utils";
import { Badge } from "../../../shared-ui/frontend/badge";
import {
  useIsFullscreen,
  useToggleFullscreen,
} from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { ClusterScoreBadge } from "../cluster-score-badge";
import { VIEW_MODE, ViewModeSelectors } from "../../common/view-mode-selectors";
import { Link } from "react-router-dom";
import { ExternalLink } from "lucide-react";
import { chartDimensionsConfig } from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import useBaseUrl from "../../../zustand/useBaseUrl";
import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import { Dialog, DialogContent } from "../../../shared-ui/frontend/dialog";
import { HeatMap } from "../../clusters/manager/HeatMap";
import {
  useGetUseViewModeStore,
  UseViewModeStoreProvider,
} from "../../../shared-ui/time-series-2/grid-view-store";
import { useStore } from "jotai";

const DRIFT_LEVELS = getAriaColors();

export function ClusterCharts(props: { selectedClusters: string[] }) {
  const $ds = useDateState();
  const cdsQuery = useClusterDriftScoreQuery(
    $ds.axisRangeFrom.dateString,
    $ds.axisRangeTo.dateString
  );
  const clustersQuery = useAriaClustersQuery({
    at: $ds.axisRangeTo.local,
    filterType: "dynamic",
  });
  const [globalViewMode, setGlobalViewMode] = useState<VIEW_MODE>(
    (localStorage.getItem("ARIA_VIEW_MODE") || "list") as VIEW_MODE
  );
  const [globalNumCols, setGlobalNumCols] = useState<number>(2);

  const clusters = useMemo(() => {
    const filteredAndSorted = (clustersQuery.data || [])
      .filter((cluster) => props.selectedClusters.includes(cluster._id))
      .map((c) => {
        const cdsData = cdsQuery.data || {};
        const cdsToday =
          cdsData[Object.keys(cdsData).sort().at(-1) || ""] || {};
        return {
          ...c,
          driftScore: cdsToday[c._id]?.maxLevel || 0,
        };
      })
      .sort((a, b) => {
        // Sort by drift score descending, then by name ascending
        if (a.driftScore === b.driftScore) {
          return a.name.localeCompare(b.name);
        }
        return b.driftScore - a.driftScore;
      });

    return filteredAndSorted.reduce(
      (acc, cluster) => {
        const { driftScore } = cluster;
        if (!acc[driftScore]) {
          acc[driftScore] = [];
        }
        acc[driftScore].push(cluster);
        return acc;
      },
      {} as Record<number, clusterSchema[]>
    );
  }, [clustersQuery.data, cdsQuery.data, props.selectedClusters]);

  return (
    <div className="grow">
      {Object.values(clusters).flat().length === 0 && (
        <div className="text-center text-2xl mt-4 font-semibold text-xslate-11">
          No clusters to display
        </div>
      )}
      {Object.keys(clusters)
        .sort((a, b) => parseInt(b) - parseInt(a))
        .map((s, i) => {
          const score = parseInt(s);
          const clustersWithScore = clusters[score];
          if (!clustersWithScore) return null;
          return (
            <>
              <div
                className={cn(
                  "border-b border-xslate-7 flex flex-row items-center pb-1.5 mb-2",
                  i > 0 && "pb-2 pr-2 mt-8"
                )}
              >
                <span className="text-xl tracking-tight text-xslate-12 font-semibold">
                  {DRIFT_LEVELS.find((l) => l.value === score)?.label ||
                    "Processing"}{" "}
                  Clusters
                </span>
                <Badge className="select-none ml-2" variant="aria">
                  {clustersWithScore.length} Clusters
                </Badge>

                {i === 0 && (
                  <ViewModeSelectors
                    variant={"aria"}
                    className="ml-auto mr-1.5"
                    viewMode={globalViewMode}
                    withLabels={true}
                    setViewMode={(mode) => {
                      setGlobalViewMode(mode);
                      localStorage.setItem("ARIA_VIEW_MODE", mode);
                    }}
                    numCols={globalNumCols}
                    setNumCols={(n) => {
                      setGlobalNumCols(n);
                    }}
                    enabledModes={["list", "grid"]}
                  />
                )}
              </div>
              <div
                className={`grid grid-cols-${globalViewMode === "grid" ? globalNumCols : 1} gap-2`}
              >
                {clustersWithScore.map((cluster) => {
                  return (
                    // id used by sidebar to scroll to
                    <div key={cluster._id} id={`cluster-${cluster._id}`}>
                      <UseViewModeStoreProvider
                        init={{ initialNumCols: 3, initialViewMode: "chart" }}
                      >
                        <ClusterCard
                          cluster={cluster}
                          globalViewMode={globalViewMode}
                        />
                      </UseViewModeStoreProvider>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
    </div>
  );
}

function ClusterCard({
  cluster,
  globalViewMode,
}: {
  cluster: clusterSchema;
  globalViewMode: VIEW_MODE;
}) {
  const domain = useTzAwareDateStateDomain();
  const showLineClick = useShowClickedLine();
  const variableObjects = useVariablesArrayQuery()?.data || [];
  const toggleFs = useToggleFullscreen();
  const urlParams = new URLSearchParams(window.location.search);
  const baseUrl = useBaseUrl();
  const [heatmapOpen, setHeatmapOpen] = useState(false);
  const useViewModeStore = useGetUseViewModeStore();
  const viewMode = useViewModeStore((s) => s.viewMode);
  const numCols = useViewModeStore((s) => s.numCols);
  const setViewMode = useViewModeStore((s) => s.setViewMode);
  const setNumCols = useViewModeStore((s) => s.setNumCols);
  const jot = useStore();
  const isFullscreen = useIsFullscreen()(jot);

  const chartDims = chartDimensionsConfig(
    viewMode === "grid"
      ? {
          isGridView: true,
          numCols,
        }
      : { isGridView: false }
  );
  return (
    <div className="@container rounded-lg bg-white mb-3 border border-xslate-6">
      <div className="flex flex-row mb-1 p-1.5 items-start">
        {/* this part of the header is duplicated in HeaderGutsARIA, which is shown in FS */}
        {!isFullscreen && (
          <div className="inline-flex items-center">
            <span className="text-md tracking-tight">
              {cluster.type === "dynamic" ? (
                <BsFillLightningChargeFill className="h-4 w-4 mr-1 inline" />
              ) : null}
              {cluster.name}
            </span>
            <Badge className="select-none ml-1 h-min" variant="secondary">
              {cluster.variables.length} Tags
            </Badge>
            <ClusterScoreBadge
              className="hidden @xl:inline-flex"
              score={cluster.score}
            />
            {cluster &&
              cluster.pairwise_score &&
              cluster.pairwise_score.length > 0 && (
                <>
                  <Tooltip label="Relationship Heatmap" withArrow>
                    <Button
                      className="btn-ghost text-xslate-11 hidden @xl:inline-flex"
                      onClick={() => setHeatmapOpen(true)}
                    >
                      <BsFillGrid3X3GapFill />
                    </Button>
                  </Tooltip>
                  <Dialog open={heatmapOpen} onOpenChange={setHeatmapOpen}>
                    <DialogContent className="min-w-fit">
                      <HeatMap scores={cluster.pairwise_score} />
                    </DialogContent>
                  </Dialog>
                </>
              )}
          </div>
        )}

        <ViewModeSelectors
          variant={"aria"}
          className="ml-auto hidden @xl:inline-flex"
          viewMode={viewMode}
          setViewMode={setViewMode}
          numCols={numCols}
          setNumCols={setNumCols}
        />
        <Link
          to={`${baseUrl}/pb?z=${urlParams.get("z")}&d=${urlParams.get("d")}&mo=${urlParams.get("mo")}&y=${urlParams.get("y")}&dv=${cluster.variables.map((vid) => variableObjects.find((v) => v._id === vid)?.short_id).join("%2C")}`}
          target="_blank"
        >
          <ExternalLink className="size-4 text-xslate-12 my-2.5 mr-4 ml-3 hidden @xl:inline-flex" />
        </Link>
      </div>
      {viewMode !== "chart" && (
        <div className="mr-12 ml-3 mb-1">
          <StatusSeries clusterId={cluster._id} domain={domain} />
        </div>
      )}
      <div
        className={`grid ${viewMode === "grid" ? `p-2 grid-cols-${numCols} gap-2` : "grid-cols-1 gap-2"}`}
      >
        {viewMode === "chart" && cluster.variables.length > 0 ? (
          <AriaSecondaryVariableViewProvider
            clusterId={cluster._id}
            initialVariables={cluster.variables as [string, ...string[]]}
            initialExpanded
          >
            <AriaSecondaryVariableViewNeedsProvider
              onLineClick={
                toggleFs && globalViewMode === "grid"
                  ? () =>
                      ({ jotaiStore: s }) => {
                        toggleFs(s);
                      }
                  : (numVars) => {
                      if (numVars < 2) return undefined;
                      return ({ bvOrId, clientX, clientY, jotaiStore }) => {
                        showLineClick({
                          clientX,
                          clientY,
                          jotaiStore,
                          bvOrId,
                        });
                      };
                    }
              }
              ableToGetTaller
              {...chartDims}
              padding={{
                left: 45,
              }}
            />
          </AriaSecondaryVariableViewProvider>
        ) : (
          cluster.variables.map((v, i) => {
            const color =
              colorGen.getN([], cluster.variables.length).at(i) || "#fff";
            const darkened = Color(color).darken(0.4).toString();
            return (
              <AriaSecondaryVariableViewProvider
                key={v}
                initialExpanded={false}
                clusterId={cluster._id}
                initialVariables={[v]}
              >
                <AriaSecondaryVariableViewNeedsProvider
                  className="border"
                  onLineClick={
                    toggleFs &&
                    (() =>
                      ({ jotaiStore: s }) => {
                        toggleFs(s);
                      })
                  }
                  {...chartDims}
                  padding={{
                    left: 45,
                  }}
                  primaryVariableColor={[color, darkened]}
                  numTicks={4}
                  ableToGetTaller
                  onlyMinMaxYAxes={viewMode === "grid"}
                />
              </AriaSecondaryVariableViewProvider>
            );
          })
        )}
      </div>
    </div>
  );
}
