import { atom, useAtomValue } from "jotai";
import { DateTime } from "luxon";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { YYYY_MM_DD } from "../../../lib/validators";

const chartDomainAsYYYY_MM_DD_ForFakeDatesAtom = atom((get) => {
  const chartDomain = get(Atoms.getDomainAtom);

  return (zone: string) => {
    const start = YYYY_MM_DD.parse(
      DateTime.fromMillis(chartDomain[0], { zone })
        .setZone("utc", { keepLocalTime: true })
        .toISODate()
    );
    const end = YYYY_MM_DD.parse(
      DateTime.fromMillis(chartDomain[1], { zone })
        .setZone("utc", { keepLocalTime: true })
        .toISODate()
    );

    return [start, end] as const;
  };
});

export const useChartDomainAsYYYY_MM_DD_ForFakeDates = () =>
  useAtomValue(chartDomainAsYYYY_MM_DD_ForFakeDatesAtom)(useTimezone());
