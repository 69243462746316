import { ReactNode } from "react";
import { BsFillGridFill } from "react-icons/bs";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shared-ui/frontend/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shared-ui/frontend/tooltip";

import { TbLayoutListFilled } from "react-icons/tb";

import { cn } from "../../lib/utils";
import { RiCheckboxMultipleBlankFill } from "react-icons/ri";
import { Tabs, TabsList, TabsTrigger } from "../../shared-ui/frontend/tabs";
import { PropsWithCn } from "../../shared-ui/frontend/cn";
import { cva, type VariantProps } from "class-variance-authority";
import { Button } from "../../shared-ui/frontend/button";
import { AiOutlineColumnWidth } from "react-icons/ai";

export type VIEW_MODE = "grid" | "list" | "chart";

const viewModeSelectorsVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 active:scale-95",
  {
    variants: {
      variant: {
        default:
          "data-[state=active]:bg-xindigo-3 data-[state=active]:text-xindigo-11 data-[state=active]:border-xindigo-7",
        aria: "data-[state=active]:bg-aria-3 data-[state=active]:text-aria-11 data-[state=active]:border-aria-7",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export function ViewModeSelectors<T extends VIEW_MODE[]>({
  viewMode,
  setViewMode,
  className,
  numCols,
  setNumCols,
  enabledModes,
  variant,
  withLabels,
}: {
  viewMode: VIEW_MODE;
  setViewMode: (mode: T[number]) => void;
  numCols?: number;
  setNumCols?: (numCols: number) => void;
  enabledModes?: T;
  withLabels?: boolean;
} & PropsWithCn<VariantProps<typeof viewModeSelectorsVariants>>) {
  const tabs: {
    value: VIEW_MODE;
    Icon: (props: PropsWithCn) => ReactNode;
    label: string;
  }[] = [
    {
      value: "list",
      Icon: TbLayoutListFilled,
      label: "List",
    },
    {
      value: "grid",
      Icon: BsFillGridFill,
      label: "Grid",
    },
    {
      value: "chart",
      Icon: RiCheckboxMultipleBlankFill,
      label: "Overlay",
    },
  ];

  const enabledModesArray = enabledModes || [];
  if (enabledModesArray.length === 1 && enabledModesArray[0] === "grid") {
    return (
      <div className={cn(className, "min-w-max")}>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"default"}
              size={"xs"}
              className={cn(
                withLabels ? "py-3.5" : "py-[10px]",
                variant === "aria" && "bg-aria-3 text-aria-11 border-aria-7"
              )}
            >
              <BsFillGridFill className="size-3.5 mr-2" />
              {numCols}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col p-1">
            {[2, 3, 4, 5].map((n) => (
              <Button
                key={n}
                variant={n === numCols ? "default" : "ghost"}
                size={"xs"}
                onClick={() => setNumCols?.(n)}
              >
                {n} column{`${n > 1 ? "s" : ""}`}
              </Button>
            ))}
          </PopoverContent>
        </Popover>
      </div>
    );
  }

  return (
    <Tabs
      className={cn(className, "min-w-max")}
      value={viewMode}
      onValueChange={(v) => setViewMode(v as VIEW_MODE)}
    >
      <TabsList className="h-9 rounded-md bg-white border-xslate-3">
        {tabs
          .filter((tab) => !enabledModes || enabledModes.includes(tab.value))
          .map((tab) => {
            const columnSelectorShown =
              setNumCols && viewMode === "grid" && tab.value === "grid";
            return (
              <Tooltip key={tab.value}>
                <TooltipTrigger asChild>
                  <div>
                    <TabsTrigger
                      key={tab.value}
                      value={tab.value}
                      className={cn(
                        "py-1.5 border border-transparent",
                        viewModeSelectorsVariants({ variant }),
                        columnSelectorShown && "rounded-r-none border-r-0"
                      )}
                    >
                      <tab.Icon
                        className={cn("size-3.5", withLabels && "mr-1")}
                      />
                      {withLabels && (
                        <span className="text-xs">{tab.label}</span>
                      )}
                    </TabsTrigger>
                    {columnSelectorShown && (
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={"default"}
                            size={"xs"}
                            className={cn(
                              "rounded-l-none relative bottom-[1.1px]",
                              withLabels ? "py-3.5" : "py-[10px]",
                              variant === "aria" &&
                                "bg-aria-3 text-aria-11 border-aria-7"
                            )}
                          >
                            {numCols}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="flex flex-col p-1">
                          {[2, 3, 4, 5].map((n) => (
                            <Button
                              key={n}
                              variant={n === numCols ? "default" : "ghost"}
                              size={"xs"}
                              onClick={() => setNumCols(n)}
                            >
                              {n} column{`${n > 1 ? "s" : ""}`}
                            </Button>
                          ))}
                        </PopoverContent>
                      </Popover>
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{tab.label} view</p>
                </TooltipContent>
              </Tooltip>
            );
          })}
      </TabsList>
    </Tabs>
  );
}
