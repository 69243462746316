import { create } from "zustand";
import { z } from "zod";

const defaultValue = false;

const key = "isMyFitnessLimitsOn";

const getLocalStorage = () => {
  const val = localStorage.getItem(key);
  if (!val) return defaultValue;

  try {
    return z.boolean().parse(JSON.parse(val));
  } catch {
    return defaultValue;
  }
};

const setLocalStorage = (on: boolean) => {
  localStorage.setItem(key, JSON.stringify(on));
};

type MyFitnessLimitsOnStore = {
  on: boolean;
  setOn: (on: boolean) => void;
};

/**
 * Restrict this on a type-level because calling the imperative
 * store functions can cirumvent the local-storage persistence.
 */
type SelectorFn<T> = (store: MyFitnessLimitsOnStore) => T;
type UseStore = <T>(x: SelectorFn<T>) => T;

export const useIsMyFitnessLimitsOnStore: UseStore =
  create<MyFitnessLimitsOnStore>()((set) => {
    return {
      on: getLocalStorage(),
      setOn: (on) => {
        setLocalStorage(on);
        set({ on });
      },
    };
  });
