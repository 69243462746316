import { FaCheck } from "react-icons/fa";
import { DoubleBadge } from "../../ui/double-badge";
import { FaXmark } from "react-icons/fa6";
import { ReactNode } from "react";

enum Application {
  DRA,
  ST,
  OF,
  VARIABILITY,
  FT,
  ARIA,
}

type ApplicationString = keyof typeof Application;

const applicationMap: { [A in ApplicationString]: A } = {
  DRA: "DRA",
  ST: "ST",
  OF: "OF",
  VARIABILITY: "VARIABILITY",
  FT: "FT",
  ARIA: "ARIA",
};

type SpecialtyReport_TODO_VERIFY_THIS = {
  extendedData: {
    formattedRange: string;
    formattedAnomalies: string;
    formattedOrder: string;
    formattedDelivery: string;
    formattedWatchlist: string;
    formattedDri: string;
    formattedFreshAnomalies: string;
    formattedTrendLength: string;
    formattedFitnessAnalysisType: string;
    formattedFitnessLevels: string;
    formattedFitnessSendWithNoVariables: string;
    formattedFitnessOnlyShowSelectedLevels: string;
    formattedChangeInVariability: string;
    formattedDriftCategory: string;
    formattedClusterType: string;
  };
  data: {
    application: ApplicationString;
    ft_summary: string;
    any_node_active: boolean;
    send_empty: boolean;
    includeRemaining: boolean;
  };
};

function DoubleBadgeWrapped({
  label,
  value,
}: {
  label: string;
  value: ReactNode;
}) {
  return (
    <DoubleBadge
      left={{
        children: label,
        variant: "slate",
      }}
      right={{
        variant: "outline",
        children: value,
      }}
    />
  );
}

export function BadgesForSpecialtyReportCard({
  report,
}: {
  report: SpecialtyReport_TODO_VERIFY_THIS;
}) {
  const {
    data: { application: app },
  } = report;

  return (
    <>
      <div className="w-full mb-1 flex gap-2">
        <DoubleBadgeWrapped
          label="Range"
          value={report.extendedData.formattedRange}
        />
        {app === applicationMap.DRA && (
          <DoubleBadgeWrapped
            label="Anomaly Category"
            value={report.extendedData.formattedAnomalies}
          />
        )}
        {app === applicationMap.FT ? (
          <>
            <DoubleBadgeWrapped
              label="Fault Tree Summary"
              value={<CheckOrX value={!!report.data.ft_summary} />}
            />
            <DoubleBadgeWrapped
              label="Show Fault Tree"
              value={
                report.data.any_node_active
                  ? "Any Node is Active"
                  : "Main Node is Active"
              }
            />
            <DoubleBadgeWrapped
              label="Send Report When Empty"
              value={<CheckOrX value={!!report.data.send_empty} />}
            />
          </>
        ) : (
          <DoubleBadgeWrapped
            label="Order"
            value={report.extendedData.formattedOrder}
          />
        )}
        <DoubleBadgeWrapped
          label="Delivery"
          value={report.extendedData.formattedDelivery}
        />
      </div>
      <div className="w-full mb-1 flex gap-2">
        {app === applicationMap.DRA && (
          <>
            <DoubleBadgeWrapped
              label="Watchlists"
              value={report.extendedData.formattedWatchlist}
            />
            <DoubleBadgeWrapped
              label="DRI"
              value={report.extendedData.formattedDri}
            />
          </>
        )}

        {report.data.includeRemaining && (
          <DoubleBadgeWrapped label="Remaining Variables" value={<Check />} />
        )}
        {app === applicationMap.DRA && (
          <DoubleBadgeWrapped
            label="Anomaly Type"
            value={report.extendedData.formattedFreshAnomalies}
          />
        )}
        {app === applicationMap.ST && (
          <DoubleBadgeWrapped
            label="Trends"
            value={report.extendedData.formattedTrendLength}
          />
        )}
        {app === applicationMap.OF && (
          <>
            <DoubleBadgeWrapped
              label="Analysis Type"
              value={report.extendedData.formattedFitnessAnalysisType}
            />
            <DoubleBadgeWrapped
              label="Level"
              value={report.extendedData.formattedFitnessLevels}
            />
            <DoubleBadgeWrapped
              label="Send Report When Empty"
              value={
                <CheckOrX
                  value={
                    !!report.extendedData.formattedFitnessSendWithNoVariables
                  }
                />
              }
            />
            <DoubleBadgeWrapped
              label="Limits on Trendcharts"
              value={report.extendedData.formattedFitnessOnlyShowSelectedLevels}
            />
          </>
        )}
        {app === applicationMap.VARIABILITY && (
          <DoubleBadgeWrapped
            label="Change In Variability"
            value={report.extendedData.formattedChangeInVariability}
          />
        )}
        {app === applicationMap.ARIA && (
          <>
            <DoubleBadgeWrapped
              label="Drift Category"
              value={report.extendedData.formattedDriftCategory}
            />
            <DoubleBadgeWrapped
              label="Cluster Type"
              value={report.extendedData.formattedClusterType}
            />
          </>
        )}
      </div>
    </>
  );
}

function Check() {
  return <FaCheck className="text-xgreen-9 h-3 w-3" />;
}

function X() {
  return <FaXmark className="text-xred-9 h-4 w-4" />;
}

function CheckOrX({ value }: { value: boolean }) {
  return value ? <Check /> : <X />;
}
