export const OPERATORS = {
  "+": "Addition",
  "-": "Subtraction",
  "*": "Multiplication",
  "/": "Division",
  "%": "Modulus",
  "|": "OR",
  "&": "AND",
  ">": "Greater than",
  "<": "Less than",
  ">=": "Greater than or equal to",
  "<=": "Less than or equal to",
  "==": "Equal to",
  "!=": "Not equal to",
};

type Functions = {
  [functionName: string]: {
    argTypes: string[];
    description: string;
    // todo we should make sure all functions have an example and signature
    example?: string;
    signature?: string;
  };
};
export const FUNCTIONS: Functions = {
  // date: {
  //   description: "date description",
  //   argTypes: ["date"],
  // },

  // string: {
  //   description: "string description",
  //   argTypes: ["string"],
  // },

  // pattern_match: {
  //   argTypes: ["tag", "date", "date"],
  //   signature: "pattern_match(variable, pattern start time, pattern end time)",
  //   example:
  //     "pattern_match(\"TI500.PV\", \"2022-11-25 17:55\", \"2022-11-25 18:05\")",
  //   description:
  //     "Calculates degree of match with respect to a specified (golden) pattern. Degree of match can be 0, 1, 2, where 0 indicates No Match, 1 indicates a Probable Match, and 2 indicates an Identical Match. ",
  // },

  // pattern_match_custom: {
  //   argTypes: ["tag", "date", "date", "number", "number"],
  //   signature:
  //     "pattern_match_custom(variable, pattern start time, pattern end time, probable match %, identical match %)",
  //   example:
  //     "pattern_match_custom(\"TI500.PV\", \"2022-11-25 17:55\", \"2022-11-25 18:05\", 75, 90)",
  //   description:
  //     "Calculates degree of match with respect to a specified (golden) pattern – based on percentage thresholds (for Probably Match and Identical Match) defined by the user. Degree of match can be 0, 1, 2, where 0 indicates No Match, 1 indicates a Probable Match, and 2 indicates an Identical Match.",
  // },

  // pattern_match_percent: {
  //   argTypes: ["tag", "date", "date"],
  //   signature:
  //     "pattern_match_percent(variable, pattern start time, pattern end time)",
  //   example:
  //     "pattern_match_percent(\"TI500.PV\", \"2022-11-25 17:55\", \"2022-11-25 18:05\")",
  //   description:
  //     "Calculates percentage of match with respect to a specified (golden) pattern. Ranges between 0 and 100. ",
  // },

  // pattern_match_height: {
  //   description: "pattern_match_height description",
  //   argTypes: ["tag", "date", "date"],
  // },

  abs: {
    argTypes: ["expression"],
    signature: "abs(expression)",
    example: 'abs("TI100.PV" – "TI200.PV")',
    description: "Calculates absolute value of an expression",
  },

  pow: {
    argTypes: ["expression", "number"],
    signature: "pow(expression, exponent)",
    example: 'pow("TI100.PV", 2)',
    description: "Variable or expression raised to a power",
  },

  min: {
    argTypes: ["expression", "expression..."],
    signature: "min(variable1, variable2, ...)",
    example: 'min("TI100.PV", "TI200.PV", "TI300.PV")',
    description: "Calculates minimum value across multiple variables",
  },

  max: {
    argTypes: ["expression", "expression..."],
    signature: "max(variable1, variable2, ...)",
    example: 'max("TI100.PV", "TI200.PV", "TI300.PV")',
    description: "Calculates maximum value across multiple variables",
  },

  mean: {
    argTypes: ["expression", "expression..."],
    signature: "mean(variable1, variable2, ...)",
    example: 'mean("TI100.PV", "TI200.PV", "TI300.PV")',
    description: "Calculates mean value across multiple variables",
  },

  median: {
    argTypes: ["expression", "expression..."],
    signature: "median(variable1, variable2, ...)",
    example: 'median("TI100.PV", "TI200.PV", "TI300.PV")',
    description: "Calculates median value across multiple variables",
  },

  dxdt: {
    argTypes: ["expression"],
    signature: "dxdt(expression)",
    example: 'dxdt("TI100.PV")',
    description:
      "Calculates first order derivative for a variable or expression",
  },

  log: {
    argTypes: ["expression"],
    signature: "log(expression)",
    example: 'log("5TI100.PV")',
    description:
      "Calculates base 10 logarithmic value of a variable or expression",
  },

  ln: {
    argTypes: ["expression"],
    signature: "ln(expression)",
    example: 'ln("5TI100.PV")',
    description:
      "Calculates natural logarithmic value of a variable or expression",
  },

  exp: {
    argTypes: ["expression"],
    signature: "exp(expression)",
    example: 'exp("5TI100.PV")',
    description: "Calculates exponential value of a variable or expression",
  },

  // square: {
  //   description: "square description",
  //   argTypes: ["number"],
  // },

  rateofchange: {
    argTypes: ["expression", "number"],
    signature: "rateofchange(variable, time period in minutes)",
    example: 'rateofchange("TI100.PV", 15)',
    description:
      "Calculates rate of change for a variable or expression over a time period",
  },

  lag: {
    argTypes: ["expression", "number"],
    signature: "lag(variable, time period in minutes)",
    example: 'lag("TI100.PV", 15)',
    description: "Calculates lagged value of variable or expression",
  },

  // filter: {
  //   description: "filter description",
  //   argTypes: ["expression", "expression"],
  // },

  // log_normal_prob: {
  //   description: "log_normal_prob description",
  //   argTypes: ["expression", "number"],
  // },

  periodicity: {
    argTypes: ["expression", "number"],
    signature:
      "periodicity(event activation expression, snooze time period in hours)",
    example: 'periodicity("TI100.PV" > 100, 1)',
    description:
      "First identifies when an event occurs based on event activation expression and then reports the time difference (in hours) between consecutive events. Snooze time period consolidates the events that are within the specified time duration. ",
  },

  // frequency: {
  //   description: "frequency description",
  //   argTypes: ["expression", "number"],
  // },

  rolling_rms: {
    argTypes: ["expression", "number"],
    signature: "rolling_rms(variable, time period in hours)",
    example: 'rolling_rms("A1.PV", 1)',
    description:
      "Calculates root mean square value of a variable (or expression) over a rolling time period",
  },

  rolling_gradient: {
    argTypes: ["expression", "number"],
    signature: "rolling_gradient(variable, time period in hours)",
    example: 'rolling_gradient("A1.PV", 1)',
    description:
      "Calculates gradient value of the best fit line for a variable over a rolling time period",
  },

  rolling_std: {
    argTypes: ["expression", "number"],
    signature: "rolling_std(variable, time period in hours)",
    example: 'rolling_std("A1.PV", 1.5)',
    description:
      "Calculates standard deviation of a variable (or expression) over a rolling time period",
  },

  rolling_mean: {
    argTypes: ["expression", "number"],
    signature: "rolling_mean(variable, time period in hours)",
    example: 'rolling_mean("AI10.PV", 1)',
    description:
      "Calculates mean of a variable (or expression) over a rolling time period",
  },

  rolling_max: {
    argTypes: ["expression", "number"],
    signature: "rolling_max(variable, time period in hours)",
    example: 'rolling_max("AI10.PV", 0.25)',
    description:
      "Calculates maximum value of a variable (or expression) over a rolling time period",
  },

  rolling_min: {
    argTypes: ["expression", "number"],
    signature: "rolling_min(variable, time period in hours)",
    example: 'rolling_min("AI10.PV", 0.25)',
    description:
      "Calculates minimum value of a variable (or expression) over a rolling time period",
  },

  rolling_median: {
    argTypes: ["expression", "number"],
    signature: "rolling_median(variable, time period in hours)",
    example: 'rolling_median("A1.PV", 1.5)',
    description:
      "Calculates median of a variable (or expression) over a rolling time period",
  },

  rolling_corr: {
    argTypes: ["expression", "expression", "number"],
    signature: "rolling_corr(variable1, variable2, time period in hours)",
    example: 'rolling_corr("AI10.PV", "AI20.PV", 12)',
    description:
      "Calculates Pearson correlation between two variables over a rolling time period",
  },

  rolling_percentile: {
    argTypes: ["expression", "number", "number"],
    signature:
      "rolling_percentile(variable, percentile value between 0 and 100, time period in hours)",
    example: 'rolling_percentile("A1.PV", 75, 1)',
    description:
      "Calculates percentile value of a variable (or expression) over a rolling time period",
  },

  ceiling: {
    argTypes: ["expression"],
    signature: "ceiling(expression)",
    example: 'ceiling("5TI100.PV" – "5TI200.PV")',
    description: "Rounds the expression to the next integer value",
  },

  floor: {
    argTypes: ["expression"],
    signature: "floor(expression)",
    example: 'floor("5TI100.PV" – "5TI200.PV")',
    description: "Rounds the expression to the previous integer value",
  },

  round: {
    argTypes: ["expression"],
    signature: "round(expression)",
    example: 'round("5TI100.PV")',
    description: "Rounds the expression to the nearest integer value",
  },

  mean_abs_dev: {
    argTypes: ["expression", "expression..."],
    signature: "mean_abs_dev(variable1, variable 2, ...)",
    example: 'mean_abs_dev("AI10.PV", "AI20.PV", "AI30.PV")',
    description:
      "Calculates mean of absolute deviations between two or more variables",
  },

  max_abs_dev: {
    argTypes: ["expression", "expression..."],
    signature: "max_abs_dev(variable1, variable 2, ...)",
    example: 'max_abs_dev("AI10.PV", "AI20.PV", "AI30.PV")',
    description:
      "Calculates maximum of absolute deviations between two or more variables",
  },

  if: {
    argTypes: ["expression", "expression|number", "expression|number"],
    signature:
      "if(expression, value when expression is True, value when expression is False)",
    example: 'if("TI100.PV" > 300, 300, "TI100.PV")',
    description:
      "Evaluates IF statement and assigns values based on when the expression is True or False",
  },

  // strend_level: {
  //   argTypes: ["tag"],
  //   signature: "strend_level(variable)",
  //   example: "strend_level(\"TI100.PV\")",
  //   description:
  //     "Calculates level of sloping trend for a variable (0: no trend, 1: short-term, 2: medium-trend, 3: long-trend)",
  // },

  // strend_gradient: {
  //   argTypes: ["tag"],
  //   signature: "strend_gradient(variable)",
  //   example: "strend_gradient(\"TI100.PV\")",
  //   description:
  //     "Calculates gradient of sloping trend for a variable (time range measured in days)",
  // },

  // strend_length: {
  //   argTypes: ["tag"],
  //   signature: "strend_length(variable)",
  //   example: "strend_length(\"TI100.PV\")",
  //   description: "Calculates length of sloping trend (in days)",
  // },

  // prob_h1: {
  //   argTypes: ["tag"],
  //   signature:
  //     "prob_h1(variable), prob_l1(variable), ..., prob_h5(variable), prob_l5(variable)",
  //   example: "prob_h1(\"TI100.PV\")",
  //   description:
  //     "Calculates a variable’s predictive probability of crossing an operating limit (pre-defined) in the next 7 days",
  // },

  // prob_l1: {
  //   description: "prob_l1 description",
  //   argTypes: ["tag"],
  // },

  // prob_h2: {
  //   description: "prob_h2 description",
  //   argTypes: ["tag"],
  // },

  // prob_l2: {
  //   description: "prob_l2 description",
  //   argTypes: ["tag"],
  // },

  // prob_h3: {
  //   description: "prob_h3 description",
  //   argTypes: ["tag"],
  // },

  // prob_l3: {
  //   description: "prob_l3 description",
  //   argTypes: ["tag"],
  // },

  // prob_h4: {
  //   description: "prob_h4 description",
  //   argTypes: ["tag"],
  // },

  // prob_l4: {
  //   description: "prob_l4 description",
  //   argTypes: ["tag"],
  // },

  // prob_h5: {
  //   description: "prob_h5 description",
  //   argTypes: ["tag"],
  // },

  // prob_l5: {
  //   description: "prob_l5 description",
  //   argTypes: ["tag"],
  // },

  time: {
    signature: "time(start time, end time)",
    description:
      "Creates a boolean step function which is true from start time until end time and false for other times",
    example: "time('2024-04-25 06:00', '2024-04-25 13:30')",
    argTypes: ["date", "date"],
  },

  // exceeded_h1: {
  //   description: "exceeded_h1 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_l1: {
  //   description: "exceeded_l1 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_h2: {
  //   description: "exceeded_h2 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_l2: {
  //   description: "exceeded_l2 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_h3: {
  //   description: "exceeded_h3 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_l3: {
  //   description: "exceeded_l3 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_h4: {
  //   description: "exceeded_h4 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_l4: {
  //   description: "exceeded_l4 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_h5: {
  //   description: "exceeded_h5 description",
  //   argTypes: ["tag"],
  // },

  // exceeded_l5: {
  //   description: "exceeded_l5 description",
  //   argTypes: ["tag"],
  // },

  duration: {
    example: 'duration("TI100.PV" > 300)',
    argTypes: ["expression"],
    description:
      "Starts a timer and calculates the duration (in minutes) while the expression is True",
    signature: "duration(expression)",
  },
  duration_event: {
    example: 'duration_event("TI100.PV" > 300, "TI300.PV" < 100)',
    argTypes: ["expression", "expression"],
    description:
      "Starts a timer when the start expression is True, calculates the duration (in minutes), and stops when the end expression is True",
    signature: "duration_event(start expression, end expression)",
  },
  duration_with_pause: {
    example:
      'duration_with_pause("TI100.PV" > 300, "TI300.PV" < 100, "FI100.PV" < 5)',
    argTypes: ["expression", "expression", "expression"],
    description:
      "Starts a timer when the start expression is True, calculates the duration (in minutes), pauses the timer when pause expression is True, and stops when the end expression is True",
    signature:
      "duration_with_pause(start expression, end expression, pause expression)",
  },
  event: {
    example: 'event("TI100.PV" > 300, "TI300.PV" < 100)',
    argTypes: ["expression", "expression"],
    description:
      "Starts a timer (and reports a value of 1) when the start expression is True, and stops (and reports a value of 0) when the end expression is True",
    signature: "event(start expression, end expression)",
  },
  event_totaltime: {
    example: 'event_totaltime("TI100.PV" > 300, "TI300.PV" < 100)',
    argTypes: ["expression", "expression"],
    description:
      "Starts a timer when the start expression is True, and stops when the end expression is True. Updates after the event has ended",
    signature: "event_totaltime(start expression, end expression)",
  },
  event_max: {
    example: 'event_max("TI100.PV" > 300, "TI300.PV" < 100, "TI200.PV")',
    argTypes: ["expression", "expression", "expression"],
    description:
      "Finds the maximum value of the value expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended",
    signature: "event_max(start expression, end expression, value expression)",
  },
  event_min: {
    example: 'event_min("TI100.PV" > 300, "TI300.PV" < 100, "TI200.PV")',
    argTypes: ["expression", "expression", "expression"],
    description:
      "Finds the minimum value of the value expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended",
    signature: "event_min(start expression, end expression, value expression)",
  },
  event_mean: {
    example: 'event_mean("TI100.PV" > 300, "TI300.PV" < 100, "TI200.PV")',
    argTypes: ["expression", "expression", "expression"],
    description:
      "Finds the mean value of the value expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended",
    signature: "event_mean(start expression, end expression, value expression)",
  },
  event_median: {
    example: 'event_median("TI100.PV" > 300, "TI300.PV" < 100, "TI200.PV")',
    argTypes: ["expression", "expression", "expression"],
    description:
      "Finds the median value of the value expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended",
    signature:
      "event_median(start expression, end expression, value expression)",
  },
  event_std: {
    example: 'event_std("TI100.PV" > 300, "TI300.PV" < 100, "TI200.PV")',
    argTypes: ["expression", "expression", "expression"],
    description:
      "Finds the standard deviation of the value expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended",
    signature: "event_std(start expression, end expression, value expression)",
  },
  gaussian_noise: {
    example: '"73FC303.SP" + gaussian_noise(0, 1)',
    argTypes: ["number", "number"],
    description:
      "Calculates gaussian noise or random fluctuations, which can be added to a variable or expression",
    signature: "gaussian_noise(mean, standard deviation)",
  },
};

export const SLOW_FUNCTIONS: Functions = {
  da_hi: {
    argTypes: ["tag"],
    signature: "da_hi(variable)",
    example: 'da_hi("TI100.PV")',
    description: "Calculates degree of anomaly (high side) for a variable",
  },

  da_lo: {
    argTypes: ["tag"],
    signature: "da_lo(variable)",
    example: 'da_lo("TI100.PV")',
    description: "Calculates degree of anomaly (low side) for a variable",
  },

  da_sf: {
    argTypes: ["tag"],
    signature: "da_sf(variable)",
    example: 'da_sf("TI100.PV")',
    description:
      "Calculates degree of anomaly (sensor flatline) for a variable",
  },

  da: {
    argTypes: ["tag"],
    signature: "da(variable)",
    example: 'da("TI100.PV")',
    description: "Calculates degree of anomaly for a variable",
  },
};
