import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { createStore } from "zustand";

function createShutdownDrawerStore() {
  return createStore<{
    shutdownId: string | undefined;
    setShutdownId: (shutdownId: string | undefined) => void;
  }>((set) => ({
    shutdownId: undefined,
    setShutdownId: (shutdownId) => set({ shutdownId }),
  }));
}

type ShutdownDrawerStore = ReturnType<typeof createShutdownDrawerStore>;

const ShutdownDrawerStoreContext = createContext<
  ShutdownDrawerStore | undefined
>(undefined);

function ShutdownDrawerStoreProvider({ children }: PropsWithChildren) {
  const [store] = useState(createShutdownDrawerStore);
  return (
    <ShutdownDrawerStoreContext.Provider value={store}>
      {children}
    </ShutdownDrawerStoreContext.Provider>
  );
}

function useShutdownDrawerStoreNotRequired() {
  return useContext(ShutdownDrawerStoreContext);
}

function useShutdownDrawerStore() {
  const store = useContext(ShutdownDrawerStoreContext);

  if (!store) {
    throw new Error(
      "useShutdownStore must be used within a ShutdownStoreProvider"
    );
  }

  return store;
}

export {
  ShutdownDrawerStoreProvider,
  useShutdownDrawerStore,
  useShutdownDrawerStoreNotRequired,
};
