import {
  type ClickedLineData,
  useGetUseTimeseriesChartsTooltipStore,
} from "./use-global-timeseries-tooltip-and-clicked-line-store";

import {
  Dialog,
  DialogInternalContent,
  DialogOverlay,
  DialogPortal,
} from "../../frontend/dialog";
import { ID_FOR_CLICKED_LINE_OVERLAY } from "../timeseries-chart";
import { Button } from "../../frontend/button";
import { X } from "lucide-react";
import { type ReactNode } from "react";
import { Atoms } from "../svv-store/use-svv-store";
import { trendDataMatches } from "../draw/draw";

function GlobalControlButtonsForClickedLine({
  children,
}: {
  children: (
    onClosePopupDialog: () => void,
    clickedLineData: ClickedLineData
  ) => ReactNode;
}) {
  const useStore = useGetUseTimeseriesChartsTooltipStore();

  if (!useStore)
    throw new Error(
      "useStore is undefined. Only render this component inside a TimeseriesChartTooltipStoreProvider."
    );

  const clickedLineData = useStore((s) => s.clickedLine);

  const handleClosePopupDialog = () => {
    useStore.getState().removeClickedLineData();
    clickedLineData?.jotaiStore.get(Atoms.redrawCanvasFnAtom)?.(undefined);
  };

  // todo: should not be getting this synchronously
  const color =
    clickedLineData &&
    clickedLineData.jotaiStore.get(Atoms.selectedVariablesAtom)?.find((x) => {
      switch (x.type) {
        case "expression":
          return x.id === clickedLineData.bvOrId;
        case "variable":
          return x.bv === clickedLineData.bvOrId;
        default:
          const _: never = x;
          throw new Error("impossible");
      }
    })?.color;

  return (
    <Dialog
      open={!!clickedLineData}
      onOpenChange={(isOpen) => {
        !isOpen && handleClosePopupDialog();
      }}
    >
      <DialogPortal>
        <DialogOverlay
          id={ID_FOR_CLICKED_LINE_OVERLAY}
          className="bg-transparent backdrop-blur-none"
        />
        <DialogInternalContent
          withoutClose
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
          className="fixed z-[300] flex w-auto max-w-max gap-2 border border-xslate-7 bg-white p-1 text-xslate-11 hover:border-xslate-8 dark:bg-xslate-3"
          style={
            clickedLineData && {
              borderColor: color,
              left: `${clickedLineData.clientX}px`, // top left of this component will be where the pointer is
              top: `${clickedLineData.clientY}px`,
              transform: "translate(-50%, -130%)", // then center it (-50) (changes from top left to top middle) and then put it on top of the pointer (-130)
            }
          }
        >
          {clickedLineData && (
            <>
              {children(handleClosePopupDialog, clickedLineData)}
              <Button
                className="absolute -right-2 -top-2 h-4 w-4"
                size="icon"
                variant={"destructive"}
                onClick={handleClosePopupDialog}
              >
                <X className="size-2" />
              </Button>
            </>
          )}
        </DialogInternalContent>
      </DialogPortal>
    </Dialog>
  );
}

export { GlobalControlButtonsForClickedLine };
