import { v4 } from "uuid";
import { create } from "zustand";
import { POST } from "../frameworks/fetcher/fetcher-experimental";

enum EventType {
  FOCUS,
  BLUR,
}

type Event = {
  type: EventType;
  createdAt: Date;
};

type AnalyticsStore = {
  events: [Event, ...Event[]];
  addFocusEvent: () => void;
  addBlurEvent: () => void;
  persist: () => void;
};

/**
 * Use this to discern if they are jsut switching tabs or if they are
 * actually leaving the site, closing the tab, etc.
 */
const uniqueIdPerBrowserSession = v4();

export const createUseAnalyticsStore = () =>
  create<AnalyticsStore>()((set, get) => {
    const lastEventType = (curr: Event[]): EventType | undefined => {
      const last = curr[curr.length - 1];
      return last?.type;
    };

    const addFocusEvent = () => {
      const currEvents = get().events;
      if (lastEventType(currEvents) === EventType.FOCUS)
        throw new Error("already focus event");

      set({
        events: [
          ...currEvents,
          { createdAt: new Date(), type: EventType.FOCUS },
        ],
      });
    };

    const addBlurEvent = () => {
      const currEvents = get().events;
      if (!currEvents.length) throw new Error("must be focus event first");

      if (lastEventType(currEvents) !== EventType.FOCUS)
        throw new Error("must be focus event first");

      set({
        events: [
          ...currEvents,
          { createdAt: new Date(), type: EventType.BLUR },
        ],
      });
    };

    return {
      events: [{ createdAt: new Date(), type: EventType.FOCUS }],
      addFocusEvent,
      addBlurEvent,

      persist: async () => {
        const currEvents = get().events;
        if (currEvents.length < 1) throw new Error("should be 1 or more");

        try {
          await POST("/analytics", {
            id: uniqueIdPerBrowserSession,
            events: currEvents,
          });
        } catch (e) {
          // silently ignore is ok
          console.error("could not persist analytics", e);
        }
      },
    };
  });

export type UseAnalyticsStore = ReturnType<typeof createUseAnalyticsStore>;
