import { Check, Plus } from "lucide-react";
import {
  Acknowledgements,
  PersonalFoldersQuery,
  useAcknowledgementsQuery,
  useAriaClustersQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { Button } from "../../../shared-ui/frontend/button";
import { Input } from "../../../shared-ui/frontend/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import * as HeaderComponents from "../../../shared-ui/time-series-2/secondary-variable-view/header";
import { VariableInfoTooltip } from "../../charts/DTC/variable-info-tooltip";
import { WatchlistFlagButton } from "../../charts/DTC/watchlist-flag-button";
import { useMutation } from "@tanstack/react-query";
import {
  postAcknowledgement,
  PostAckSchema,
  postFolder,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { memo, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { atom, useAtom, useAtomValue, useSetAtom, useStore } from "jotai";
import {
  Atoms,
  ChartVariant,
  isVariableTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  cn,
  PropsWithCnAndChildren,
  type PropsWithCn,
} from "../../../shared-ui/frontend/cn";
import {
  FaChartLine,
  FaCheck,
  FaExclamationTriangle,
  FaSearchPlus,
} from "react-icons/fa";
import { CorrelatedTagsButton, useExitSlopingTrends } from "./bottom-controls";
import { Badge } from "../../../shared-ui/frontend/badge";
import { VariabilityStatisticsPopover } from "./control-buttons";
import { assertMinLen1, iife } from "../../../shared-ui/lib/utils";
import { useGetUseProfileBookStoreNotRequired } from "../../pb/use-profile-book-store";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { YYYY_MM_DD } from "../../../lib/validators";
import { DateTime } from "luxon";
import { minutesToMilliseconds } from "date-fns";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { MdExpandMore } from "react-icons/md";
import { TimeFormat } from "../../../shared-ui/lib/date-utils";
import { acknowledgementSchema } from "../../../lib/api-schema/acknowledgment";
import { DynamicStatusSeries } from "../../aria/details/status-series";
import * as HeaderPrimitive from "../../../shared-ui/time-series-2/secondary-variable-view/header";
import { useDateState } from "../../../zustand/useDateState";
import {
  BsFillGrid3X3GapFill,
  BsFillLightningChargeFill,
} from "react-icons/bs";
import { ClusterScoreBadge } from "../../aria/cluster-score-badge";
import { Dialog, DialogContent } from "../../../shared-ui/frontend/dialog";
import { HeatMap } from "../../clusters/manager/HeatMap";
import { Tooltip as MantineTooltip } from "@mantine/core";
import { useIsFullscreen } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";

const numExpressionTrendLinesAtom = atom(
  (get) => get(Atoms.onlyExpressionTrendLinesAtom).length
);

const numVariableTrendLinesAtom = atom(
  (get) => get(Atoms.onlyVariableTrendLinesAtom).length
);

export function HeaderGuts({
  onClose,
  expandable,
}: {
  onClose?: () => void;
  expandable?: boolean;
}) {
  const numExprs = useAtomValue(numExpressionTrendLinesAtom);
  const numVars = useAtomValue(numVariableTrendLinesAtom);
  const totalTrendLines = numExprs + numVars;

  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  const variablesQuery = useVariablesArrayQuery();

  const primaryVariableObj = useMemo(() => {
    if (primaryTrendLine.type !== "variable") return;
    if (!variablesQuery.data) return;

    return variablesQuery.data.find(
      (x) => x._id === primaryTrendLine.bv.slice(24)
    );
  }, [variablesQuery.data, primaryTrendLine]);

  const isARIA = useAtomValue(Atoms.chartVariantAtom) === ChartVariant.Aria;

  const daDisableAtom = useAtomValue(Atoms.daBarsAtomAtom);

  const showExpandButton = expandable && totalTrendLines === 1;

  const isOperatingLimitsChart = useAtomValue(Atoms.chartVariantAtom) === "ol";
  const isInstantCalc = useAtomValue(Atoms.chartVariantAtom) === "ic";

  return (
    <>
      <WatchlistButton className="hidden @xl:inline-flex" />
      <HeaderComponents.SidebarButton />
      {primaryVariableObj && (
        <HeaderComponents.SingleVariableTitle
          name={primaryVariableObj.trimmedName}
          description={primaryVariableObj.description}
          uom={primaryVariableObj.units_of_measurement ?? undefined}
        />
      )}
      {primaryTrendLine.type === "variable" && totalTrendLines === 1 ? (
        <>
          <VariableInfoTooltip
            variableId={primaryTrendLine.bv.slice(24)}
            className="hidden @xl:inline-flex"
          />
          <VariabilityStatisticsPopover
            variableId={primaryTrendLine.bv.slice(24)}
            color={primaryTrendLine.color}
            className="hidden @xl:inline-flex"
          />
        </>
      ) : (
        !isARIA &&
        numVars > 0 && (
          <Badge className="select-none" variant={"secondary"}>
            {numVars} Tags
          </Badge>
        )
      )}

      {showExpandButton && (
        <HeaderComponents.ExpandButton className="hidden @xl:inline-flex" />
      )}
      <div className="inline-flex ml-auto gap-2 items-center">
        <LowHighSideLabel
          className={"@lg:mr-3 text-[0.6rem] leading-[0.8rem] @lg:text-xs"}
        />
        <CloseSlopingTrendsButton />
        <HeaderComponents.YAxisModeTabs />
        {daDisableAtom && (
          <>
            <DaPatchesToggleButton>
              {isARIA ? "Drift Patches" : "Anomaly Patches"}
            </DaPatchesToggleButton>
            {!isARIA && <DaBarsToggleButton />}
          </>
        )}
        {/* {!isARIA && <SaveMultiviewButton />} */}
        <HeaderComponents.SpotlightToggle />
        {!isOperatingLimitsChart && !isInstantCalc && <ChartAckButton />}
        <CorrelatedTagsButton size={"icon-xs"} className={"@xl:hidden"} />
        <HeaderComponents.FullscreenButton />
        {onClose && <HeaderComponents.CloseButton onClick={onClose} />}
      </div>
    </>
  );
}

export function HeaderGutsARIA({
  onClose,
  expandable,
}: {
  onClose?: () => void;
  expandable?: boolean;
}) {
  /**
   * exactly the same as HeaderGuts but instead of breakpoint being 3xl it's xl
   * also includes ARIA status series
   */
  const numExprs = useAtomValue(numExpressionTrendLinesAtom);
  const numVars = useAtomValue(numVariableTrendLinesAtom);
  const totalTrendLines = numExprs + numVars;

  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const selectedVariables = useAtomValue(Atoms.selectedVariablesAtom);
  const allBvs = selectedVariables
    .filter((x) => x.type === "variable")
    .map((x) => x.bv);
  const ariaVars = allBvs.filter((s) => s.slice(0, 24) !== "0".repeat(24));
  const hasMultipleARIAVariables = ariaVars.length > 1;
  const firstAriaVar = ariaVars[0];
  const clusterId = firstAriaVar ? firstAriaVar.slice(0, 24) : "";

  const variablesQuery = useVariablesArrayQuery();

  const primaryVariableObj = useMemo(() => {
    if (primaryTrendLine.type !== "variable") return;
    if (!variablesQuery.data) return;

    return variablesQuery.data.find(
      (x) => x._id === primaryTrendLine.bv.slice(24)
    );
  }, [variablesQuery.data, primaryTrendLine]);

  const isARIA = true; //useAtomValue(Atoms.chartVariantAtom) === ChartVariant.Aria;

  const daDisableAtom = useAtomValue(Atoms.daBarsAtomAtom);

  const showExpandButton = expandable && totalTrendLines === 1;

  const isOperatingLimitsChart = useAtomValue(Atoms.chartVariantAtom) === "ol";
  const isInstantCalc = useAtomValue(Atoms.chartVariantAtom) === "ic";

  // only need 1 cluster, but this query should already be cached by src/components/aria/details/cluster-charts.tsx
  const $ds = useDateState();
  const clustersQuery = useAriaClustersQuery({
    at: $ds.axisRangeTo.local,
    filterType: "dynamic",
  });
  const cluster = clustersQuery.data?.find((x) => x._id === clusterId);
  const [heatmapOpen, setHeatmapOpen] = useState(false);
  const jot = useStore();
  const isFullscreen = useIsFullscreen()(jot);

  return (
    <div className="flex flex-col w-full">
      <HeaderPrimitive.Container className="gap-0 @lg:gap-2">
        <WatchlistButton className="hidden @xl:inline-flex" />
        <HeaderComponents.SidebarButton className="@3xl:inline-flex" />
        {primaryVariableObj && (
          <HeaderComponents.SingleVariableTitle
            name={primaryVariableObj.trimmedName}
            description={primaryVariableObj.description}
            uom={primaryVariableObj.units_of_measurement ?? undefined}
          />
        )}
        {primaryTrendLine.type === "variable" && totalTrendLines === 1 ? (
          <>
            <VariableInfoTooltip
              variableId={primaryTrendLine.bv.slice(24)}
              className="hidden @xl:inline-flex"
            />
            <VariabilityStatisticsPopover
              variableId={primaryTrendLine.bv.slice(24)}
              color={primaryTrendLine.color}
              className="hidden @xl:inline-flex"
            />
          </>
        ) : (
          !isARIA &&
          numVars > 0 && (
            <Badge className="select-none" variant={"secondary"}>
              {numVars} Tags
            </Badge>
          )
        )}

        {showExpandButton && (
          <HeaderComponents.ExpandButton className="hidden @xl:inline-flex" />
        )}

        {/* this part of the header duplicated from ClusterCard in src/components/aria/details/cluster-charts.tsx */}
        {/* the version in ClusterCard isn't shown in fullscreen, this is (when viewing a cluster) */}
        {isFullscreen && hasMultipleARIAVariables && (
          <div className="inline-flex items-center">
            <span className="text-md tracking-tight">
              {cluster?.type === "dynamic" ? (
                <BsFillLightningChargeFill className="h-4 w-4 mr-1 inline" />
              ) : null}
              {cluster?.name}
            </span>
            <Badge className="select-none ml-1 h-min" variant="secondary">
              {cluster?.variables.length} Tags
            </Badge>
            <ClusterScoreBadge
              className="hidden @xl:inline-flex"
              score={cluster?.score}
            />
            {cluster &&
              cluster.pairwise_score &&
              cluster.pairwise_score.length > 0 && (
                <>
                  <MantineTooltip label="Relationship Heatmap" withArrow>
                    <Button
                      variant={"ghost"}
                      className="text-xslate-11 hidden @xl:inline-flex"
                      onClick={() => setHeatmapOpen(true)}
                    >
                      <BsFillGrid3X3GapFill />
                    </Button>
                  </MantineTooltip>
                  <Dialog open={heatmapOpen} onOpenChange={setHeatmapOpen}>
                    <DialogContent className="min-w-fit">
                      <HeatMap scores={cluster.pairwise_score} />
                    </DialogContent>
                  </Dialog>
                </>
              )}
          </div>
        )}

        <div className="inline-flex ml-auto gap-2 items-center">
          <LowHighSideLabel
            className={"@lg:mr-3 text-[0.6rem] leading-[0.8rem] @lg:text-xs"}
          />
          <CloseSlopingTrendsButton />
          <HeaderComponents.YAxisModeTabs className="@xl:inline-flex" />
          {daDisableAtom && (
            <>
              <DaPatchesToggleButton className="@xl:inline-flex">
                {isARIA ? "Drift Patches" : "Anomaly Patches"}
              </DaPatchesToggleButton>
              {!isARIA && <DaBarsToggleButton />}
            </>
          )}
          {/* {!isARIA && <SaveMultiviewButton />} */}
          <HeaderComponents.SpotlightToggle className="@xl:inline-flex" />
          {!isOperatingLimitsChart && !isInstantCalc && <ChartAckButton />}
          <CorrelatedTagsButton size={"icon-xs"} className={"@xl:hidden"} />
          <HeaderComponents.FullscreenButton />
          {onClose && <HeaderComponents.CloseButton onClick={onClose} />}
        </div>
      </HeaderPrimitive.Container>

      {hasMultipleARIAVariables && (
        <div className="mt-2 mr-6 @2xl:mr-12 ml-1 @2xl:ml-3">
          <DynamicStatusSeries clusterId={clusterId} />
        </div>
      )}
    </div>
  );
}

const domainEndAtom = atom((g) => g(Atoms.getDomainAtom)[1]);

export function AckButton({
  acks,
  variable,
  selectedDate,
  className,
  onSuccess,
}: {
  acks: acknowledgementSchema[];
  variable: string;
  selectedDate: YYYY_MM_DD;
  onSuccess?: () => void;
} & PropsWithCn) {
  const [opened, setOpened] = useState(false);
  const mostRecentMaybe = acks[0];
  const isUnacked = !mostRecentMaybe || mostRecentMaybe.unacknowledgment;
  const invalidate = Acknowledgements.list.useInvalidate();
  const b = useBaseUrlExperimental();
  const mut = useMutation({
    mutationFn: ({
      unacknowledgment,
      type,
    }: { unacknowledgment: boolean } & Required<
      Pick<PostAckSchema, "type">
    >) => {
      return postAcknowledgement(b, {
        variable,
        date: selectedDate,
        unacknowledgment,
        type,
      });
    },
    onSuccess: () => {
      invalidate();
      if (onSuccess) onSuccess();
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });
  const zone = useTimezone();

  return (
    <div className="flex flex-row">
      <Tooltip>
        <TooltipContent>Acknowledge</TooltipContent>
        <Popover open={opened} onOpenChange={setOpened}>
          <PopoverTrigger asChild>
            <TooltipTrigger asChild>
              {iife(() => {
                if (isUnacked)
                  return (
                    <Button
                      size={"icon-xs"}
                      variant={"ghost"}
                      className={cn(className)}
                    >
                      <Check className="size-4" />
                    </Button>
                  );

                const Icon = iife(() => {
                  switch (mostRecentMaybe.type) {
                    case "issue":
                      return FaExclamationTriangle;
                    case "monitoring":
                      return FaSearchPlus;
                    default:
                      return Check;
                  }
                });

                return (
                  <Button
                    size={"xxs"}
                    variant={iife(() => {
                      if (isUnacked) return "ghost";
                      if (mostRecentMaybe.type === "issue")
                        return "destructive";
                      return "outline";
                    })}
                    className={cn(
                      "rounded-r-none",
                      mostRecentMaybe.type === "normal" &&
                        "text-indigo-500 focus:text-indigo-600 focus:bg-indigo-100 bg-indigo-100 border-indigo-500",
                      className
                    )}
                  >
                    <Icon className="size-3.5" />
                    {!isUnacked && (
                      <>
                        <span className="ml-1 whitespace-nowrap">
                          {mostRecentMaybe.author.first[0]}.&nbsp;
                        </span>
                        <span className="hidden @3xl:inline">
                          {mostRecentMaybe.author.last}
                        </span>
                        <span className="@3xl:hidden">
                          {mostRecentMaybe.author.last[0]}
                        </span>
                      </>
                    )}
                  </Button>
                );
              })}
            </TooltipTrigger>
          </PopoverTrigger>

          <PopoverContent className="p-1">
            <div className="flex flex-col">
              <Button
                size={"sm"}
                onClick={
                  mut.isLoading
                    ? undefined
                    : () => {
                        setOpened(false);
                        mut.mutate({
                          unacknowledgment: isUnacked
                            ? false
                            : mostRecentMaybe.type === "issue",
                          type: "issue",
                        });
                      }
                }
                className={cn(
                  "text-red-500 focus:text-red-600 hover:bg-red-100 py-2 px-4",
                  !isUnacked && mostRecentMaybe.type === "issue" && "bg-red-100"
                )}
                variant={"ghost"}
              >
                <FaExclamationTriangle className="size-4 mr-3" />
                Needs Attention
              </Button>
              <Button
                size={"sm"}
                onClick={
                  mut.isLoading
                    ? undefined
                    : () => {
                        setOpened(false);
                        mut.mutate({
                          unacknowledgment: isUnacked
                            ? false
                            : mostRecentMaybe.type === "normal",
                          type: "normal",
                        });
                      }
                }
                className={cn(
                  "text-indigo-500 focus:text-indigo-600 px-4 py-2 focus:bg-indigo-100",
                  !isUnacked &&
                    mostRecentMaybe.type !== "issue" &&
                    "bg-indigo-100"
                )}
                variant={"ghost"}
              >
                <Check className="size-4 mr-3" />
                Acknowledged
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </Tooltip>
      {!isUnacked && (
        <Popover>
          <PopoverTrigger asChild>
            <Button
              size={"xxs"}
              variant={iife(() => {
                if (isUnacked) return "ghost";
                if (mostRecentMaybe.type === "issue") return "destructive";
                return "outline";
              })}
              className={cn(
                "rounded-l-none border-l-0 p-1",
                mostRecentMaybe?.type === "normal" &&
                  "text-indigo-500 focus:text-indigo-600 focus:bg-indigo-100 bg-indigo-100 border-indigo-500",
                className
              )}
            >
              <MdExpandMore className="size-3.5" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-72 max-h-[500px] overflow-scroll p-0">
            <span className="block pl-2 text-[0.9rem] py-1 text-gray-600">
              Acknowledgments:
            </span>
            <ul>
              {acks.map((ack) => (
                <li
                  key={ack._id}
                  className="border-bordgrey2 border-b pl-4 py-1 first-of-type:border-t text-xs"
                >
                  <div className="flex items-center">
                    <div className="flex-1">
                      <div className="text-gray-600">
                        {!ack.unacknowledgment ? (
                          !ack.type || ack.type === "normal" ? (
                            <FaCheck className="inline mr-1" />
                          ) : (
                            <FaExclamationTriangle className="inline mr-1" />
                          )
                        ) : null}
                        {ack.unacknowledgment
                          ? `Unacked by ${ack.author.first} ${ack.author.last}`
                          : `Acked by ${ack.author.first} ${ack.author.last}`}
                      </div>
                      <div className="text-gray-400">
                        {DateTime.fromISO(ack.created_at, {
                          zone: zone,
                        }).toFormat("dd-LLL-yyyy hh:mm a" satisfies TimeFormat)}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
}

export function ChartAckButton({ className }: PropsWithCn) {
  const primary = useAtomValue(Atoms.primaryBatchVariableAtom);

  const zone = useTimezone();

  const end = YYYY_MM_DD.parse(
    DateTime.fromMillis(useAtomValue(domainEndAtom), { zone }).toFormat(
      "yyyy-LL-dd"
    )
  );

  const primaryIsVariable = isVariableTrendLine(primary);
  if (!primaryIsVariable)
    throw new Error("This button should not be shown. Bug");

  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);
  const acksQuery = useAcknowledgementsQuery(
    {
      variableId: primaryIsVariable ? primary.bv.slice(24) : "dummy-not-used",
      start: end,
      end,
    },
    {
      enabled: canRequestData && primaryIsVariable,
      staleTime: minutesToMilliseconds(5),
    }
  );

  const single = useAtomValue(Atoms.singleVariableViewAtom);

  if (acksQuery.isLoading || acksQuery.isError)
    return (
      <Button variant={"ghost"} disabled className={cn(className)}>
        <Check className="size-4" />
      </Button>
    );

  if (!single || !primaryIsVariable) return;

  return (
    <AckButton
      acks={acksQuery.data}
      variable={primary.bv.slice(24)}
      selectedDate={end}
      className={className}
    />
  );
}

function CloseSlopingTrendsButton({ className }: PropsWithCn) {
  const isSlopingTrendsView = useAtomValue(Atoms.slopingTrendsAtom);
  const exit = useExitSlopingTrends();

  if (!isSlopingTrendsView) return;

  return (
    <Button
      variant={"outline"}
      size={"xxs"}
      onClick={exit}
      className={cn("hidden @3xl:inline-flex", className)}
    >
      <FaChartLine className="size-3 mr-2" />
      Close Sloping Trends
    </Button>
  );
}

function LowHighSideLabel({ className }: PropsWithCn) {
  const isSingleView = useAtomValue(Atoms.singleVariableViewAtom);
  const isSlopingTrendsView = useAtomValue(Atoms.slopingTrendsAtom);
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const variablesQuery = useVariablesArrayQuery();

  if (!isSingleView || !variablesQuery.data) return;

  if (primaryTrendLine.type !== "variable") return;

  const v = variablesQuery.data.find(
    (x) => x._id === primaryTrendLine.bv.slice(24)
  );

  if (!v) return;

  const getLabel = (label: string) => {
    return (
      <span className={cn("text-zinc-700 text-sm font-light", className)}>
        {label}{" "}
        <span className="text-red-600 uppercase font-semibold">OFF</span>
      </span>
    );
  };

  /**
   * If we're in sloping trends view, try to show the sloping trend label
   * first, if one exists.
   */
  if (isSlopingTrendsView) {
    const someSlopeIsOff = v.hide_negative_slopes || v.hide_positive_slopes;

    if (someSlopeIsOff) {
      const slopeLabel =
        [
          v.hide_positive_slopes && "Positive",
          v.hide_negative_slopes && "Negative",
        ]
          .filter(Boolean)
          .join(" & ") + " Slope:";

      return getLabel(slopeLabel);
    }
  }

  // Only show a label relating to anomalies if anomaly toggle is on
  const shouldShowAnomalyLabelIfTheyExist = primaryTrendLine.anom;
  if (!shouldShowAnomalyLabelIfTheyExist) return;

  /**
   * Even if we're in sloping trend view, but we didn't find any sloping
   * labels, we can still show the low/high side labels.
   */
  const someAnomalyIsOff = !v.low_side || !v.high_side;
  const nothingIsOff = !someAnomalyIsOff; // nothing to show
  if (nothingIsOff) return;

  const anomalyLabel =
    [!v.low_side && "Low-Side", !v.high_side && "High-Side"]
      .filter(Boolean)
      .join(" & ") + " Anomaly:";

  return getLabel(anomalyLabel);
}

function WatchlistButton({ className }: PropsWithCn) {
  const single = useAtomValue(Atoms.singleVariableViewAtom);

  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const variables = useVariablesArrayQuery().data;

  if (!single || primaryTrendLine.type !== "variable") return;

  const variable = variables?.find(
    (x) => x._id === primaryTrendLine.bv.slice(24)
  );

  return (
    variable && (
      <WatchlistFlagButton variable={variable} className={className} />
    )
  );
}

function DaBarsToggleButton() {
  const daBarsAtom = useAtomValue(Atoms.daBarsAtomAtom);

  if (!daBarsAtom) throw new Error("only render when this is defined");
  const hasAtLeastOneVariableTrendLine = useAtomValue(
    Atoms.atLeastOneVariableTrendLineAtom
  );

  const [showDaBars, setShowDaBars] = useAtom(daBarsAtom);

  if (!hasAtLeastOneVariableTrendLine) return;

  return (
    <Button
      onClick={() => setShowDaBars((curr) => !curr)}
      variant={!showDaBars ? "outline" : "default"}
      size={"xxs"}
      className="hidden @3xl:inline-flex"
    >
      {showDaBars && <Check className="mr-1 size-3" />}
      Anomaly Levels
    </Button>
  );
}

const someVariableHasAnomAtom = atom((get) => {
  return get(Atoms.onlyVariableTrendLinesAtom).some((x) => x.anom);
});

function DaPatchesToggleButton({
  className,
  children,
}: PropsWithCnAndChildren) {
  const daBarsAtom = useAtomValue(Atoms.daBarsAtomAtom);
  if (!daBarsAtom) throw new Error("only render when this is defined");

  const hasAtLeastOneVariableTrendLine = useAtomValue(
    Atoms.atLeastOneVariableTrendLineAtom
  );

  const isAnomalyColoration = useAtomValue(someVariableHasAnomAtom);
  const setAllAnomalyColoration = useSetAtom(Atoms.setAllAnomalyColorationAtom);

  if (!hasAtLeastOneVariableTrendLine) return;
  return (
    <Button
      onClick={() => setAllAnomalyColoration(!isAnomalyColoration)}
      variant={!isAnomalyColoration ? "outline" : "default"}
      size={"xxs"}
      className={cn("hidden @3xl:inline-flex", className)}
    >
      {isAnomalyColoration && <Check className="mr-1 size-3" />}
      {children}
    </Button>
  );
}

function _SaveMultiviewButton() {
  const usePbStoreMaybe = useGetUseProfileBookStoreNotRequired();
  const { register, handleSubmit, reset } = useForm<{ name: string }>({
    shouldUseNativeValidation: true,
  });
  const [open, setOpen] = useState(false);
  const isMulti = !useAtomValue(Atoms.singleVariableViewAtom);
  const vs = useAtomValue(Atoms.selectedVariablesAtom);

  const baseUrl = useBaseUrlExperimental();

  const invalidateFoldersQuery = PersonalFoldersQuery.useInvalidate();

  const mut = useMutation({
    mutationFn: (name: string) => {
      return postFolder(baseUrl, {
        name,
        variables: [
          assertMinLen1(
            vs.filter(isVariableTrendLine).map((x) => x.bv.slice(24))
          ),
        ],
      });
    },
    onSuccess: ({ name, _id }) => {
      invalidateFoldersQuery(); // new data will be fetched

      reset();

      setOpen(false);

      const pbStateMaybe = usePbStoreMaybe?.getState();

      if (pbStateMaybe) {
        pbStateMaybe.setTitle({
          type: "personal-folders",
          _id,
        });
      }
      addSuccessToast(`${name} saved`);
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });

  if (!isMulti) return;

  const onSubmit = handleSubmit((data) => {
    const name = data.name.trim();

    if (!name)
      return addToast({
        title: "Name is required",
        variant: "danger",
      });
    mut.mutate(data.name);
  });

  return (
    <Popover
      open={open}
      onOpenChange={(x) => {
        setOpen(x);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          size={"xxs"}
          className="hidden @3xl:inline-flex"
        >
          <Plus className="mr-1 size-3" />
          Save Multiview
        </Button>
      </PopoverTrigger>
      <PopoverContent className="bg-transparent p-0 border-0">
        <form className="flex" onSubmit={onSubmit}>
          <Input
            {...register("name", {
              required: "Name is required",
              minLength: 1,
            })}
            disabled={mut.isLoading}
            className="rounded-r-none focus-visible:ring-0 focus-visible:ring-offset-0"
            placeholder="Enter a name"
          />
          <Button
            type="submit"
            disabled={mut.isLoading}
            variant={"default"}
            size={"default"}
            className="rounded-l-none"
          >
            <Plus className="size-5" />
          </Button>
        </form>
      </PopoverContent>
    </Popover>
  );
}
