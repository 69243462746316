import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { create } from "zustand";

type BodyElementIsFullscreenStore = {
  isFs: boolean;
  setIsFs: (isFs: boolean) => void;
};

const createUseBodyElementIsFullscreenStore = (initialIsFs: boolean) => {
  return create<BodyElementIsFullscreenStore>()((set) => {
    return {
      isFs: initialIsFs,
      setIsFs: (isFs) => set({ isFs }),
    };
  });
};

type UseBodyElementIsFullscreenStore = ReturnType<
  typeof createUseBodyElementIsFullscreenStore
>;

const UseBodyElementIsFullscreenStoreContext = createContext<
  UseBodyElementIsFullscreenStore | undefined
>(undefined);

const documentIsFullscreen = () => document.fullscreenElement === document.body;

export function UseBodyElementIsFullscreenStoreProvider({
  children,
}: PropsWithChildren) {
  const [store] = useState(() => {
    return createUseBodyElementIsFullscreenStore(documentIsFullscreen());
  });

  useEffect(() => {
    const handle = () => {
      const is = documentIsFullscreen();
      store.getState().setIsFs(is);

      if (is) {
        document.body.style.overflowY = "scroll";
      } else {
        // unset it
        document.body.style.overflowY = "";
      }
    };

    handle(); // do it now

    document.addEventListener("fullscreenchange", handle);

    return () => {
      document.removeEventListener("fullscreenchange", handle);
    };
  }, [store]);

  return (
    <UseBodyElementIsFullscreenStoreContext.Provider value={store}>
      {children}
    </UseBodyElementIsFullscreenStoreContext.Provider>
  );
}

export function useBodyElementIsFullscreen() {
  const useStore = useContext(UseBodyElementIsFullscreenStoreContext);

  if (!useStore) {
    throw new Error(
      "useDocumentIsFullscreen must be used within a UseBodyElementIsFullscreenStoreProvider"
    );
  }

  return useStore((s) => s.isFs);
}
