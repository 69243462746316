import { Button } from "../../frontend/button";
import { iife } from "../../lib/utils";
import { FaChevronRight } from "react-icons/fa";
import {
  cn,
  type PropsWithCn,
  type PropsWithCnAndChildren,
} from "../../frontend/cn";
import { Tabs, TabsList, TabsTrigger } from "../../frontend/tabs";
import { BatchViewMode, YAxisMode } from "../../time-series/types";
import {
  useIsFullscreen,
  useToggleFullscreen,
} from "../fullscreen/fullscreen-provider";
import { Maximize2, Minimize2, X } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../frontend/tooltip";
import { MdOutlineLightMode } from "react-icons/md";
import { type ComponentProps, type PropsWithChildren } from "react";
import { useAtom, useAtomValue, useSetAtom, useStore } from "jotai";
import { Atoms } from "../svv-store/use-svv-store";
import { RiFullscreenFill } from "react-icons/ri";

function Container({ children, className }: PropsWithCnAndChildren) {
  return (
    <div
      className={cn(
        "relative flex flex-row items-center gap-2 p-1 @md:pb-2",
        className
      )}
    >
      {children}
    </div>
  );
}

function SidebarButton({ className }: PropsWithCn) {
  const singleVariableView = useAtomValue(Atoms.singleVariableViewAtom);
  const [openSidebar, setOpenSidebar] = useAtom(
    useAtomValue(Atoms.cardsSidebarOpenAtomAtom)
  );

  return (
    !singleVariableView && (
      <Button
        size="icon"
        variant={"ghost"}
        onClick={() => setOpenSidebar(!openSidebar)}
        className={cn("hidden size-6 @3xl:inline-flex", className)}
      >
        <FaChevronRight
          className={cn(
            "size-3 transition-transform",
            openSidebar && "rotate-180"
          )}
        />
      </Button>
    )
  );
}

function SpotlightToggle({ className }: PropsWithCn) {
  const single = useAtomValue(Atoms.singleVariableViewAtom);
  const [spotlight, setSpotlight] = useAtom(
    useAtomValue(Atoms.spotlightAtomAtom)
  );

  if (single) return;

  return (
    <Button
      size={"xxs"}
      variant={spotlight ? "default" : "outline"}
      onClick={() => setSpotlight(!spotlight)}
      className={cn("hidden @3xl:inline-flex", className)}
    >
      {spotlight && <MdOutlineLightMode className="mr-1.5 size-3" />}
      Spotlight
    </Button>
  );
}

function SingleVariableTitle({
  description,
  name,
  uom,
}: {
  name: string;
  description: string | undefined;
  uom?: string;
}) {
  const singleVariableView = useAtomValue(Atoms.singleVariableViewAtom);

  if (!singleVariableView) return;

  return (
    <span className="line-clamp-1 break-all text-xs @lg:text-sm">
      {name}{" "}
      {description && (
        <>
          | <span className="text-xslate-11">{description}</span>
        </>
      )}
      {uom && (
        <span className="hidden text-xslate-11 @lg:inline"> | {uom}</span>
      )}
    </span>
  );
}

function Tab({ children, value }: PropsWithChildren<{ value: YAxisMode }>) {
  return (
    <TabsTrigger className="h-4 py-0 text-xs" value={value}>
      {children}
    </TabsTrigger>
  );
}

function YAxisModeTabs({ className }: PropsWithCn) {
  const [viewMode, setYAxisMode] = useAtom(Atoms.yAxisModeAtom);
  const isInstantCalc = useAtomValue(Atoms.chartVariantAtom) === "ic";

  if (viewMode === undefined) return null;

  const tabs: { value: YAxisMode; label: string }[] = [
    {
      value: YAxisMode.Absolute,
      label: "Absolute",
    },
    {
      value: YAxisMode.Relative,
      label: "Relative",
    },
    {
      value: YAxisMode.Swimlane,
      label: "Stacked",
    },
  ];

  if (!isInstantCalc) {
    tabs.push({
      value: YAxisMode.Variability,
      label: "Variability",
    });
  }

  return (
    viewMode && (
      <Tabs
        className={cn("hidden @3xl:inline-flex", className)}
        value={viewMode}
        onValueChange={(v) => setYAxisMode(v as YAxisMode)}
      >
        <TabsList className="h-6 rounded-md bg-xslate-4">
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value}>
              {tab.label}
            </Tab>
          ))}
        </TabsList>
      </Tabs>
    )
  );
}

function ParallelTabs() {
  const single = useAtomValue(Atoms.singleVariableViewAtom);
  const [xAxisMode, setXAxisMode] = useAtom(
    useAtomValue(Atoms.xAxisModeAtomAtom)
  );

  if (single) return;

  const isSomeParallel =
    xAxisMode === BatchViewMode.ParallelStage ||
    xAxisMode === BatchViewMode.ParallelStart;

  if (!isSomeParallel) return;

  const options = [
    {
      label: "Align by stage",
      value: BatchViewMode.ParallelStage,
    },
    {
      label: "Align by start",
      value: BatchViewMode.ParallelStart,
    },
  ] as const satisfies readonly {
    label: string;
    value: string;
  }[];

  return (
    <Tabs
      value={xAxisMode}
      onValueChange={(v) => setXAxisMode(v as BatchViewMode)}
    >
      <TabsList className="h-7 rounded-md bg-xslate-4">
        {options.map((option) => (
          <TabsTrigger value={option.value} key={option.value}>
            {option.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
}

function XAxisModeTabs() {
  const [xAxisMode, setXAxisMode] = useAtom(
    useAtomValue(Atoms.xAxisModeAtomAtom)
  );

  if (!xAxisMode) return;

  const batchViewModeOptions = [
    {
      value: BatchViewMode.ParallelStage,
      label: "Parallel",
    },
    {
      value: BatchViewMode.series,
      label: "Series",
    },
  ] as const satisfies readonly {
    value: BatchViewMode;
    label: string;
  }[];

  return (
    <Tabs
      value={iife((): (typeof batchViewModeOptions)[number]["value"] => {
        switch (xAxisMode) {
          case BatchViewMode.ParallelStage:
            return BatchViewMode.ParallelStage;
          case BatchViewMode.series:
            return BatchViewMode.series;
          case BatchViewMode.ParallelStart:
            return BatchViewMode.ParallelStage;
          default:
            const _: never = xAxisMode;
            throw new Error("unreachable");
        }
      })}
      onValueChange={(v) => {
        const newBatchViewMode = v as BatchViewMode;
        const noop = xAxisMode === newBatchViewMode;
        if (noop) return;
        setXAxisMode(newBatchViewMode);
      }}
    >
      <TabsList className="h-7 rounded-md bg-xslate-4">
        {batchViewModeOptions.map((option) => (
          <TabsTrigger value={option.value} key={option.value}>
            {option.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
}

function SpanButton() {
  const single = useAtomValue(Atoms.singleVariableViewAtom);

  if (single) return;

  return (
    <Button
      variant={false ? "default" : "outline"}
      // onClick={() => multiState.setSpanMode((v) => !v)}
    >
      Span
    </Button>
  );
}

function FullscreenButton({ className }: PropsWithCn) {
  const jot = useStore();
  const toggleFullscreen = useToggleFullscreen();
  const isFullscreen = useIsFullscreen()(jot);

  const onlyShowCommentsAndNotifsIfIsFullscreen = useAtomValue(
    Atoms.onlyShowCommentsAndNotifsIfIsFullscreenAtom
  );

  const removeBrushAndNotificationsPanel = useSetAtom(
    Atoms.exitNotificationsAreaAtom
  );

  if (!toggleFullscreen) return null;

  const Icon = isFullscreen ? X : RiFullscreenFill;

  return (
    <Button
      className={cn("uppercase", className)}
      variant={"ghost"}
      size={isFullscreen ? "xxs" : "icon-xs"}
      onClick={() => {
        toggleFullscreen(jot);

        const shouldRemoveCommentsAndNotifsWhenExitingFullscreen =
          onlyShowCommentsAndNotifsIfIsFullscreen && isFullscreen;

        if (shouldRemoveCommentsAndNotifsWhenExitingFullscreen)
          removeBrushAndNotificationsPanel();
      }}
    >
      <Icon className="mr-0.5 size-3.5" />
      {isFullscreen && "close"}
    </Button>
  );
}

function CloseButton({
  onClick,
  className,
}: Pick<ComponentProps<typeof Button>, "onClick" | "className">) {
  return (
    <Button
      onClick={onClick}
      variant={"ghost"}
      size={"icon-xs"}
      className={className}
    >
      <X className="size-4" />
    </Button>
  );
}

function ExpandButton({ className }: PropsWithCn) {
  const [expanded, setExpanded] = useAtom(Atoms.expandedAtom);

  const Icon = expanded ? Minimize2 : Maximize2;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={() => setExpanded(!expanded)}
          variant={"ghost"}
          size={"icon-xs"}
          className={className}
        >
          <Icon className={cn("size-3.5 -rotate-45")} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{expanded ? "Collapse" : "Expand"}</TooltipContent>
    </Tooltip>
  );
}

export {
  SidebarButton,
  SpotlightToggle,
  Container,
  SingleVariableTitle,
  FullscreenButton,
  SpanButton,
  XAxisModeTabs,
  ParallelTabs,
  YAxisModeTabs,
  CloseButton,
  ExpandButton,
};
