import { useLocation } from "react-router";

type Paths = { [x: string]: string | Paths };

/**
 * If we use Link to navigate, we'll probably use this
 */
export const routerPaths = {
  builder: "builder",
  login: "login",
  backdoorLogin: "root-login",
  setpass: "setpass",
  help: "help",
  overview: "ov",
  profilebook: "pb",
  dri: "dri",
  comments: "comments",
  tags: "tag",
  groups: "group",
  shutdownrules: "sdr",
  operatingmodes: "om",
  clusters: "cluster",
  aria: "aria",
  acknowledgements: "ack",
  reportslibrary: "rl",
  faulttrees: "ft",
  operatingfitnessManager: "ofm",
  operatingfitness: "of",
  specialtyreports: "sr",
  users: "users",
  teams: "teams",
  backprocessing: "bp",
  staleTags: "bt",
  notifications: "notifications",
  mylimits: "my-limits",
  control: "control",
  entra: {
    noAccount: "noaccount",
    error: "error",
  },
} as const satisfies Paths;

export function useIsOnHelpPage() {
  const { pathname } = useLocation();
  return pathname.startsWith("/" + routerPaths.help);
}
