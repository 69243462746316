import { cn } from "../../../lib/utils";
import { variableSchema } from "../../../lib/api-validators";
import { useWatchlistMutation } from "../../../hooks/tanstack-query";
import { Button } from "../../../shared-ui/frontend/button";
import { FaFlag as SolidFlag, FaRegFlag as OutlineFlag } from "react-icons/fa";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { type PropsWithCn } from "../../../shared-ui/frontend/cn";

export function WatchlistFlagButton({
  variable,
  className,
}: {
  variable: variableSchema;
} & PropsWithCn) {
  const mut = useWatchlistMutation();

  const Icon = variable.watchlist ? SolidFlag : OutlineFlag;

  return (
    <TooltipProvider delayDuration={50}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            disabled={mut.isLoading}
            onClick={() => {
              if (
                !variable.watchlist ||
                window.confirm(
                  `Are you sure you want to remove ${variable.trimmedName} from your watchlist?`
                )
              ) {
                mut.mutate({
                  _id: variable._id,
                  watchlist: !variable.watchlist,
                });
              }
            }}
            size="icon"
            className={cn("size-[1.1rem]", className)}
            variant={"ghost"}
          >
            <Icon
              className={cn("size-3", variable.watchlist && "text-indigo-700")}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>
            {variable.watchlist ? "Remove from Watchlist" : "Add to Watchlist"}
          </span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
