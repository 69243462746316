import { TbChartHistogram } from "react-icons/tb";
import { Button } from "../../../shared-ui/frontend/button";
import * as BottomControlBar from "../../../shared-ui/time-series-2/secondary-variable-view/bottom-control-bar";
import { useGetUseVariabilityDrawerStoreNotRequired } from "../../variability-view/variability-drawer";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { DateSelector } from "./chart-range/date-selector";
import { ComponentProps, memo } from "react";
import { useMemo } from "use-memo-one";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import {
  useAriaClustersQuery,
  useClustersQuery,
  useGroupsQuery,
} from "../../../hooks/tanstack-query";
import {
  FaBell,
  FaChartLine,
  FaComment,
  FaEllipsisH,
  FaLayerGroup,
} from "react-icons/fa";
import { useClusterDrawerStoreNotRequired } from "../../clusters/cluster-drawer/use-cluster-drawer-store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shared-ui/frontend/dropdown-menu";
import { useTzAwareDateStateDomain } from "./chart-range/use-tz-aware-date-state-domain";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { iife } from "../../../lib/utils";
import { Check, X } from "lucide-react";
import { defaultRangeDaysForSlopingTrends } from "../../../constants/dateState";
import { atom, useAtom, useAtomValue, useSetAtom, useStore } from "jotai";
import {
  Atoms,
  isVariableTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import { useCallback } from "use-memo-one";
import { minLen1 } from "../../../shared-ui/lib/utils";
import { useOperatingModesQuery } from "../../om/manager/queries";
import {
  useIsFullscreen,
  useToggleFullscreen,
} from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { BsBarChartSteps, BsFillLightningChargeFill } from "react-icons/bs";
import { Badge } from "../../../shared-ui/frontend/badge";
import { useCorrelatedTagsDrawerStoreNotRequired } from "../../clusters/cluster-drawer/use-correlated-tags-drawer-store";
import { IoSparklesSharp } from "react-icons/io5";
import { useChartRenderOptions } from "./generic-secondary-variable-view";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { useGroupDrawerStoreNotRequired } from "../../groups/group-drawer/use-group-drawer-store";
import { MyFitnessLimitsPopover } from "./my-fitness-limits";
import { focusAtom } from "jotai-optics";
import { SelectedVariableAtomContext } from "./control-buttons";

const showBottomControlsAtom = atom((get) => {
  const brushState = get(Atoms.brushStoreAtom);
  const showNotifsAtom = get(Atoms.showNotificationsAtomAtom);

  const notCurrentlyBrushing = !brushState;
  const notCurrentlyShowingNotifications =
    !showNotifsAtom || !get(showNotifsAtom);

  const showBottomControls =
    notCurrentlyBrushing && notCurrentlyShowingNotifications;

  return showBottomControls;
});

const BottomControls = memo(function BottomControls() {
  const isOperatingLimitsChart = useAtomValue(Atoms.chartVariantAtom) === "ol";
  const isInstantCalc = useAtomValue(Atoms.chartVariantAtom) === "ic";
  const show = useAtomValue(showBottomControlsAtom);
  const d = useTzAwareDateStateDomain();
  const currentUnit = useCurrentUnitObject();
  const isRT = currentUnit && currentUnit.addons?.["realTime"];
  if (!show) return null;

  return (
    <BottomControlBar.Container>
      <BottomControlBar.VariableSelectOverlayButton />
      <BottomControlBar.GlobalClampsButton />
      {/* <BottomControlBar.EyeButton /> */}
      {/* <ClusterDrawerButton /> */}
      <CorrelatedTagsButton size={BottomControlBar.BUTTON_SIZE} />
      {/* <BottomControlBar.StoreInspect /> */}
      {/* <MyLimitsPopover /> */}
      <FirstSelectedVariableMyFitnessLimits />
      <DateSelector className="mx-auto" />
      {!isInstantCalc && <CreateCommentButton />}
      {!isOperatingLimitsChart && !isInstantCalc && (
        <SlopingTrendsButton size={BottomControlBar.BUTTON_SIZE} />
      )}
      {!isInstantCalc && isRT && <NotificationButton />}
      {!isInstantCalc && (
        <VariabilityButton size={BottomControlBar.BUTTON_SIZE} />
      )}
      <BottomControlBar.ResetButton domain={d} />
      <MasksButton />
    </BottomControlBar.Container>
  );
});

function FirstSelectedVariableMyFitnessLimits() {
  const [first, ...rest] = useAtomValue(Atoms.selectedVariableAtomsAtom);

  if (!first) throw new Error("impossible");

  const myLimitsAtomAtom = useAtomValue(
    useMemo(
      () =>
        focusAtom(first, (x) => x.guard(isVariableTrendLine).prop("myLimits")),
      [first]
    )
  );

  if (!myLimitsAtomAtom) return;
  if (rest.length !== 0) return;

  return (
    <Tooltip>
      <SelectedVariableAtomContext.Provider value={first}>
        <MyFitnessLimitsPopover myLimitsAtomAtom={myLimitsAtomAtom}>
          {(enabled, numLimits) => {
            return (
              <TooltipTrigger asChild>
                <Button
                  variant={iife(() => {
                    if (enabled) {
                      return numLimits > 0 ? "default" : "outline";
                    } else {
                      return "ghost";
                    }
                  })}
                  size={BottomControlBar.BUTTON_SIZE}
                >
                  <BsBarChartSteps className="size-3" />
                </Button>
              </TooltipTrigger>
            );
          }}
        </MyFitnessLimitsPopover>
      </SelectedVariableAtomContext.Provider>
      <TooltipContent>My Fitness</TooltipContent>
    </Tooltip>
  );
}

const toggleModeAtom = atom(null, (get, set, s: string) => {
  const copy = new Set(get(Atoms.excludedModesAtom));
  copy.delete(s) || copy.add(s);
  set(Atoms.excludedModesAtom, copy);
});

function MasksButton() {
  const isAnOperatingLimitsChart =
    useAtomValue(Atoms.chartVariantAtom) === "ol";
  const [modesThatAreOff, setExcludedModes] = useAtom(Atoms.excludedModesAtom);
  const toggleMode = useSetAtom(toggleModeAtom);
  const [showLimitLines, setShowLimitLines] = useAtom(Atoms.showLimitLinesAtom);

  const inSingleVariableView = useAtomValue(Atoms.singleVariableViewAtom);

  const shouldShowXAxisDiscontinuityButton =
    useAtomValue(Atoms.xAxisContinuityAtom) !== undefined &&
    inSingleVariableView;

  const [isModeTransparency, setIsModeTransparency] = useAtom(
    useAtomValue(Atoms.isModeTransparencyAtomAtom)
  );

  const [showCrosshair, setShowCrosshair] = useAtom(
    useAtomValue(Atoms.showcrosshairAtomAtom)
  );

  const primary = useAtomValue(Atoms.primaryBatchVariableAtom);
  const setAllAnomalyColoration = useSetAtom(Atoms.setAllAnomalyColorationAtom);
  if (!isVariableTrendLine(primary)) return undefined;
  const isAnomalyColoration = primary.anom;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size={BottomControlBar.BUTTON_SIZE} variant={"ghost"}>
          <FaEllipsisH className="size-3.5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-2 flex flex-col gap-1.5" align="end">
        <MaskToggleButton
          onClick={() => setAllAnomalyColoration(!isAnomalyColoration)}
          active={isAnomalyColoration}
        >
          Anomalies
        </MaskToggleButton>
        {/* when it's undefined, it hasn't been initialized yet  */}
        {isModeTransparency !== undefined && (
          <MaskToggleButton
            onClick={() => setIsModeTransparency(!isModeTransparency)}
            active={isModeTransparency}
          >
            Mode Transparency
          </MaskToggleButton>
        )}
        {shouldShowXAxisDiscontinuityButton && <XAxisDiscontinuityToggle />}
        {isAnOperatingLimitsChart && (
          <MaskToggleButton
            onClick={() => setShowLimitLines((s) => !s)}
            active={showLimitLines}
          >
            Toggle Limits
          </MaskToggleButton>
        )}
        <ChartTooltipToggle />

        <MaskToggleButton
          onClick={() => setShowCrosshair((s) => !s)}
          active={showCrosshair}
        >
          Hover Crosshair
        </MaskToggleButton>

        <div className="w-full h-[0.5px] bg-xslate-8 my-1" />

        <MaskToggleButton
          onClick={() => setExcludedModes(new Set())}
          active={modesThatAreOff.size === 0}
          disabled={modesThatAreOff.size === 0}
        >
          View All
        </MaskToggleButton>
        <MaskToggleButton
          onClick={() => toggleMode("Remainder")}
          active={!modesThatAreOff.has("Remainder")}
        >
          Remainder
        </MaskToggleButton>
        <MaskToggleButton
          onClick={() => toggleMode("0".repeat(24))}
          active={!modesThatAreOff.has("000000000000000000000000")}
        >
          Shutdown
        </MaskToggleButton>
        <_ModesMaskButtonsForVariableId />
      </PopoverContent>
    </Popover>
  );
}

function ChartTooltipToggle() {
  const [show, setShow] = useAtom(useAtomValue(Atoms.showChartTooltipAtomAtom));

  return (
    <MaskToggleButton onClick={() => setShow((x) => !x)} active={show}>
      Chart Tooltip
    </MaskToggleButton>
  );
}

function _ModesMaskButtonsForVariableId() {
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const modesQuery = useOperatingModesQuery();
  const modesThatAreOff = useAtomValue(Atoms.excludedModesAtom);
  const toggleMode = useSetAtom(toggleModeAtom);

  const modes = modesQuery.data;

  const myModes = useMemo(() => {
    if (!modes) return undefined;
    if (!isVariableTrendLine(primaryTrendLine)) return undefined;

    return modes.filter((x) =>
      x.bindingVariableIdsSet.includes(primaryTrendLine.bv.slice(24))
    );
  }, [modes, primaryTrendLine]);
  if (!myModes?.length) return undefined;

  return (
    <>
      {myModes.map(({ _id, name }) => {
        const off = modesThatAreOff.has(_id);
        const active = !off;
        return (
          <MaskToggleButton active={active} onClick={() => toggleMode(_id)}>
            {name}
          </MaskToggleButton>
        );
      })}
    </>
  );
}

function MaskToggleButton({
  active,
  onClick,
  children,
  disabled,
}: Pick<ComponentProps<typeof Button>, "onClick" | "children" | "disabled"> & {
  active: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "justify-start w-full flex-nowrap animate-in slide-in-from-top-3"
      )}
      size={"xs"}
      variant={active ? "default" : "destructive"}
    >
      {active ? (
        <Check className="h-4 w-4 mr-1" />
      ) : (
        <X className="h-4 w-4 text-xred-10 mr-1" />
      )}
      {children}
    </Button>
  );
}

const xAxisContinuityAtomRequired = atom((get) => {
  const out = get(Atoms.xAxisContinuityAtom);
  if (!out) throw new Error("Don't use when not set");

  return out;
});

function XAxisDiscontinuityToggle() {
  const continuousAtom = useAtomValue(xAxisContinuityAtomRequired);
  const [isContinuous, setIsContinuous] = useAtom(continuousAtom);

  return (
    <MaskToggleButton
      onClick={() => setIsContinuous((x) => !x)}
      active={isContinuous}
    >
      X-Axis Continuity
    </MaskToggleButton>
  );
}

function ClusterDrawerButton() {
  const clusterStore = useClusterDrawerStoreNotRequired();
  const groupStore = useGroupDrawerStoreNotRequired();
  const clustersQuery = useClustersQuery();
  const groupsQuery = useGroupsQuery();
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  if (!useAtomValue(Atoms.singleVariableViewAtom)) return null;
  if (!clusterStore) return null;
  if (!groupStore) return null;

  if (!isVariableTrendLine(primaryTrendLine)) return null;

  const myClusters = clustersQuery.data?.filter((x) =>
    x.variables.includes(primaryTrendLine.bv.slice(24))
  );
  const myGroups = groupsQuery.data
    ?.filter((x) => x.variables.includes(primaryTrendLine.bv.slice(24)))
    .sort((a, b) => a.variables.length - b.variables.length);

  if (!myClusters?.length && !myGroups?.length) return null;

  return (
    <Tooltip>
      <TooltipContent>Clusters and Groups</TooltipContent>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <TooltipTrigger asChild>
            <Button size={BottomControlBar.BUTTON_SIZE} variant={"ghost"}>
              <FaLayerGroup className="size-3" />
            </Button>
          </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {myClusters?.length ? (
            <>
              <div className="font-semibold text-black text-sm pl-2">
                Clusters
              </div>
              {myClusters.map((x) => {
                return (
                  <DropdownMenuItem
                    key={x._id}
                    onSelect={() => clusterStore.getState().setClusterId(x._id)}
                    className="flex flex-row justify-between"
                  >
                    <div className="line-clamp-1 flex flex-row">
                      {x.type === "dynamic" && (
                        <BsFillLightningChargeFill className="size-3 relative top-1" />
                      )}
                      <span className="max-w-80 truncate">{x.name}</span>
                    </div>
                    <div className="flex flex-row gap-1">
                      <Badge
                        variant={"secondary"}
                        className="relative shrink-0 ml-2"
                      >
                        {x.variables.length} tags
                      </Badge>
                    </div>
                  </DropdownMenuItem>
                );
              })}
            </>
          ) : null}
          {myGroups?.length ? (
            <>
              <div
                className={cn(
                  "font-semibold text-black text-sm pl-2",
                  myClusters?.length && "mt-2"
                )}
              >
                Groups
              </div>
              {myGroups.map((x) => {
                return (
                  <DropdownMenuItem
                    key={x._id}
                    onSelect={() => groupStore.getState().setGroupId(x._id)}
                    className="flex flex-row justify-between"
                  >
                    <div className="line-clamp-1 flex flex-row">
                      <span className="max-w-80 truncate">{x.name}</span>
                    </div>
                    <div className="flex flex-row gap-1">
                      <Badge
                        variant={"secondary"}
                        className="relative shrink-0 ml-2"
                      >
                        {x.variables.length} tags
                      </Badge>
                    </div>
                  </DropdownMenuItem>
                );
              })}
            </>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>
    </Tooltip>
  );
}

export function CorrelatedTagsButton({
  className,
  size,
}: Pick<ComponentProps<typeof Button>, "className" | "size">) {
  const s = useCorrelatedTagsDrawerStoreNotRequired();
  const clustersQuery = useAriaClustersQuery({
    filterType: "static",
  });
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  if (!useAtomValue(Atoms.singleVariableViewAtom)) return null;
  if (!s) return null;

  if (!isVariableTrendLine(primaryTrendLine)) return null;

  const myClusters = clustersQuery.data?.filter((x) =>
    x.variables.includes(primaryTrendLine.bv.slice(24))
  );

  if (!myClusters?.length) return null;

  const all = [primaryTrendLine.bv.slice(24)];
  const excellent: string[] = [];
  const veryGood: string[] = [];
  const good: string[] = [];
  const fair: string[] = [];
  const poor: string[] = [];
  myClusters
    .sort((a, b) => (b.score || 0) - (a.score || 0))
    .forEach((c) => {
      const score = c.score || 0;
      const newVars = c.variables.filter((v) => !all.includes(v));
      if (score >= 95) {
        excellent.push(...newVars);
      } else if (score >= 90) {
        veryGood.push(...newVars);
      } else if (score >= 70) {
        good.push(...newVars);
      } else if (score >= 40) {
        fair.push(...newVars);
      } else {
        poor.push(...newVars);
      }
      all.push(...newVars);
    });
  const correlatedTags = {
    Excellent: excellent,
    "Very Good": veryGood,
    Good: good,
    Fair: fair,
    Poor: poor,
  };
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          size={size}
          variant={"ghost"}
          onClick={() => {
            s.getState().setTags(correlatedTags);
            s.getState().setTagId(primaryTrendLine.bv.slice(24));
          }}
          className={cn(className)}
        >
          <IoSparklesSharp className="size-3.5" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Correlated Tags</TooltipContent>
    </Tooltip>
  );
}

function VariabilityButton({
  className,
  size,
}: PropsWithCn & Pick<ComponentProps<typeof Button>, "size">) {
  const selectedVariables = useAtomValue(Atoms.onlyVariableTrendLinesAtom);

  const jot = useStore();

  const useVariabilityStore = useGetUseVariabilityDrawerStoreNotRequired();

  if (!useVariabilityStore || !selectedVariables.length) return;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={"ghost"}
          size={size ?? "icon"}
          onClick={() => {
            const [start, end] = jot.get(jot.get(Atoms.drawnDomainAtomAtom));

            useVariabilityStore.getState().openDrawer({
              variables: iife(() => {
                const out = selectedVariables.map(({ bv, color }) => ({
                  color,
                  _id: bv.slice(24),
                }));

                if (!minLen1(out)) throw new Error("impossible");
                return out;
              }),
              defaultEnd: end,
              defaultStart: start,
            });
          }}
        >
          <TbChartHistogram
            // fontSize="1.2em"
            className="size-4 [&>path:first-child]:stroke-none"
            /* TODO: there might be a better way of handling this, but from
             * what I can see most other react-icons don't share this problem.
             * The icon wants to inherit stroke color, which is great, but it
             * also uses `stroke:none` internally, which gets overridden by
             * CSS. Not sure if it's just a bug or if there's meant to be a
             * more complicated wrapper used.*/
          />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Variability</TooltipContent>
    </Tooltip>
  );
}

export function useExitSlopingTrends() {
  const [start, end] = useTzAwareDateStateDomain();
  const setSlopingTrends = useSetAtom(Atoms.setSlopingTrendsAtom);
  return useCallback(() => {
    setSlopingTrends({
      on: false,
      newChartDomain: [start, end],
    });
  }, [start, end, setSlopingTrends]);
}

export function useEnterSlopingTrends() {
  const [, end] = useAtomValue(Atoms.getDomainAtom);
  const setSlopingTrends = useSetAtom(Atoms.setSlopingTrendsAtom);
  const zone = useTimezone();

  return useCallback(() => {
    const newEnd = DateTime.fromMillis(end, { zone })
      .endOf("day")
      .startOf("minute");

    const start = newEnd
      .minus({
        days: Math.max(defaultRangeDaysForSlopingTrends - 1, 0),
      })
      .startOf("day");

    setSlopingTrends({
      on: true,
      newChartDomain: [start.toMillis(), newEnd.toMillis()],
    });
  }, [end, setSlopingTrends, zone]);
}

function SlopingTrendsButton({
  className,
  size,
}: PropsWithCn & Pick<ComponentProps<typeof Button>, "size">) {
  const stEnabled = useAtomValue(Atoms.slopingTrendsAtom);
  const show = useAtomValue(Atoms.singleVariableViewAtom);
  const enterSlopingTrends = useEnterSlopingTrends();
  const exitSlopingTrends = useExitSlopingTrends();

  if (!show) return null;
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={stEnabled ? "default" : "ghost"}
          size={size ?? "icon"}
          onClick={() => {
            const newEnabled = !stEnabled;
            if (newEnabled) {
              enterSlopingTrends();
              return;
            }

            exitSlopingTrends();
          }}
        >
          <FaChartLine className="size-3.5" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Sloping Trends</TooltipContent>
    </Tooltip>
  );
}

function NotificationButton({ className }: PropsWithCn) {
  const a = useAtomValue(Atoms.showNotificationsAtomAtom);
  if (!a) return null;
  return <_NotificationButton className={cn(className)} />;
}

function _NotificationButton({ className }: PropsWithCn) {
  const a = useAtomValue(Atoms.showNotificationsAtomAtom);
  if (!a) throw new Error("impossible");

  const jot = useStore();
  const toggleFullscreen = useToggleFullscreen();
  const isFullscreen = useIsFullscreen()(jot);

  const onlyShowCommentsAndNotifsIfIsFullscreen = useAtomValue(
    Atoms.onlyShowCommentsAndNotifsIfIsFullscreenAtom
  );

  const setShow = useSetAtom(a);
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={"ghost"}
          size={BottomControlBar.BUTTON_SIZE}
          onClick={() => {
            setShow(true);

            if (
              /**
               * since notifs should only be shown in fullscreen,
               * we'll toggle fullscreen if it's not already fullscreen
               */
              onlyShowCommentsAndNotifsIfIsFullscreen &&
              toggleFullscreen &&
              !isFullscreen
            )
              toggleFullscreen(jot);
          }}
        >
          <FaBell className="size-3" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Notifications</TooltipContent>
    </Tooltip>
  );
}

function CreateCommentButton({ className }: PropsWithCn) {
  const setBrushStore = useSetAtom(Atoms.brushStoreAtom);
  const jot = useStore();
  const toggleFullscreen = useToggleFullscreen();
  const isFullscreen = useIsFullscreen()(jot);

  const { hideCommentPills } = useChartRenderOptions();

  const onlyShowCommentsAndNotifsIfIsFullscreen = useAtomValue(
    Atoms.onlyShowCommentsAndNotifsIfIsFullscreenAtom
  );

  const setSelectedCommentId = useSetAtom(Atoms.selectedCommentIdAtom);

  if (hideCommentPills) return null;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={"ghost"}
          size={BottomControlBar.BUTTON_SIZE}
          onClick={() => {
            // remove any selected comments if we're creating a new one
            setSelectedCommentId(undefined);
            setBrushStore({
              range: undefined,
              mode: "comment-create",
            });

            if (
              onlyShowCommentsAndNotifsIfIsFullscreen &&
              toggleFullscreen &&
              !isFullscreen
            )
              toggleFullscreen(jot);
          }}
        >
          <FaComment className="size-3" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Create Comment</TooltipContent>
    </Tooltip>
  );
}

export { BottomControls };
