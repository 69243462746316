import { type AnomalyLevelEnum } from "../lib/anomaly-levels";

type Timeseries = {
  timestamp: number;
  value: number;
};

type Changepoints = {
  timestamp: number;
  da: number;
  mode: string | null;
  // the value can be NaN which should come over the wire as undefined (but putting null here just in case it's null as well, can't hurt)
  value?: number | null;
};

type Point = {
  v: number;
  t: number;
};

type Points = {
  pts: [Point, ...Point[]];
  r: [number, number];
  d: AnomalyLevelEnum | null; // TODO attach this to something else
};

type PointsPartitioned = [Points, ...Points[]];

type StageFromAPI = {
  d: [number, number];
  r: [number, number];
  _id: "Remainder" | "000000000000000000000000" | (string & {});
  ptsPartitioned: PointsPartitioned;
};

type TimeseriesForBv = (
  | {
      type: "expression";
      id: string;
    }
  | {
      type: "variable";
      bv: string;
    }
) & {
  stages: [StageFromAPI, ...StageFromAPI[]];
  d: [number, number];
  r: [number, number];
};

type TimeseriesForBvFromVariable = Extract<
  TimeseriesForBv,
  { type: "variable" }
>;

type TimeseriesForBvFromExpression = Extract<
  TimeseriesForBv,
  { type: "expression" }
>;

export enum MyExceedanceType {
  Open,
  Close,
}

export type ExceedanceOccurred = { x: number; type: MyExceedanceType };

type StageForDraw = {
  d: [number, number];
  r: [number, number];
  _id: StageFromAPI["_id"];
  ptsPartitioned: PointsPartitioned;
  offset: { (t: number): number; invert(t: number): number };
  myFitnessLimitsIdToExceedances?: Record<
    // limit id
    string,
    [ExceedanceOccurred, ...ExceedanceOccurred[]]
  >;
};

type TimeseriesForBvForDraw = (
  | {
      type: "variable";
      bv: string;
    }
  | {
      type: "expression";
      id: string;
    }
) & {
  stages: [StageForDraw, ...StageForDraw[]];
  d: [number, number];
  r: [number, number];
};

enum LineWidth {
  Regular,
  ExplicitBold,
  HoverBold,
}

export {
  type Timeseries,
  type Changepoints,
  type StageFromAPI,
  type Point,
  type Points,
  type TimeseriesForBv,
  type TimeseriesForBvForDraw,
  type PointsPartitioned,
  type TimeseriesForBvFromVariable,
  type TimeseriesForBvFromExpression,
  LineWidth,
};
